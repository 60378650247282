import { IconButton, Tooltip, tooltipClasses } from "@mui/material";
import React from "react";
import { FileService } from "../../services/FileService";
import iconExcel from "../../assets/icons/ExportExcel.png";
import styled from "@emotion/styled";

const Img = styled("img")(({ theme }) => ({
  width: 50,
  [theme.breakpoints.down("sm")]: {
    width: 50,
  },
}));

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
}));

export default function ExportExcel({search}) {
  const exportExcel = async () => {
    console.log('DATA', search)
    
    const fileService = FileService.getInstance();
    const responseExcel = await fileService.getFilesReport(search === "" || search === null || search === undefined ? undefined : search);
    console.log(responseExcel)
    const url = window.URL.createObjectURL(new Blob([responseExcel.data.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Expedientes.csv");
    document.body.appendChild(link);
    link.click()
  };

  return (
    <Tip title="Descargar solicitudes." placement="top">
    <IconButton sx={{ width: 60 }} onClick={() => exportExcel()}>
      <Img src={iconExcel} alt="iconExcel" />
    </IconButton>
    </Tip>
  );
}
