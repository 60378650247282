import React, { useEffect, useState } from "react";
import {
  isChrome,
  isFirefox,
  isSafari,
  isEdge,
  isOpera,
} from "react-device-detect";
import { clearCacheData, obtenerGeolocalizacionIP } from "../utils";
import { ToastNotification } from "./toastNotification";

export const GeoLocationGeneric = (props) => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  console.log("NAVEGADORES", isChrome, isFirefox, isSafari, isEdge, isOpera);
  console.log("NAVEGADORES", props.ReloadGeoLocation);

  useEffect(() => {
    console.log("Recargar Geolocalizacion: ", props.ReloadGeoLocation);
    if (props.ReloadGeoLocation == true) {
      console.log("Activa ubucacion :", props.ReloadGeoLocation);
      if (navigator.geolocation) {
        const watchId = navigator.geolocation.watchPosition(
          (position) => {
            console.log("ENTRO OBTENER IP 30", position);
            handleGeoLocationSuccess(position);
          },
          (errorApi) => {
            console.log("ENTRO OBTENER error 34", errorApi);
            handleGeoLocationError(errorApi);
            return props.setOpenAlerte(true);
          }
        );
        return () => {
          console.log("ENTRO OBTENER error 39");
          console.error("navigator.geolocation.clearWatch watchId :", watchId);
          navigator.geolocation.clearWatch(watchId);
        };
      } else {
        console.log("ENTRO OBTENER error 44");
        console.error("La geolocalización no es compatible con este navegador");
      }
    }
  }, [props.ReloadGeoLocation]);
  console.log("props.geolocationIP", props.geolocationIP);

  useEffect(() => {
    console.log("ENTRO ACTUALIZAR");
    if (props.geolocationIP == true) {
      obtenerGeolocalizacionIP()
        .then((responseData) => {
          console.log("ENTRO response", responseData);
          handleGeoLocationSuccess({
            coords: {
              latitude: responseData.data.location.lat,
              longitude: responseData.data.location.lng,
            },
          });
        })
        .catch((error) => {
          ToastNotification({
            icon: "error",
            text: error,
          });
          console.error("ERROR API", error);
          handleGeoLocationError(error);
        });
    }
  }, [props.geolocationIP]);

  const requestLocation = () => {
    console.log("ENTRO OBTENER request 51");
    console.log("requestLocation browser generic.");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        handleGeoLocationSuccess,
        handleGeoLocationError,
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
      );
    } else {
      console.log("La geolocalización no es compatible con este navegador.");
    }
  };

  const handleGeoLocationSuccess = (position) => {
    const { latitude, longitude } = position.coords;
    console.log(
      "Obtiene geolocalizacion: latitude, longitude: ",
      latitude,
      longitude
    );
    setLatitude(latitude);
    setLongitude(longitude);
    props.SuccessFN(latitude, longitude);
    props.setReloadGeoLocation(false);
    ToastNotification({
      icon: "success",
      text: "Se obtuvo la ubicación de manera correcta.",
    });
    props.setOpenAlerte(false);
  };

  const handleGeoLocationError = (error) => {
    props.setReloadGeoLocation(false);
    console.log("Error al obtener la geolocalización:", error);
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("El usuario ha denegado el permiso de geolocalización.");
        props.ErrorFN("El usuario ha denegado el permiso de geolocalización.");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("La información de ubicación no está disponible.");

        props.ErrorFN("La información de ubicación no está disponible.");
        break;
      case error.TIMEOUT:
        console.log(
          "Se ha excedido el tiempo de espera para obtener la ubicación."
        );
        props.ErrorFN(
          "Se ha excedido el tiempo de espera para obtener la ubicación."
        );
        break;
      case error.UNKNOWN_ERROR:
        console.log("Se ha producido un error desconocido.");
        props.ErrorFN("Se ha producido un error desconocido.");
        break;
      default:
        console.log("No controlado: ", error);
        props.ErrorFN(`No controlado - Code: ${error.code}`);
        setTimeout(() => {
          requestLocation();
        }, 3000);
        break;
    }
  };

  return (
    <div style={{ display: "none" }}>
      {latitude && longitude ? (
        <p>
          Latitud: {latitude}, Longitud: {longitude}
        </p>
      ) : (
        <p>Obteniendo la geolocalización...</p>
      )}
    </div>
  );
};
