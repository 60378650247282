import { CircularProgress, Box } from "@mui/material";

export const Loader = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: 300,
        width: "100%",
        ...(props.sx ?? {}),
      }}
    >
      <CircularProgress size={props.size ?? 60} />
      {props.children}
    </Box>
  );
};
