import { Box, IconButton } from "@mui/material";
import React from "react";
import { useState } from "react";
import { CustomModalDialog } from "./CustomModalDialog";

export default function HelpPages(props) {
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          right: 40,
          bottom: 48,
          zIndex: 999,
          color: "#C20E30",
          display: { xs: 'none', sm: 'block' },

        }}
        className="intermitente"
      >
        <IconButton
          onClick={() => setOpenDialog(!openDialog)}
          sx={(theme) => ({
            background: theme.palette.primary.main,
            color: "#FFF",
            width: 56,
          })}
        >
          <spam style={{ fontSize: 35 }}>?</spam>
        </IconButton>
      </Box>

      <CustomModalDialog
        Open={openDialog}
        OnClose={() => setOpenDialog(!openDialog)}
        Body={props.Body}
      />
    </>
  );
}
