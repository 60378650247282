import { useEffect, useState, useRef } from 'react';
import { useUserMedia } from "./use-user-media";
import {
  Typography,
  Button,
  Box,
  AppBar,
  CircularProgress,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import {
  CameraAlt as IconCamera,
  Clear as IconClear,
} from "@mui/icons-material";

const CAPTURE_OPTIONS = { audio: false, video: true }

export const Camera = (props) => {
  const videoRef = useRef();
  const canvasRef = useRef();

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true)
  const [isChangeVideo, setIsChangeVideo] = useState(true);

  const [urlToblobImage, setUrlToblobImage] = useState(null);

  const mediaStream = useUserMedia(CAPTURE_OPTIONS);

  useEffect(() => {
    console.log("videoRef.current: ", videoRef.current)
    if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
      setIsChangeVideo(false)
      videoRef.current.srcObject = mediaStream;
      setIsVideoPlaying(true)
      handleClear()
    }
  }, [videoRef.current, mediaStream])

  let handleCapture = () => {
    const context = canvasRef.current.getContext("2d");

    context.drawImage(
      videoRef.current,
      // offsets.x,
      // offsets.y,
      // container.width,
      // container.height,
      0,
      0,
      // container.width,
      // container.height
    );

    canvasRef.current.toBlob(blob => {
      setUrlToblobImage(blob)
      props.onCapture(blob)
    }, "image/jpeg", 1);
    setIsCanvasEmpty(false);
    // setIsFlashing(true);
  }

  let handleClear = () => {
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setIsCanvasEmpty(true);
    setUrlToblobImage(null)
    props.onClear();
  }

  const Spinner = () => {
    return (
      <Box sx={{ display: "grid", placeContent: "center", height: 500 }}>
        <CircularProgress size={60} />
      </Box>
    );
  };

  return (
    <div style={{ textAlign: 'center',display: 'flex',
    alignItems: 'center', flexDirection: 'column' }}>
      {/* {console.log("isChangeVideo: ", isChangeVideo)} */}
      {isChangeVideo === true ? <Box>
        <Spinner />
      </Box> : null}
      <video
        ref={videoRef}
        autoPlay={true}
        muted={true}
        width="100%"
        height="100%"
        hidden={!isCanvasEmpty}
      />
      <canvas
        ref={canvasRef}
        width={400}
        height={400}
        hidden={true}
      />

      {urlToblobImage ? <img 
        width="80%"
        height="100%"
        src={urlToblobImage && URL.createObjectURL(urlToblobImage)}
        alt="experiment" /> : null}

      {isVideoPlaying && (
        <IconButton
          sx={{ color: "#FFFFFF", pointerEvents: "auto",
           borderRadius: '50% 50%',
           marginTop:'0.5em',
            background: '#C20E30', 
           "&:hover": { background: "#C20E30" } }}
          onClick={isCanvasEmpty ? handleCapture : handleClear}
        >
          {isCanvasEmpty ? <IconCamera /> : <IconClear />}
        </IconButton>
        // <button onClick={isCanvasEmpty ? handleCapture : handleClear}>
        //   {isCanvasEmpty ? "Take a picture" : "Take another picture"}
        // </button>
      )}
    </div>
  );
}

