export const newFileConstants = {
  NEW_FILE_REQUEST: "NEW_FILE_REQUEST",
  NEW_FILE_SUCCESS: "NEW_FILE_SUCCESS",
  NEW_FILE_FAILURE: "NEW_FILE_FAILURE",
  RESET_NEW_FILE: "RESET_NEW_FILE",
  SET_NEW_DOCUMENT: "SET_NEW_DOCUMENT",
  RESET_NEW_DOCUMENT: "RESET_NEW_DOCUMENT",
  RESET_PDF: "RESET_PDF",
  SET_FORM: "SET_FORM",
  UNSET_FORM: "UNSET_FORM",
  SET_CHECK_PARTICIPANTS: "SET_CHECK_PARTICIPANTS",
  SET_SIGN_PARTICIPANTS: "SET_SIGN_PARTICIPANTS",
  SET_VIEW_PARTICIPANTS: "SET_VIEW_PARTICIPANTS",
  SET_CAPTURE_PARTICIPANTS: "SET_CAPTURE_PARTICIPANTS",
  UNSET_ALL_PARTICIPANTS: "UNSET_ALL_PARTICIPANTS",
  ADD_TO_DOCS_STACK: "ADD_TO_DOCS_STACK",
  SET_DOCS_STACK: "SET_DOCS_STACK",
  RESET_DOCS_STACK: "RESET_DOCS_STACK",
  EDIT_STACK: "EDIT_STACK",
  SET_PRERREQUISITES: "SET_PRERREQUISITES",
  RESET_PRERREQUISITES: "RESET_PRERREQUISITES"
};
