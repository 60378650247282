import { Box, Button, Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CustomInputField } from "../../components/CustomInputField";
import { UsersService } from "../../services/UsersService";
import { Alert } from "../../components/Alert";
import BackdropComponent from "../../components/BackDrop";
import { CustomModalAlert } from "../../components/CustomModalAlert";

export default function UserRegistration(props) {
  const { control, setValue, watch, formState, handleSubmit, register } =
    useForm({
      mode: "onBlur",
    });
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [rolesData, setRolesData] = useState([]);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const fileService = UsersService.getInstance();
        const res = await fileService.getRoles({ SearchText: "" });
        setRolesData(res.Body);
        const newList = res.Body.map((item) => ({
          value: item.Id,
          label: item.Name === "Operador" ? "Participante" : item.Name,
          tooltipText:
            item.Name === "Operador"
              ? "Con este rol, solo puedes editar y firmar los documentos."
              : item.Name === "Administrador"
              ? "Con este rol, puedes crear expedientes, editar y firmar documentos."
              : null,
        }));
        setRoles(newList);
      } catch (error) {
        setRoles([]);
      }
    };
    getRoles();
  }, []);

  const handleSendData = async (data) => {
    setLoading(true);
    const rolSeleted = rolesData.find((item) => item.Id == data.rolUser);
    console.log("data", data);

    let Body = {
      UserName: data.emailUser,
      Password: "",
      UserAlias: data.aliasUser,
      Name: data.nameUser ? data.nameUser : "",
      FatherLastName: data.lastName ? data.lastName : "",
      MotherLastName: "",
      Email: data.emailUser,
      PhoneNumber: data.phoneUser ? data.phoneUser : "",
      Metadata: [],
      Roles: [
        {
          Id: rolSeleted.Id,
          Name: rolSeleted.Name,
          Code: rolSeleted.Code,
        },
      ],
    };
    
    try {
      const userService = UsersService.getInstance();
      if (props.userEdit === true) {
        Body = {
          ...Body,
          Id: props.usersSelected.Id,
          Active: data.activeUser,
          Locked: props.usersSelected.Locked,
        };
        const res = await userService.updateUserRole({ Body });
        Alert({
          icon: "success",
          text: "Usuario editado con éxito.",
          okbtntext: "Aceptar",
        });
        props.setUserRegistration(false);
        props.setReloadState(true);
        setLoading(false);
      } else {
        const res = await userService.registerParticipant({ Body });
        Alert({
          icon: "success",
          text: "Invitación enviada con éxito.",
          okbtntext: "Aceptar",
        });
        props.setUserRegistration(false);
        props.setReloadState(true);
        setLoading(false);
      }
    } catch (error) {
      Alert({
        icon: "error",
        text: error.message,
        okbtntext: "Aceptar",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.userEdit === true) {
      setValue("nameUser", props.usersSelected?.Name);
      setValue(
        "lastName",
        `${
          props.usersSelected?.FatherLastName
            ? props.usersSelected?.FatherLastName
            : ""
        }${" "} ${
          props.usersSelected?.MotherLastName
            ? props.usersSelected?.MotherLastName
            : ""
        }`
      );
      setValue("phoneUser", props.usersSelected?.PhoneNumber);
      setValue("emailUser", props.usersSelected?.emailUser);
      setValue("rolUser", props.usersSelected.rolData[0]?.Id);
      setValue("activeUser", props.usersSelected?.Active);
    }
  }, [props.usersSelected, roles]);

  const notifica = (e) => {
    if (props.userEdit === true) {
      console.log("rolSeleted 1", e.target.value);
      const rolSeleted = rolesData?.find((item) => item.Id == e.target.value);
      Alert({
        icon: "warning",
        title:
          rolSeleted?.Name === "Administrador"
            ? "Estás convirtiendo a este usuario en administrador"
            : rolSeleted?.Name === "Operador"
            ? "Estás convirtiendo a este usuario en participante"
            : null,
        text:
          rolSeleted?.Name === "Administrador"
            ? "Podrá crear expedientes, así como también editar y firmar documentos."
            : rolSeleted?.Name === "Operador"
            ? "No podrá crear expedientes, tendrá como función editar y firmar documentos"
            : null,
        okbtntext: "Entendido",
      });
    }
  };

  return (
    <Fragment>
      <BackdropComponent loading={loading} />
      <Grid item lg={12} sx={{ p: 2 }}>
        <Box>
          <Typography variant="titulo">
            {props.userEdit === true ? "" : "Alta de Usuarios"}{" "}
          </Typography>
        </Box>
        {props.userEdit === true ? null : (
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1">
              Completa el siguiente formulario para agregar a un nuevo usuario
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid container justifyContent={"center"}>
        <Grid item lg={6} md={8} xs={11}>
          {props.userEdit === true ? (
            <Box>
              <Box sx={{ mt: 1.5 }}>
                <CustomInputField
                  inputType="text"
                  control={control}
                  register={register}
                  name={"nameUser"}
                  label={"Nombre (s)"}
                  validations={{ required: true }}
                  required={true}
                />
              </Box>
              <Box sx={{ mt: 1.5 }}>
                <CustomInputField
                  inputType="text"
                  control={control}
                  register={register}
                  name={"lastName"}
                  label={"Apellidos (s)"}
                  validations={{ required: true }}
                  required={true}
                />
              </Box>
              <Box sx={{ mt: 1.5 }}>
                <CustomInputField
                  inputType="phone"
                  control={control}
                  register={register}
                  name={"phoneUser"}
                  label={"Número de celular"}
                  validations={{ required: true }}
                  required={true}
                />
              </Box>
            </Box>
          ) : (
            <Box>
              <Box sx={{ mt: 1.5 }}>
                <CustomInputField
                  inputType="text"
                  control={control}
                  register={register}
                  name={"aliasUser"}
                  label={"Alias del usuario"}
                  validations={{ required: true }}
                  error={!!formState.errors[`Alias del usuario`]}
                  required={true}
                />
              </Box>
            </Box>
          )}
          <Box sx={{ mt: 1.5 }}>
            <CustomInputField
              inputType="email"
              control={control}
              register={register}
              disabled={props.userEdit}
              name={"emailUser"}
              label={"Correo electrónico"}
              required={true}
            />
          </Box>
          {formState.errors.emailUser && <p> <span style={{color: '#C20E30', fontSize: 12}}>{formState.errors.emailUser.message}</span></p>}
          <Box sx={{ mt: 1.5 }}>
            <CustomInputField
              inputType="radio"
              register={register}
              functionClick={(e) => notifica(e)}
              label={"Rol"}
              control={control}
              name="rolUser"
              radioValues={roles}
              validations={{ required: true }}
            />
          </Box>
          {props.userEdit === true ? (
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontWeight: 700 }}>
                Estatus del usuario
              </Typography>
              <CustomInputField
                tooltipText={
                  "Si desactivas el estatus del usuario, no podrá editar ni firmar los documentos que tiene asignados."
                }
                inputType="check"
                register={register}
                label={"Activo"}
                control={control}
                name="activeUser"
                defaultValue={props.usersSelected?.Active}
              />
            </Box>
          ) : null}
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"} sx={{ mt: 2 }}>
        <Grid item lg={3} md={4} xs={11} sx={{ p: 1 }}>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            onClick={() => props.setUserRegistration(false)}
            sx={{ height: 45 }}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item lg={3} md={4} xs={11} sx={{ p: 1 }}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleSubmit(handleSendData)}
            sx={{ height: 45 }}
          >
            {props.userEdit === true ? " Guardar cambios" : "Enviar invitación"}
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}
