import React, { useEffect, useState, useCallback } from "react";
import Webcam from "react-webcam";

export const CustomCameraVideo = (props) => {
  // const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);

  const webcamRef = useCallback(
    (inputElement) => {
      if (inputElement) {
        console.log(
          "Entro en inputElement:**********Acciones******************: ",
          mediaRecorderRef,
          props.Recording,
          inputElement
        );
        // inputElement.click();
        if (props.Recording) {
          console.log(
            "Entro en props.Recording:***********: ",
            props.Recording,
            inputElement
          );

          setCapturing(true);
          mediaRecorderRef.current = new MediaRecorder(inputElement.stream);
          mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
          );
          mediaRecorderRef.current.start();
        }
      }
    },
    [props.Recording]
  );

  useEffect(() => {
    // console.log("props.Stopped: ", props.Recording, mediaRecorderRef?.current)
    if (
      props.Stopped &&
      mediaRecorderRef?.current !== undefined &&
      mediaRecorderRef?.current !== null
    ) {
      // console.log("INIT props.Stopped: ", props.Stopped)
      mediaRecorderRef.current.stop();
      setCapturing(false);
    }
  }, [props.Stopped, mediaRecorderRef, webcamRef, setCapturing]);

  useEffect(() => {
    // console.log("props.Stopped:useEffect capturing ", capturing, recordedChunks.length)
    if (!capturing && recordedChunks.length > 0) {
      // console.log("INIT props.Stopped:capturing ", capturing)
      handleCapture();
    }
  }, [capturing, recordedChunks.length]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleCapture = React.useCallback(() => {
    // console.log("recordedChunks.length: ", recordedChunks.length)
    if (recordedChunks.length) {
      // console.log(" blob Capturar video: ", recordedChunks.length)
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });

      props.onCapture(blob);

      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  return (
    <>
      {props.deviceId &&
      (navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/Android/i)) ? (
        <Webcam
          muted={true}
          audio={true}
          ref={webcamRef}
          screenshotQuality={1}
          style={
            props.isDisplay === false
              ? { display: "none" }
              : { display: "block" }
          }
          videoConstraints={{
            facingMode:
              props.FacingMode === undefined ||
              props.FacingMode === null ||
              props.FacingMode === ""
                ? "user"
                : props.FacingMode,
            width: parseInt(props.width),
            deviceId: props.deviceId,
          }}
          mirrored={
            props.Mirrored === undefined ||
            props.Mirrored === "" ||
            props.Mirrored === null
              ? navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/Android/i)
                ? true
                : false
              : props.Mirrored
          }
        />
      ) : (
        <Webcam
          muted={true}
          audio={true}
          ref={webcamRef}
          screenshotQuality={1}
          style={
            props.isDisplay === false
              ? { display: "none", width: "100%" }
              : { display: "block", width: "100%" }
          }
          videoConstraints={{
            facingMode:
              props.FacingMode === undefined ||
              props.FacingMode === null ||
              props.FacingMode === ""
                ? "user"
                : props.FacingMode,
            width: parseInt(props.width),
          }}
          mirrored={
            props.Mirrored === undefined ||
            props.Mirrored === "" ||
            props.Mirrored === null
              ? navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/Android/i)
                ? true
                : false
              : props.Mirrored
          }
        />
      )}

      {/* {capturing ? (
      <button onClick={handleStopCaptureClick}>Stop Capture</button>
    ) : (
      <button onClick={handleStartCaptureClick}>Start Capture</button>
    )}
    {recordedChunks.length > 0 && (
      <button onClick={handleDownload}>Download</button>
    )} */}
    </>
  );
};
