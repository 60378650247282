import { expedienteConstants } from "../constants";
import { WorkFlowService } from "../../services/WorkFlowService";

const request = () => {
    return {
      type: expedienteConstants.RECORDS_REQUEST,
    };
  };
  
  const success = (dataRecord) => {
    return {
      type: expedienteConstants.RECORDS_SUCCESS,
      payload: dataRecord,
    };
  };
  
  const failure = (error) => {
    return {
      type: expedienteConstants.RECORDS_FAILURE,
      payload: error,
    };
  };

  export const resetStatusStack = () => {
    return {
      type: expedienteConstants.RECORDS_RESET,
    };
  };
  
  export const getStageRecords = (dataRecord) => {
    return async (dispatch) => {
      dispatch(request());
      try {
        const recordService = WorkFlowService.getInstance();
        const recordData = await recordService.getStageRecords(dataRecord);
        dispatch(success(recordData.Body));
      } catch (error) {
        dispatch(failure(error.message));
      }
    };
  };

  export const getStageRecordsDocument = (dataRecord) => {
    return async (dispatch) => {
      dispatch(request());
      try {
        const recordService = WorkFlowService.getInstance();
        const recordData = await recordService.getStageRecordsDocument(dataRecord);
        dispatch(success(recordData.Body));
      } catch (error) {
        dispatch(failure(error.message));
      }
    };
  };