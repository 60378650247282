import React, { useEffect, useState } from "react";
import { Grid, Button, useTheme, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getFiles, getGlobalFiles } from "../../store/actions";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { InputField } from "../../components/InputField";
import { FileService } from "../../services/FileService";
import ExportExcel from "./ExportExcel";
import { WhiteTooltip } from "../../components/WhiteTooltip";

export const InboxTableHeader = (props) => {
  const { searching } = props;
  const stateRedux = useSelector((state) => state);
  const [filters, setfilters] = useState([]);
  const [selectedFilters, setselectedFilters] = useState([]);
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const { control, reset, watch, register, setValue } = useFormContext();
  const watchInputSearch = watch("inputSearch");
  const watchStartDate = watch("startDate");
  const watchFinishDate = watch("finishDate");

  const handleClearFilter = () => {
    if (
      watchInputSearch ||
      watchFinishDate ||
      watchStartDate ||
      selectedFilters
    ) {
      reset();
      if (searching) {
        setselectedFilters([]);
        dispatch(
          getGlobalFiles({
            SearchText: "",
            FechaInicio: "",
            FechaFin: "",
            Page: 0,
          })
        );
        setValue("inputSearch", "");
      } else {
        dispatch(
          getFiles({
            SearchText: "",
            FechaInicio: "",
            FechaFin: "",
            Page: 0,
          })
        );
        setValue("inputSearch", "");
      }
    }
  };

  useEffect(() => {
    reset();
    setselectedFilters([]);
  }, [location.pathname, reset]);

  useEffect(() => {
    const getFiltersSelect = async () => {
      try {
        const fileService = FileService.getInstance();
        const response = await fileService.getFilters();
        setfilters(response.Body);
      } catch (err) {
        console.log(err);
        setfilters([]);
      }
    };
    if (searching) {
      getFiltersSelect();
    }
  }, [searching]);

  console.log(stateRedux);

  useEffect(() => {
    if (props.resetFilter === true) {
      setValue("inputSearch", "");
      props.setResetFilter(false);
    }
  }, [props.resetFilter]);

  return (
    <Grid
      container
      spacing={1}
      sx={{
        paddingTop: 0,
        mb: 3,
        justifyContent: { xs: "center", md: "flex-start" },
      }}
    >
      <Typography
        sx={{ display: { xs: "block", md: "none" }, mt: 1 }}
        variant="titulo"
      >
        {stateRedux?.GenericData?.title}
      </Typography>
      {searching ? (
        <>
          <Grid item xs={10} md={4} lg={!searching ? 6 : 7.5}>
            <InputField
              inputType="text"
              control={control}
              placeholder="Ingresa el nombre documental, folio o nombre del solicitante"
              name="inputSearch"
              posIcon="start"
              icon={
                <Search
                  sx={{ color: theme.palette.primary.main }}
                  fontSize="small"
                />
              }
              onEnter={(e) => {
                if (e.key === "Enter" && e.shiftKey === false) {
                  return props.handleFilter();
                }
              }}
              sxInput={{
                "& .MuiOutlinedInput-input": {
                  paddingLeft: 0,
                },
                "& .MuiOutlinedInput-root": {
                  bgcolor: theme.palette.action.hover,
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                },
              }}
              sxLabel={{ color: "#888 !important" }}
            />
          </Grid>
          <Grid item xs={2} sx={{ display: { xs: "block", md: "none" } }}>
            <ExportExcel search={watchInputSearch} />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={10} md={5} lg={6}>
            <InputField
              inputType="text"
              control={control}
              placeholder="Ingresa el nombre documental, folio o nombre del solicitante"
              name="inputSearch"
              onEnter={(e) => {
                if (e.key === "Enter" && e.shiftKey === false) {
                  return props.handleFilter();
                }
              }}
              posIcon="start"
              icon={
                <Search
                  sx={{ color: theme.palette.primary.main }}
                  fontSize="small"
                />
              }
              sxInput={{
                "& .MuiOutlinedInput-input": {
                  paddingLeft: 0,
                },
                "& .MuiOutlinedInput-root": {
                  bgcolor: theme.palette.action.hover,
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                },
              }}
              sxLabel={{ color: "#888 !important" }}
            />
          </Grid>
          <Grid item xs={2} sx={{ display: { xs: "block", md: "none" } }}>
            <ExportExcel search={watchInputSearch} />
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}
        sm={5}
        md={3}
        lg={1.5}
        sx={(theme) => ({
          display: "grid",
          alignContent: "end",
        })}
      >
        <Button
          variant="contained"
          fullWidth
          sx={{
            fontSize: "14px !important",
            height: 45,
          }}
          onClick={props.handleFilter}
        >
          Buscar
        </Button>
      </Grid>

      <Grid
        item
        xs={12}
        sm={5}
        md={3}
        lg={2}
        sx={(theme) => ({
          display: "grid",
          alignContent: "end",
          [theme.breakpoints.down("sm")]: {
            my: 0,
          },
        })}
      >
        <Button
          fullWidth
          variant="outlined"
          color="secondary"
          sx={{
            fontSize: "14px !important",
            whiteSpace: "nowrap",
            height: 45,
          }}
          onClick={handleClearFilter}
        >
          Limpiar búsqueda
        </Button>
      </Grid>
      {!searching ? (
        <Grid
          item
          xs={12}
          sm={5}
          md={3}
          lg={1.5}
          sx={(theme) => ({
            display: "grid",
            alignContent: "end",
            [theme.breakpoints.down("sm")]: {
              my: 0,
            },
          })}
        >
          <WhiteTooltip
            arrow
            enterTouchDelay={0}
            placement="top"
            title={"Cancelar solicitudes."}
          >
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              sx={{
                fontSize: "14px !important",
                whiteSpace: "nowrap",
                height: 45,
              }}
              onClick={props.delteExpediente}
            >
              Cancelar
            </Button>
          </WhiteTooltip>
        </Grid>
      ) : null}
      <Grid
        item
        xs={12}
        sm={1}
        md={1}
        sx={(theme) => ({
          display: { xs: "none", md: "grid" },
          alignItems: "center",
        })}
      >
        <ExportExcel search={watchInputSearch} />
      </Grid>
    </Grid>
  );
};
