import { workFlowConstants } from "../constants";
import { WorkFlowService } from "../../services/WorkFlowService";

const request = (actionType) => {
  return {
    type: actionType,
  };
};

const success = (filesData, actionType) => {
  return {
    type: actionType,
    payload: filesData,
  };
};

const failure = (error, actionType) => {
  return {
    type: actionType,
    payload: error,
  };
};

export const ContinueWorkFlow = (dataContinueWF) => {
  return async (dispatch) => {
    dispatch(request(workFlowConstants.CONTINUE_WORKFLOW_REQUEST));
    try {
      const WFService = WorkFlowService.getInstance();
      const wfData = await WFService.ContinueWorkFlow(dataContinueWF);
      dispatch(success(wfData.Body, workFlowConstants.CONTINUE_WORKFLOW_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, workFlowConstants.CONTINUE_WORKFLOW_FAILURE));
    }
  };
};

export const CancelWorkFlow = (dataCancelWF) => {
  return async (dispatch) => {
    dispatch(request(workFlowConstants.CANCEL_WORKFLOW_REQUEST));
    try {
      const WFService = WorkFlowService.getInstance();
      const wfData = await WFService.CancelWorkFlow(dataCancelWF);
      dispatch(success(wfData.Body, workFlowConstants.CANCEL_WORKFLOW_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, workFlowConstants.CANCEL_WORKFLOW_FAILURE));
    }
  };
};