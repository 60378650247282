import { validationConstants } from "../constants";
import { FileFlowService } from "../../services/FileFlowService";

const request = (actionType) => {
  return {
    type: actionType,
  };
};

const success = (filesData, actionType) => {
  return {
    type: actionType,
    payload: filesData,
  };
};

const failure = (error, actionType) => {
  return {
    type: actionType,
    payload: error,
  };
};

export const SetDocumentValidation = (data) => {
  return async (dispatch) => {
    dispatch(request(validationConstants.SET_DOC_VALIDATION_REQUEST));
    try {
      const WFService = FileFlowService.getInstance();
      const wfData = await WFService.SetDocumentValidation(data);
      dispatch(success(wfData.Body, validationConstants.SET_DOC_VALIDATION_SUCCESS));
    } catch (error) {
      dispatch(failure(error.message, validationConstants.SET_DOC_VALIDATION_FAILURE));
    }
  };
};