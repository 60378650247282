import React from 'react';

export const UseImage = props => {
  return (
    (() => {
      switch (props?.type) {
        case 'Image':
          // console.log("case IMAGE name: ", props.src)
          let images = require.context('../assets', true, /\.(gif|png|jpe?g|svg)$/)
          // console.log("Images--- ", images)
          let loadImage = (imageName) => (images(`./${imageName}`).default)
          // console.log("case IMAGE name loadImage(props.src): ", props.src, loadImage)
          return <img src={require(`../assets/icons/${props.src}`)}
            height={props.height !== null && props.height !== undefined ? `${props.height}px !important` : ""}
            width={props.width !== null && props.width !== undefined ? `${props.width}px !important` : ""}
            className={props.className} />

        default: return null;
      }
    }).call(this)
  )
}