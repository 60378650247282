import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { FileService } from "../../services/FileService";
import BackdropComponent from "../../components/BackDrop";
import { Alert } from "../../components/Alert";
import { UTCtoLocal } from "../../utils";
import { renderToString } from "react-dom/server";
import { UsersService } from "../../services/UsersService";

export function UnAuthenticated() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const query = new URLSearchParams(useLocation().search);
  const questionId = query.get("ActionId");
  const email = query.get("Email");
  const fileId = query.get("FileId");
  
  const dataUsuario = useCallback(async () => {
    const fileService = FileService.getInstance();
    const dataStage = await fileService.getFileUserActionCustom({
      ActionUid: questionId,
    });
    console.log("dataStage", dataStage);
    // https://solusign-cap-portal.azurewebsites.net/
    try {
      if (dataStage.data.data.Body.IsCorrect === true) {
        return navigate(`/auth/login?ActionId=${questionId}`);

        // if (dataStage.data.data.Body.Pin_OTP === (null || undefined || "")) {
        //   sessionStorage.clear();
        //   setLoading(false);
        //   sessionStorage.setItem(
        //     "emailUser",
        //     dataStage.data.data.Body.Autientication?.UserLoginData?.Email
        //   );
        // } else {
        //   setLoading(false);
        //   sessionStorage.clear();
        //   sessionStorage.setItem(
        //     "token",
        //     dataStage.data.data.Body.Autientication?.Token
        //   );
        //   sessionStorage.setItem(
        //     "userName",
        //     `${dataStage.data.data.Body.Autientication?.UserLoginData.Name} ${
        //       dataStage.data.data.Body.Autientication?.UserLoginData
        //         .FatherLastName ?? ""
        //     } ${
        //       dataStage.data.data.Body.Autientication?.UserLoginData
        //         ?.MotherLastName ?? ""
        //     }`
        //   );
        //   sessionStorage.setItem(
        //     "userNameDisplay",
        //     `${dataStage.data.data.Body.Autientication?.UserLoginData.Name}`
        //   );
        //   sessionStorage.setItem(
        //     "Rol",
        //     `${dataStage.data.data.Body.Autientication?.UserLoginData.SecurityLoginData.Roles[0].Name}`
        //   );
        //   // PARA METER LOS DATOS LO LOGIN
        //   dispatch(noAuthentication(dataStage.data.data.Body.Autientication));

        //   if (viewId) {
        //     return navigate(viewId);
        //   } else if (dataStage.data.data.Body?.Pin_OTP) {
        //     console.log("ENTRO A OTP");
        //     sessionStorage.setItem("OTP", dataStage.data.data.Body.Pin_OTP);
        //     return navigate("/inbox/file-information");
        //   } else {
        //     // //PARA GUARDAR LOS DATOS
        //     dispatch(
        //       getFileUserActionCustom(dataStage.data.data.Body.FileCustomData)
        //     );
        //     return navigate("/inbox/file-information");
        //   }
        // }
      } else {
        Alert({
          title: "Lo sentimos",
          icon: "",
          okbtntext: "Confirmar",
          html: renderToString(
            <Box>
              <Typography
                textAlign={"justify"}
                sx={{ maxWidth: 400, mx: "auto" }}
              >
                El archivo de nombre{" "}
                <b>{dataStage.data.data.Body.FileCustomData.Name}</b>, con folio{" "}
                <b>{dataStage.data.data.Body.FileCustomData?.FileData?.Id}</b>{" "}
                creado el día{" "}
                <b>
                  {UTCtoLocal(
                    dataStage.data.data.Body.FileCustomData?.FileData
                      ?.CreationDate,
                    false
                  )}
                </b>{" "}
                está en proceso de{" "}
                <b>
                  {dataStage.data.data.Body.FileCustomData?.FileData?.StageName}
                </b>
              </Typography>
            </Box>
          ),
        });
        return navigate("/auth/login");
      }
    } catch (error) {
      if (dataStage.data.data.Body.Loked === true) {
        Alert({
          title: "Datos no encontrados",
          icon: "",
          okbtntext: "Confirmar",
          text: dataStage.data.data.Body.ExceptionMessage,
        });
        return navigate(
          `/auth/newUser?ActionId=${dataStage.data.data.Body.TokenRegisterUserCustom}`
        );
      } else {
        Alert({
          title: "Lo sentimos",
          icon: "",
          okbtntext: "Confirmar",
          text: dataStage.data.Body.Messages,
        });
        return navigate("/auth/login");
      }
    }
  }, [questionId]);

  const reenvioRegistro = useCallback(async () => {
    const usersService = UsersService.getInstance();
    const response = await usersService.getUserByCorrero({
      SearchText: email,
    });
    console.log("BODY CORREO", response?.Body?.Locked);

    if (response?.Body?.Locked === true) {
      Alert({
        icon: "warnign",
        text: "Para comenzar a realizar acciones en tu solicitud, es necesario que completes el registro",
        okbtntext: "Aceptar",
      });
      return navigate(`/auth/newUser?Email=${email}`);
    } else {
      return navigate(`/auth/login?Email=${email}&FileId=${fileId}`);
    }
  }, [email]);

  useEffect(() => {
    setLoading(true);
    if (questionId) {
      dataUsuario();
    } else if (email) {
      reenvioRegistro(email);
    }
  }, [questionId, email, dataUsuario]);

  return (
    <Box>
      <BackdropComponent loading={loading} />
    </Box>
  );
}
