import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    timer: {
        fontStyle: 'normal',
        fontWeight: '500',        
        lineHeight: '130%',
        color: '#000000',
        // margin: '3rem 0',
        width: '100%',
        display: 'flex',
        // height: '12%',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    digits: {
        fontFamily: 'Montserrat',
        fontSize: '10px',
        color: '#000000',
    },
    miliSec: {
        color: '#e42a2a',
        width: '15px',
    },
}));

export default function Timer(props) {
    const classes = useStyles();

    return (
        <div className={classes.timer}>
             <span className={classes.digits}>
                00:
            </span>
            <span className={classes.digits}>
                {("0" + Math.floor((props.time / 60000) % 60)).slice(-2)}:
            </span>
            <span className={classes.digits}>
                {("0" + Math.floor((props.time / 1000) % 60)).slice(-2)}
            </span>
            {/* <span className={`${classes.digits} ${classes.miliSec}`}>
                {("0" + ((props.time / 10) % 100)).slice(-2)}
            </span> */}
        </div>
    );
}