import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Typography } from '@mui/material'
import React, { Fragment, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Alert } from '../../../components/Alert';
import BackdropComponent from '../../../components/BackDrop'
import { InputField } from '../../../components/InputField';
import { FileService } from '../../../services/FileService';
import 'react-phone-input-2/lib/style.css'
import { CustomInputField } from '../../../components/CustomInputField';

export default function EditProfiel({ setLoading }) {
    const auth = useSelector((state) => state.Authentication?.items?.UserLoginData);
    const navigate = useNavigate();
    const { control, handleSubmit, setValue, register } = useForm();

    useEffect(() => {
        if (auth) {
            setValue('name', auth?.Name);
            setValue('fatherLastName', auth?.FatherLastName);
            setValue('phoneUser', auth?.PhoneNumber);
            setValue('email', auth?.Email);
        }
    }, [auth])

    const handleService = async (data) => {
        if (!data.phoneUser) {
            return null;
        }
        try {
            const fileService = FileService.getInstance();
            const response = await fileService.updateUserCustom({
                Id: auth.Id,
                Password: '',
                Name: data.name,
                FatherLastName: data.fatherLastName,
                MotherLastName: '',
                Email: auth.Email,
                PhoneNumber: data.phoneUser,
                // Metadata: [],    
                IsUpdateUser: true,
            });
            console.log(response)
            if (response.Body) {
                Alert(
                    {
                        title: "Edición exitosa",
                        icon: "success",
                        okbtntext: "Salir",
                    },
                    navigate("/auth/login")
                );
            }
            setLoading(false);
        } catch (error) {
            console.log(error)
            Alert(
                {
                    title: "Error al editar el usuario",
                    icon: "error",
                    okbtntext: "Salir",
                },
            );
        } finally {
            // navigate("/inbox/files")
        }
    };

    return (
        <Fragment>
            <BackdropComponent />
            <Grid container justifyContent={"center"} alignItems={'center'}>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <form onSubmit={handleSubmit(handleService)}>
                            <Box>
                                <Box sx={{ mt: 2 }}>

                                    <InputField
                                        name="email"
                                        disabled={true}
                                        id="email"
                                        label="Correo electrónico"
                                        control={control}
                                        fullWidth
                                        sx={{ borderRadius: "12px" }}
                                    // validations={{ required: true }}
                                    />
                                    <InputField
                                        name="name"
                                        id="name"
                                        label="Nombre (s)"
                                        control={control}
                                        fullWidth
                                        sx={{ borderRadius: "12px" }}
                                        validations={{ required: true }}
                                    />
                                    <InputField
                                        name="fatherLastName"
                                        id="fatherLastName"
                                        label="Apellido (s)"
                                        control={control}
                                        fullWidth
                                        sx={{ borderRadius: "12px" }}
                                        validations={{ required: true }}
                                    />
                                    <Box sx={{ mt: 2 }}>
                                        <CustomInputField
                                            inputType="phone"
                                            control={control}
                                            register={register}
                                            name={"phoneUser"}
                                            label={"Número de celular"}
                                            validations={{ required: true }}
                                            required={true}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", p: 1 }}>
                                <Box width={"90%"} sx={{ p: 1 }}>
                                    <Button
                                        fullWidth
                                        sx={{ height: 45 }}
                                        variant="outlined"
                                        size="medium"
                                        onClick={() => navigate("/inbox/files")}
                                    >
                                        <Typography sx={{ letterSpacing: 1.2 }}>Cancelar</Typography>
                                    </Button>
                                </Box>
                                <Box width={"90%"} sx={{ p: 1 }}>
                                    <LoadingButton
                                        fullWidth
                                        sx={{ height: 45 }}
                                        variant="contained"
                                        size="medium"
                                        onClick={handleSubmit(handleService)}
                                    >
                                        <Typography sx={{ letterSpacing: 1.2 }}>Enviar</Typography>
                                    </LoadingButton>
                                </Box>

                            </Box>
                        </form>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    )
}
