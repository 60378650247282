import React, { Fragment } from 'react'
import GrabacionVideoTextExpensiveResponsive from './StagesSignature/GrabacionVideoTextExpensiveResponsive';
import GrabacionVideoTextExpensive from './StagesSignature/GrabacionVideoTextExpensive';

export default function IndexVideo(props) {

    return (
        <Fragment>
            {navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/Android/i) ? (
                // <GrabacionVideoTextExpensive setValue={props.setValue} isActiveMassiveSignature={props.isActiveMassiveSignature} />
                <GrabacionVideoTextExpensiveResponsive setViewDocument={props.setViewDocument} setValue={props.setValue} isActiveMassiveSignature={props.isActiveMassiveSignature} />
            ) : (
                <GrabacionVideoTextExpensive setValue={props.setValue} isActiveMassiveSignature={props.isActiveMassiveSignature} />
            )}
        </Fragment>
    )
}
