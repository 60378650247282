import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
  customClass: {
    popup: "dstoast",
    icon: "dsicon",
  },
});

export const ToastNotification = (props) => {
  return Toast.fire({
    icon: props.icon ?? "success",
    title: props.text ?? "",
  });
};
