import React from "react";
import { Box, styled, Typography, useTheme, useMediaQuery } from "@mui/material";
import wave from "../assets/wave.png";
import packageJson from "../../package.json";
import logoDocBlack from "../assets/SoluSingLogoPrincipal.png"

const RootStyle = styled(Box)(({ theme }) => ({
  width: "100%",
  maxHeight: 40,
  minHeight: 40,
  marginTop: "auto",
  display: "grid",
  alignContent: "end",
  justifyContent: "center",
  position: "relative",
  zIndex: 0,
  [theme.breakpoints.down("sm")]: {
    background: "#FFFFFF",
    margin: 0,
    display: 'block',
    textAlign: 'center',
    zIndex: 0,
  },
}));
const Wave = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  bottom: -10
}));

export const Footer = () => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <RootStyle>
      <Box sx={{ display: "flex",  }}>
        <Typography
          variant="body2"
          color="#000000"
          sx={{
            zIndex: 1, pb: 1, display: "flex", justifyContent: 'center', textAlign: "center",
            [theme.breakpoints.down("sm")]: {
              background: 'rgba(236, 236, 236, 0.37)',
              width: '100%',
              pb: 0.5,
              pt: 0.5,
            },
          }}
        >
          Derechos reservados por
          <Box sx={{ width: 95, ml: .6, mr: .6 }}>
            <a href="https://solusign.mx/" target="_blank">
              <img alt="logoSolusign" src={logoDocBlack} />
            </a>
          </Box>
          V. {packageJson.version}
          {/* &#xA9; {new Date().getFullYear()} DocSolutions */}
        </Typography>
      </Box>
      {mobileScreen ? null :
        <Wave alt="" src={wave} />
      }
    </RootStyle>
  );
};
