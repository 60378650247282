import { expedienteConstants } from "../constants";

export const File = (state = {}, action) => {
  switch (action.type) {
    case expedienteConstants.FILE_REQUEST:
      return {
        ...state,
        loading: true,
        item: null,
        error: null,
      };

    case expedienteConstants.FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.payload,
        error: null,
      };

    case expedienteConstants.FILE_FAILURE:
      return {
        ...state,
        loading: false,
        item: null,
        error: action.payload,
      };
    case expedienteConstants.RECORDS_REQUEST:
      return {
        ...state,
        loading: true,
        record: null,
        error: null,
      };
    case expedienteConstants.RECORDS_RESET:
      return {
        ...state,
        loading: false,
        record: null,
        error: null,
      };
    case expedienteConstants.RECORDS_SUCCESS:
      return {
        ...state,
        loading: false,
        record: action.payload,
        error: null,
      };

    case expedienteConstants.RECORDS_FAILURE:
      return {
        ...state,
        loading: false,
        record: null,
        error: action.payload,
      };
    case expedienteConstants.SET_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        document: null,
      };
    case expedienteConstants.SET_DOCUMENT:
      return {
        ...state,
        loading: false,
        error: null,
        document: action.payload,
      };

    case expedienteConstants.COMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        coment: null,
      };
    case expedienteConstants.COMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        coment: action.payload,
      };
    case expedienteConstants.COMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        coment: null,
      };
    case expedienteConstants.SET_COMENTS:
      return {
        ...state,
        loading: false,
        error: null,
        coments_doc: action.payload,
      };

    case expedienteConstants.SET_COMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        coments_doc: null,
      };
    case expedienteConstants.SET_COMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        coments_doc: null,
      };
    case expedienteConstants.GET_URL_DOCUMENT:
      return {
        ...state,
        loading: false,
        error: null,
        enlace: action.payload,
      };
    case expedienteConstants.REQUEST_URL_DOCUMENT:
      return {
        ...state,
        loading: true,
        error: null,
        enlace: null,
      };

    // PARA TOMAR EL DOCUMENTO EN BASE 64
    case expedienteConstants.SET_DOCUMENT64:
      return {
        ...state,
        loading: false,
        error: null,
        document_64: action.payload,
      };
    case expedienteConstants.SET_DOCUMENT64_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        document_64: null,
      };
    case expedienteConstants.SET_DOCUMENT64_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        document_64: null,
      };
    //TRAER VERSIONES DE DOCUMENTOS PARA MAPEAR DENTRO DE CB
    case expedienteConstants.GET_DOCUMENT_VERSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        doc_versions: action.payload,
      };
    case expedienteConstants.GET_DOCUMENT_VERSIONS_FAILTURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        doc_versions: null,
      };
    case expedienteConstants.GET_DOCUMENT_VERSIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        doc_versions: null,
      };

    case expedienteConstants.LOADING_SUCCESS:
      return {
        ...state,
        loading_data: true,
      };

    case expedienteConstants.LOADING_FEATURE:
      return {
        ...state,
        loading_data: false,
      };


    // ENTRAN TODOS LOS RESETS
    case expedienteConstants.RESET_COMENTS:
      return {
        ...state,
        coments_doc: null,
      };
    case expedienteConstants.RESET_DOCUMENT64:
      return {
        ...state,
        document_64: null,
      };
    case expedienteConstants.RESET_URL_DOCUMENT_ENLACE:
      return {
        ...state,
        enlace: null,
      };

    case expedienteConstants.RESET_DOCUMENT:
      return {
        ...state,
        document: null,
      };
    case expedienteConstants.RESET_FILE:
      return {
        ...state,
        item: null,
      };
    default:
      return state;
  }
};
