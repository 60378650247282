import React, { useState } from "react";
import Parser from "html-react-parser";
import {
  Alert as AlertMU,
  Box,
  styled,
  Typography,
  Button,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  Dialog,
  IconButton,
  Grid,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import check from "../../assets/Check.png";
import { useDispatch, useSelector } from "react-redux";
import VisorPDF from "../../components/Viewer/VisorPDF";
import { useNavigate } from "react-router-dom";
import {
  getDocument64,
  resetFile,
  setDocsStack,
  trueLoading,
} from "../../store/actions";
import { Alert } from "../../components/Alert";
import { DocumentButton } from "./DocumentButton";
import iconDocument from "../../assets/icons/Documento.png";
import { Close } from "@mui/icons-material";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import ParticipantSortTable from "./ParticipantSortTable";
import { FileFlowService } from "../../services/FileFlowService";
import BackdropComponent from "../../components/BackDrop";
import { UTCtoLocal } from "../../utils";
import ParticipantListResponsive from "./ParticipantListResponsive";
import { DocumentButtonPrevious } from "./DocumentButtonPrevious";
import { DocumentService } from "../../services/DocumentService";
import { renderToString } from "react-dom/server";
import useAsyncEffect from "use-async-effect";
import { FileService } from "../../services/FileService";

const RootStyle = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  width: "100%",
  maxWidth: "95%",
  transition: "height 500ms ease",
}));

const PreviewContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    padding: "10px 10px",
  },
}));

const b64toUrl = async (base64Data) => {
  const r = await fetch(base64Data);
  const blob = await r.blob();
  return URL.createObjectURL(blob);
};

const convertWordToPdf = async (wordB64) => {
  const fileService = FileService.getInstance();
  const response = await fileService.convertWordToPdf(wordB64);
  return response;
};

const convertWordB64ToPdfB64 = async (b64) => {
  const wordToPdf = await convertWordToPdf(b64);
  const wordPdfB64 = `data:application/pdf;base64,${wordToPdf.Body}`;
  const wordUrl = await b64toUrl(wordPdfB64);
  return wordUrl;
};

export const B64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  var atob = require("atob");
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const FilePreview = (props) => {
  const folioIdSolicitud = sessionStorage.getItem("FileId");
  const [docSelected, setdocSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [observacionesCurrent, setObservacionesCurrent] = useState("");
  const [blobUrl, setBlobUrl] = useState("");

  const dispatch = useDispatch();
  const newFileInfo = useSelector((state) => state.NewFile.file);
  const newFileState = useSelector((state) => state.NewFile);
  const fileState = useSelector((state) => state.File);
  const docsStack = useSelector((state) => state.NewFile.documentsStack);
  const docsStackPrerre = useSelector(
    (state) =>
      state.NewFile?.dataPrerrequesitos?.PrerequisitesConfiguration
        ?.PrerequisitesDocumentList
  );

  const dataPrerre = useSelector((state) => state.File?.item?.Prerequisites);

  const docsStackPrerreFile = useSelector(
    (state) => state.File?.item?.Prerequisites?.PrerequisitesDocumentList
  );

  const listParticipants = useSelector(
    (state) => state.File?.item?.Participants
  );

  const signParticipants = listParticipants?.filter(
    (item) => item?.Sign === true
  );
  const checkParticipants = listParticipants?.filter(
    (item) => item.Reviewer === true || item?.Check === true
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (newFileState.editStack) {
      if (fileState?.item) {
        if (docsStack.length === 0) {
          const checkListDocs = fileState?.item?.Documents;
          dispatch(setDocsStack(checkListDocs));
        }
      }
    }
  }, [fileState?.item]);

  const iniciarExpediente = async () => {
    setLoading(true);
    const documentService = DocumentService.getInstance();
    const responseVerification =
      await documentService.VerifyTraceSignConfiguration(folioIdSolicitud);
    if (responseVerification?.data?.Response?.m_Item2 === false) {
      setLoading(false);
      Alert({
        icon: "error",
        okbtntext: "Aceptar",
        html: responseVerification.data.Response.m_Item1,
      });
    } else {
      const fileFlowService = FileFlowService.getInstance();
      const fileFlowData = await fileFlowService.initializeFileFlow({
        Body: newFileState.editStack
          ? fileState.item?.FileData?.Id
          : newFileInfo?.Id,
      });
      if (fileFlowData.data.data.Body) {
        setLoading(false);
        localStorage.removeItem("ctrlExpediente");
        localStorage.removeItem("ctrlReqPrevios");
        navigate("/inbox/files");
        Alert({
          icon: "success",
          title: "Su solicitud ha sido iniciada",
          okbtntext: "Aceptar",
          html: renderToString(
            <Box>
              <Typography
                sx={{
                  maxWidth: 400,
                  mx: "auto",
                  display: "inline",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "21px",
                  lineHeight: "37px",
                  fontFamily: '"Montserrat",sans-serif',
                  ["@media only screen and (max-width: 812px)"]: {
                    fontSize: "15px",
                    lineHeight: "20.72px",
                  },
                }}
              >
                {Parser(
                  `Número de folio: <span style="color: #8CE982"}}>${fileFlowData.data.data.Body.Result.Id}</span>`
                )}
              </Typography>
            </Box>
          ),
        });
        dispatch(trueLoading());
        dispatch(resetFile());
      } else {
        setLoading(false);
        navigate("/inbox/files");
        Alert({
          icon: "error",
          title: "Error",
          text: "Hubo un error. " + fileFlowData.data.data.Messages,
        });
        dispatch(trueLoading());
        dispatch(resetFile());
      }
    }
  };

  useEffect(() => {
    if (fileState.item) {
      var observaciones = fileState?.item?.FileData?.Metadata?.find(
        (item) =>
          item.GroupName === "Alta Proveedor" && item.Name === "Observaciones"
      )?.Value;
      console.log(observaciones);
      setObservacionesCurrent(observaciones);
    }
  }, [fileState.item]);

  useAsyncEffect(async () => {
    setBlobUrl(null);
    if (
      docSelected !== undefined &&
      docSelected !== null &&
      docSelected !== ""
    ) {
      const fileService = FileService.getInstance();
      const comentData = await fileService.getDownloadDocument({
        document_Id: docSelected.DocumentData.Id,
        ConvertSfdt: false,
      });
      traerDocumentos(comentData);
    }
  }, [docSelected]);

  const traerDocumentos = (comentData) => {
    if (comentData.Body) {
      var extension =
        comentData.Body.FileName.split(".").length > 1
          ? comentData.Body.FileName.split(".")[1]
          : "pdf";
      if (extension === "docx") {
        const docConvertido = convertWordB64ToPdfB64(
          comentData?.Body?.B64Content
        );
        docConvertido.then((value) => {
          setBlobUrl(value);
        });
      } else {
        const blob = B64toBlob(comentData?.Body?.B64Content, "application/pdf");
        setBlobUrl(URL.createObjectURL(blob));
      }
    }
  };

  return (
    <RootStyle>
      <BackdropComponent
        loading={
          loading || listParticipants === undefined || fileState?.item === null
        }
      />
      <PreviewContainer>
        <Box sx={{ mb: 3 }}>
          <Box sx={{ mb: 1 }}>
            <Typography variant="titulo">
              Información de la solicitud
            </Typography>
          </Box>
          <InfoLabel
            label="Fecha de creación:"
            value={UTCtoLocal(
              newFileInfo?.CreationDateFormatted ||
                fileState.item?.FileData?.CreationDate,
              true
            )}
          />
          <InfoLabel
            label="Vigencia de la solicitud:"
            value={UTCtoLocal(
              newFileInfo?.fileForm?.FileConfiguration?.Validity ||
                fileState.item?.Validity,
              false
            )}
          />
          <InfoLabel label="Observaciones:" value={observacionesCurrent} />
        </Box>
        <Box
          sx={{
            mb: 2,
            borderBottom: 1.5,
            borderColor: "secondary.main60",
            width: "90%",
          }}
        >
          <Box sx={{ mb: 1 }}>
            <Typography variant="titulo">Documentos</Typography>
          </Box>
        </Box>

        <Box sx={(theme) => ({ boxShadow: theme.shadows[9], mt: 2 })}>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{ mt: 2, display: { xs: "none", sm: "block" } }}
          >
            <Table
              sx={{
                minWidth: 650,
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead
                sx={{
                  outline: "0px solid #FFFFFF",
                  borderBottom: 1,
                  borderColor: "primary.main60",
                  borderRadius: 0,
                }}
              >
                <TableRow>
                  <TableCell sx={{ width: 300 }} align="left">
                    Nombre del documento
                  </TableCell>
                  <TableCell width={100} align="center">
                    Documento previo
                  </TableCell>
                  <TableCell width={100} align="center">
                    Complemento
                  </TableCell>
                  <TableCell width={100} align="center">
                    Captura
                  </TableCell>
                  <TableCell width={100} align="center">
                    Revisión
                  </TableCell>
                  <TableCell width={100} align="center">
                    Firma
                  </TableCell>
                  <TableCell width={50} align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {console.log("docsStackPrerreFile", docsStack)}
                {docsStackPrerre === undefined || docsStackPrerre === null
                  ? docsStackPrerreFile?.map((doc, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Box
                            sx={{
                              display: "flex",
                              ml: 1,
                              justifyContent: "left",
                              textAlign: "left",
                              alignItems: "center",
                            }}
                          >
                            <Icon
                              icon="system-uicons:document-stack"
                              style={{
                                color: "#C20E30",
                                fontSize: 24,
                                marginRight: 5,
                              }}
                            />
                            <Typography variant="body2">
                              {`Documento - ${doc.DocumentName}`}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <img
                              src={check}
                              alt="icon"
                              style={{ height: 28 }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <b>-</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>-</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>-</b>
                        </TableCell>
                      </TableRow>
                    ))
                  : docsStackPrerre?.map((doc, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Box
                            sx={{
                              display: "flex",
                              ml: 1,
                              alignItems: "center",
                              justifyContent: "left",
                              textAlign: "left",
                            }}
                          >
                            <Icon
                              icon="system-uicons:document-stack"
                              style={{
                                color: "#C20E30",
                                fontSize: 24,
                                marginRight: 4,
                              }}
                            />
                            <Typography variant="body2">
                              {`Documento - ${doc.DocumentName}`}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <img
                              src={check}
                              alt="icon"
                              style={{ height: 28 }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <b>-</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>-</b>
                        </TableCell>
                        <TableCell align="center">
                          <b>-</b>
                        </TableCell>
                      </TableRow>
                    ))}
                {docsStack.map((doc, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: "0px !important",
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <DocumentButton
                        style={{ borderBottom: "0px !important" }}
                        key={doc.DocResponseId}
                        onSelect={() => setdocSelected(doc)}
                        customComponent={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "left",
                              textAlign: "left",
                            }}
                          >
                            <img
                              style={{ width: 15, marginRight: 7 }}
                              alt="imagenLogo"
                              src={iconDocument}
                            />
                            <Typography variant="body2">
                              {`Documento - ${
                                doc?.DocumentConfiguration?.FileName ||
                                doc?.FileName
                              }`}
                            </Typography>
                          </Box>
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <b>-</b>
                    </TableCell>
                    <TableCell align="center">
                      {doc?.DocumentConfiguration?.OnlyView === true ||
                      doc?.OnlyView === true ? (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <img src={check} alt="icon" style={{ height: 28 }} />
                        </Box>
                      ) : (
                        <b>-</b>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {doc?.TypeDocumentoProcess === "DOCX con campos" ? (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <img src={check} alt="icon" style={{ height: 28 }} />
                        </Box>
                      ) : (
                        <b>-</b>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {doc?.DocumentConfiguration?.Check === true ||
                      doc?.Check === true ? (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <img src={check} alt="icon" style={{ height: 28 }} />
                        </Box>
                      ) : (
                        <b>-</b>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {doc?.DocumentConfiguration?.Sign === true ||
                      doc?.Sign === true ? (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <img src={check} alt="icon" style={{ height: 28 }} />
                        </Box>
                      ) : (
                        <b>-</b>
                      )}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={(theme) => ({
              boxShadow: theme.shadows[5],
              mt: 2,
              display: { xs: "block", sm: "none" },
            })}
          >
            {dataPrerre?.ActivePrerequisites === true
              ? dataPrerre?.UploadPrerrequisitesStatusDocument?.map((item) => (
                  <DocumentButtonPrevious titulo={item?.DocumentName} />
                ))
              : null}
            {newFileState.documentsStack.map((doc, i) => (
              <DocumentButton
                preview={true}
                key={doc.DocResponseId}
                doc={doc}
                listDocs={true}
              />
            ))}
          </Box>
        </Box>
        {console.log("docSelected", docSelected)}
        {docSelected &&
          (blobUrl ? (
            <Dialog
              open={!!docSelected}
              onClose={() => setdocSelected(null)}
              fullWidth
              maxWidth="lg"
            >
              <Box sx={{ p: 2, position: "relative" }}>
                <IconButton
                  size="small"
                  sx={{ position: "absolute", right: 8, top: 2 }}
                  onClick={() => setdocSelected(null)}
                >
                  <Close fontSize="small" />
                </IconButton>
                <VisorPDF
                  pdfUrl={blobUrl}
                  pdfName={
                    docSelected.DocumentConfiguration
                      ? docSelected.DocumentConfiguration.FileName
                      : docSelected?.FileName
                  }
                />
              </Box>
            </Dialog>
          ) : (
            <BackdropComponent loading={true} />
          ))}

        <Box sx={{ mb: 1, mt: 3 }}>
          <Typography variant="titulo">Participantes</Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <AlertMU
            sx={{
              backgroundColor: "#FFB80059",
              color: "#000000",
            }}
            severity="info"
          >
            En la siguiente tabla podrás visualizar el orden de intervención de
            cada uno de los participantes. Serán notificados cuando sea el turno
            de realizar una acción.
          </AlertMU>
        </Box>

        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          {listParticipants?.length > 0 ? (
            <ParticipantSortTable listRows={listParticipants} />
          ) : null}
        </Box>

        <Box sx={{ display: { xs: "block", sm: "none" } }}>
          {dataPrerre?.ActivePrerequisites === true ? (
            <>
              <Box sx={{ borderBottom: "1px solid #C20E30", mb: 1 }}>
                <Typography variant="h6">Requisitos previos</Typography>
              </Box>
              {dataPrerre?.ActivePrerequisiteApproval ? (
                <ParticipantListResponsive
                  key={dataPrerre.PrerequisiteApprovalUserId}
                  user={dataPrerre.PrerequisiteApprovalUserFullName}
                  dateDeadline={dataPrerre.UploadPrerequisitesDeadline}
                  type={"previous"}
                  view={"filePreview"}
                  functionUser={"aprobador"}
                />
              ) : null}
              <ParticipantListResponsive
                key={dataPrerre.UploadPrerequisitesUserId}
                user={dataPrerre.UploadPrerequisitesUserFullName}
                dateDeadline={dataPrerre.UploadPrerequisitesDeadline}
                type={"previous"}
                view={"filePreview"}
                functionUser={"uploadUser"}
              />
            </>
          ) : null}

          {checkParticipants?.length !== 0 ? (
            <>
              <Box sx={{ borderBottom: "1px solid #C20E30", mb: 1, mt: 2 }}>
                <Typography variant="h6">Revisores</Typography>
              </Box>
              {checkParticipants?.map((user) => (
                <ParticipantListResponsive
                  key={user.UserId}
                  user={user.FullName}
                  dateDeadline={user.CheckDeadline}
                  type={"checkers"}
                  view={"filePreview"}
                  functionUser={user.Check}
                />
              ))}
            </>
          ) : null}

          {signParticipants?.length !== 0 ? (
            <>
              <Box sx={{ borderBottom: "1px solid #C20E30", mb: 1, mt: 2 }}>
                <Typography variant="h6">Firmantes</Typography>
              </Box>
              {signParticipants?.map((user) => (
                <ParticipantListResponsive
                  key={user.UserId}
                  user={user.FullName}
                  dateDeadline={user.SignDeadline}
                  type={"signers"}
                  view={"filePreview"}
                  userComplete={user}
                />
              ))}
            </>
          ) : null}
        </Box>

        <Grid container justifyContent="center">
          {newFileState?.editStack ? (
            <Grid item xs={12} sm={4}>
              <Box textAlign={"center"}>
                <Button
                  fullWidth
                  color="secondary"
                  variant="outlined"
                  sx={{ maxWidth: 300, my: 1, minHeight: 45 }}
                  onClick={props.handleBackStep}
                >
                  Anterior
                </Button>
              </Box>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={4}>
            <Box textAlign={"center"}>
              <Button
                variant="contained"
                fullWidth
                disabled={
                  listParticipants === undefined || fileState?.item === null
                }
                onClick={() => {
                  iniciarExpediente();
                }}
                sx={{ maxWidth: 300, my: 1, minHeight: 45 }}
              >
                Iniciar proceso
              </Button>
            </Box>
          </Grid>
        </Grid>
      </PreviewContainer>
    </RootStyle>
  );
};

const BoxTexto = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "100%",
  overflow: "auto",
  marginTop: 4,
  marginBottom: 3,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const InfoLabel = (props) => {
  if (!props.label || !props.value) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={3}>
        <Typography variant="subtitle1">{props.label}</Typography>
      </Grid>
      <Grid item xs={8}>
        <BoxTexto>
          <Typography variant="subtitle1">{props.value}</Typography>
        </BoxTexto>
      </Grid>
    </Grid>
  );
};
