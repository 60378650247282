import { genericConstants } from "../constants";
// import { FileService } from "../../services/FileService";

// const request = () => {
//   return {
//     type: genericConstants.SET_TITLE_GRAL_REQUEST,
//   };
// };

// const success = (filesData) => {
//   return {
//     type: expedienteConstants.FILE_SUCCESS,
//     payload: filesData,
//   };
// };

// const failure = (error) => {
//   return {
//     type: expedienteConstants.FILE_FAILURE,
//     payload: error,
//   };
// };

export const setTitleGral = (data) => {
  return {
    type: genericConstants.SET_TITLE_GRAL_SUCCESS,
    payload: data,
  };
};
