export const massiveConstants = {
    FILE_PARTICIPANT_REQUEST: "FILE_PARTICIPANT_REQUEST",
    FILE_PARTICIPANT_SUCCESS: "FILE_PARTICIPANT_SUCCESS",
    FILE_PARTICIPANT_FAILURE: "FILE_PARTICIPANT_FAILURE",

    SKIP_PREREQUISITES_REQUEST: "SKIP_PREREQUISITES_REQUEST",
    SKIP_PREREQUISITES_SUCCESS: "SKIP_PREREQUISITES_SUCCESS",
    SKIP_PREREQUISITES_FAILURE: "SKIP_PREREQUISITES_FAILURE",

    UPLOAD_TEMPLATE_REQUEST: "UPLOAD_TEMPLATE_REQUEST",
    UPLOAD_TEMPLATE_SUCCESS: "UPLOAD_TEMPLATE_SUCCESS",
    UPLOAD_TEMPLATE_FAILURE: "UPLOAD_TEMPLATE_FAILURE",

    UPLOAD_TEMPLATE_DATA_REQUEST: "UPLOAD_TEMPLATE_DATA_REQUEST",
    UPLOAD_TEMPLATE_DATA_SUCCESS: "UPLOAD_TEMPLATE_DATA_SUCCESS",
    UPLOAD_TEMPLATE_DATA_FAILURE: "UPLOAD_TEMPLATE_DATA_FAILURE",

    DISABLE_TEMPLATE_REQUEST: "DISABLE_TEMPLATE_REQUEST",
    DISABLE_TEMPLATE_SUCCESS: "DISABLE_TEMPLATE_SUCCESS",
    DISABLE_TEMPLATE_FAILURE: "DISABLE_TEMPLATE_FAILURE",

    UPLOAD_METADATA_TEMPLATE_REQUEST: "UPLOAD_METADATA_TEMPLATE_REQUEST",
    UPLOAD_METADATA_TEMPLATE_SUCCESS: "UPLOAD_METADATA_TEMPLATE_SUCCESS",
    UPLOAD_METADATA_TEMPLATE_FAILURE: "UPLOAD_METADATA_TEMPLATE_FAILURE",

    UPLOAD_METADATA_BATCHFILE_REQUEST: "UPLOAD_METADATA_BATCHFILE_REQUEST",
    UPLOAD_METADATA_BATCHFILE_SUCCESS: "UPLOAD_METADATA_BATCHFILE_SUCCESS",
    UPLOAD_METADATA_BATCHFILE_FAILURE: "UPLOAD_METADATA_BATCHFILE_FAILURE",

    ADD_LISTDOC_STACK_MASSIVE: "ADD_LISTDOC_STACK_MASSIVE",
    SET_LISTDOC_STACK_MASSIVE: "SET_LISTDOC_STACK_MASSIVE",
    RESET_LISTDOC_STACK_MASSIVE: "RESET_LISTDOC_STACK_MASSIVE",

    PREREQUISITOS_TEMPLATE_REQUEST: "PREREQUISITOS_TEMPLATE_REQUEST",
    PREREQUISITOS_TEMPLATE_SUCCESS: "PREREQUISITOS_TEMPLATE_SUCCESS",
    PREREQUISITOS_TEMPLATE_FAILURE: "PREREQUISITOS_TEMPLATE_FAILURE",

    PREREQUISITOS_TEMPLATE_RESET: "PREREQUISITOS_TEMPLATE_RESET",
    PARTICIPANTS_TEMPLATE_RESET: "PARTICIPANTS_TEMPLATE_RESET",


    REODERDER_PARTICIPANT_MASSIVE_REQUEST: "REODERDER_PARTICIPANT_MASSIVE_REQUEST",
    REODERDER_PARTICIPANT_MASSIVE_SUCCESS: "REODERDER_PARTICIPANT_MASSIVE_SUCCESS",
    REODERDER_PARTICIPANT_MASSIVE_FAILURE: "REODERDER_PARTICIPANT_MASSIVE_FAILURE",
    REODERDER_PARTICIPANT_MASSIVE_RESET: "REODERDER_PARTICIPANT_MASSIVE_RESET",
}