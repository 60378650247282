// import * as React from 'react';
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableSortLabel,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  tableCellClasses,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import IconToolTip from "../../components/IconToolTip";
import moment from "moment";

const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontWeight: 400,
    padding: "8px",
    width: 300,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    width: 300,
  },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  pointerEvents: "none",
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontWeight: 400,
    padding: "5px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: `1px solid ${theme.palette.primary.main60}`,
    },
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "CheckOrder",
    numeric: true,
    disablePadding: false,
    label: "Función",
    width: 70,
  },
  {
    id: "CheckDeadlineFormat",
    numeric: false,
    disablePadding: false,
    label: "Fecha límite",
    width: 95,
  },
  {
    id: "CheckOrder",
    numeric: true,
    disablePadding: false,
    label: "Función",
    width: 60,
  },
  {
    id: "CheckDeadlineFormat",
    numeric: false,
    disablePadding: false,
    label: "Fecha límite",
    width: 95,
  },
  {
    id: "SignOrder",
    numeric: true,
    disablePadding: false,
    label: "Orden",
    width: 70,
  },
  {
    id: "SignDeadlineFormat",
    numeric: false,
    disablePadding: false,
    label: "Fecha límite",
    width: 95,
  },
  {
    id: "SignDeadlineFormat",
    numeric: false,
    disablePadding: false,
    label: "Propiedades de la firma",
    width: 60,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell1
          rowSpan={2}
          key="FullName"
          align="center"
          sortDirection={orderBy === "FullName" ? order : false}
        >
          <TableSortLabel
            style={{ textAling: "center" }}
            sx={{ minWidth: 170 }}
            active={orderBy === "FullName"}
            direction={orderBy === "FullName" ? order : "asc"}
            onClick={createSortHandler("FullName")}
          >
            <b>Nombre del participante</b>
          </TableSortLabel>
        </StyledTableCell1>
        <StyledTableCell2 colSpan={2} align="center">
          <b>Requisitos Previos</b>
        </StyledTableCell2>
        <StyledTableCell2 colSpan={2} align="center">
          <b>Revisión</b>
        </StyledTableCell2>
        <StyledTableCell2 colSpan={3} align="center">
          <b>Firmado</b>
        </StyledTableCell2>
      </TableRow>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell1
            key={headCell.id}
            align={"center"}
            sx={{ minWidth: headCell.width }}
          >
            <b>{headCell.label}</b>
          </StyledTableCell1>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function ParticipantSortTableMasive(props) {
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const filtroPreviosUpload = props.listRows?.filter(
    (item) =>
      props.DataPrevious.PrerequisitesLoadUser_Email === item.Email &&
      props.claveGrupo === props.DataPrevious.Code &&
      props.DataPrevious?.PrerequisitesLoadUser_Email !== null
  );

  const filtroPreviosAporvated = props.listRows?.filter(
    (item) =>
      props.DataPrevious.PrerequisitesApprovalUser_Email === item.Email &&
      props.claveGrupo === props.DataPrevious.Code &&
      props.DataPrevious?.PrerequisitesApprovalUser_Email !== null
  );

  useEffect(() => {
    setRows(props.listRows);
  }, [props.listRows]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            className={classes.table}
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            aria-label="simple table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.index}>
                      <StyledTableCell1
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {row.FullName || row.Email}
                      </StyledTableCell1>
                      <StyledTableCell1 align="center">
                        {props.DataPrevious?.PrerequisitesApprovalUser_Email ===
                        row?.Email ? (
                          <IconToolTip
                            src={"Aprobador.png"}
                            title={"Usuario Aprobador."}
                            width={25}
                          />
                        ) : props.DataPrevious?.PrerequisitesLoadUser_Email ===
                          row?.Email ? (
                          <IconToolTip
                            src={"Cargador.png"}
                            title={"Usuario a subir documentos."}
                            width={25}
                          />
                        ) : (
                          <b>-</b>
                        )}
                      </StyledTableCell1>
                      <StyledTableCell1 align="center" sx={{ padding: 0 }}>
                        {props.DataPrevious?.PrerequisitesApprovalUser_Email ===
                        row?.Email ? (
                          moment(
                            props.DataPrevious?.PrerequisitesLoadUser_DeathLine
                          ).format("DD-MM-YYYY")
                        ) : props.DataPrevious?.PrerequisitesLoadUser_Email ===
                          row?.Email ? (
                          moment(
                            props.DataPrevious?.PrerequisitesLoadUser_DeathLine
                          ).format("DD-MM-YYYY")
                        ) : (
                          <b>-</b>
                        )}
                      </StyledTableCell1>
                      <StyledTableCell1 align="center">
                        {row.Reviewer === true ? (
                          <IconToolTip
                            src={"AprobadorRevision.png"}
                            title={"Usuario Aprobador."}
                            width={25}
                          />
                        ) : row.Check === true ? (
                          <IconToolTip
                            src={"Revisor.png"}
                            title={"Usuario Editor."}
                            width={25}
                          />
                        ) : (
                          <b>-</b>
                        )}
                      </StyledTableCell1>
                      <StyledTableCell1 align="center" sx={{ padding: 0 }}>
                        {row.CheckDeadline || row.ReviewerDeadLine !== null ? (
                          moment(
                            row.CheckDeadline || row.ReviewerDeadLine
                          ).format("DD-MM-YYYY")
                        ) : (
                          <b>-</b>
                        )}
                      </StyledTableCell1>
                      <StyledTableCell1 align="center">
                        {row.SignOrder}
                      </StyledTableCell1>
                      <StyledTableCell1 align="center" sx={{ padding: 0 }}>
                        {row.SignDeadline
                          ? moment(row.SignDeadline).format("DD-MM-YYYY")
                          : null}
                      </StyledTableCell1>
                      <StyledTableCell1 align="center" sx={{ width: 50 }}>
                        {row.Sign !== true ? null : row.SignatureOptions
                            .SignatureType ===
                          "Firma Autógrafa en Mensaje de Datos" ? (
                          <Box
                            sx={{
                              minWidth: 100,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <IconToolTip
                              icon={true}
                              src={"teenyicons:otp-outline"}
                              iconColor={
                                row?.SignatureOptions?.OTP
                                  ? "#D65E74"
                                  : "#979797"
                              }
                              title={"Contraseña de un solo uso."}
                              width={18}
                            />
                            <IconToolTip
                              src={"SignSimple.png"}
                              title={"Firma Autógrafa en Mensaje de Datos."}
                              width={24}
                              height={25}
                            />
                            <IconToolTip
                              src={
                                row.SignatureOptions?.VideoRecording
                                  ? "VideoIcon.svg"
                                  : "VideoIconDisabled.svg"
                              }
                              title={"Videograbación de consentimiento."}
                              width={24}
                              height={25}
                            />
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              minWidth: 100,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <IconToolTip
                              icon={true}
                              iconColor={
                                row?.SignatureOptions?.OTP
                                  ? "#D65E74"
                                  : "#979797"
                              }
                              src={"teenyicons:otp-outline"}
                              title={"Contraseña de un solo uso."}
                              width={19}
                            />
                            <IconToolTip
                              src={"SignAvanzada.png"}
                              title={"Firma Electrónica Avanzada."}
                              width={22}
                              height={22}
                            />
                            <IconToolTip
                              src={
                                row?.SignatureOptions?.VideoRecording
                                  ? "VideoIcon.svg"
                                  : "VideoIconDisabled.svg"
                              }
                              title={"Videograbación de consentimiento."}
                              width={24}
                              height={25}
                            />
                          </Box>
                        )}
                      </StyledTableCell1>
                    </TableRow>
                  );
                }
              )}
              {filtroPreviosAporvated?.length > 0 ? null : props.DataPrevious
                  ?.PrerequisitesApprovalUser_Email === null ? null : (
                <TableRow hover tabIndex={-1}>
                  <StyledTableCell1 component="th" scope="row" align="center">
                    {props.DataPrevious?.PrerequisitesApprovalUser_Alias}
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <IconToolTip
                      src={"Aprobador.png"}
                      title={"Usuario Aprobador."}
                      width={25}
                    />
                  </StyledTableCell1>
                  <StyledTableCell1 align="center" sx={{ padding: 0 }}>
                    {moment(
                      props.DataPrevious?.PrerequisitesLoadUser_DeathLine
                    ).format("DD-MM-YYYY")}
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <b>-</b>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <b>-</b>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <b>-</b>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <b>-</b>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <b>-</b>
                  </StyledTableCell1>
                </TableRow>
              )}
              {filtroPreviosUpload?.length > 0 ? null : props.DataPrevious
                  ?.PrerequisitesLoadUser_Email === null ? null : (
                <TableRow hover tabIndex={-1}>
                  <StyledTableCell1 component="th" scope="row" align="center">
                    {props.DataPrevious?.PrerequisitesLoadUser_Alias}
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <IconToolTip
                      src={"Cargador.png"}
                      title={"Usuario a subir documentos."}
                      width={25}
                    />
                  </StyledTableCell1>
                  <StyledTableCell1 align="center" sx={{ padding: 0 }}>
                    {moment(
                      props.DataPrevious?.PrerequisitesLoadUser_DeathLine
                    ).format("DD-MM-YYYY")}
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <b>-</b>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <b>-</b>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <b>-</b>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <b>-</b>
                  </StyledTableCell1>
                  <StyledTableCell1 align="center">
                    <b>-</b>
                  </StyledTableCell1>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
