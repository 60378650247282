import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Typography,
  styled,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import noresult from "../../assets/noresults.svg";

import { getFile } from "../../store/actions";
import BackdropComponent from "../../components/BackDrop";
import VisorPDF from "../../components/Viewer/VisorPDF";
import { FileService } from "../../services/FileService.js";
import { B64toBlob, b64toUrl } from "../../utils/Tools";
import { NoResults } from "./NoResults.jsx";

const RootDocumentContent = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "grid !important",
    gridTemplateColumns: "25% 75%",
    gridAutoRows: "100vh !important",
    overflowY: "hidden !important",
    "&::-webkit-scrollbar": {
      width: "0",
      background: "transparent",
    },
    overflowX: "hidden",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "100%",
    },
    // border: '1px solid blue'
  },
}));

const Image = styled("img")(({ theme }) => ({
  maxWidth: 200,
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    maxWidth: 130,
  },
}));

const ButtonDocLIst = styled(Button)(({ Button }) => ({
  width: "100%",
  color: " black",
  fontSize: "15px",
  fontWeight: 500,
  minHeight: "44px",
  background: "#F7F8FA",
  // border: "2px solid #848484",
  borderRadius: "5px",
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "flex-start",
  padding: "0.7em",
  marginTop: "5px",
}));

export const convertWordB64ToPdfB64 = async (b64) => {
  const wordToPdf = await convertWordToPdf(b64);
  const wordPdfB64 = `data:application/pdf;base64,${wordToPdf.Body}`;
  const wordUrl = await b64toUrl(wordPdfB64);
  return wordUrl;
};

export const convertWordToPdf = async (wordB64) => {
  const fileService = FileService.getInstance();
  const response = await fileService.convertWordToPdf(wordB64);
  return response;
};

export const FileDocumentTab = () => {
  const dispatch = useDispatch();
  const [blobUrl, setBlobUrl] = useState("");
  const [currentNameDocument, setCurrentNameDocument] = useState("");
  const [documentSelect, setDocumetSelect] = useState();

  const userData = useSelector(
    (state) => state.Authentication?.items?.UserLoginData?.Email
  );
  const rolUser = useSelector(
    (state) =>
      state.Authentication.items.UserLoginData.SecurityLoginData.Roles[0].Code
  );
  const userAdmin = useSelector(
    (state) => state.File?.item?.FileData?.CreationUser
  );
  const newFileInfo = useSelector((state) => state.NewFile.file);
  const stateFile = useSelector((state) => state.File);
  const listPrerequisitesDocumentList =
    stateFile?.item?.Prerequisites?.ApprovalPrerrequisitesStatusDocument;
  const listDigitalDocument = useSelector((state) =>
    stateFile?.item?.FileData?.Actors[0]?.DocumentalGroups?.find(
      (item) => item.Name === "Formato autogenerado"
    )?.Documents.find((item) => item.DocumentType_Id === 584)
  );
  const participantCreation =
    userData === stateFile?.item?.FileData?.CreationUser;

  useEffect(() => {
    if (newFileInfo !== undefined && newFileInfo !== null) {
      dispatch(getFile({ FileId: newFileInfo.Id }));
    }
  }, [newFileInfo]);

  useEffect(async () => {
    if (listPrerequisitesDocumentList?.length > 0) {
      setDocumetSelect(listPrerequisitesDocumentList[0].Document_Id);
      const fileService = FileService.getInstance();
      const comentData = await fileService.getDownloadDocument({
        document_Id: listPrerequisitesDocumentList[0].Document_Id,
        ConvertSfdt: false,
      });

      traerDocumentos(comentData);
      setCurrentNameDocument(listPrerequisitesDocumentList[0].DocumentName);
    } else if (listDigitalDocument?.Id !== null) {
      // console.log("Entro en la condicion de mostrar docuemnto")
      setDocumetSelect(listDigitalDocument.Id);
      const fileService = FileService.getInstance();
      const comentData = await fileService.getDownloadDocument({
        document_Id: listDigitalDocument.Id,
        ConvertSfdt: false,
      });

      traerDocumentos(comentData);
      setCurrentNameDocument(listPrerequisitesDocumentList[0]?.DocumentName);
    }
  }, [listPrerequisitesDocumentList, listDigitalDocument]);

  const traerDocumentos = (comentData) => {
    if (comentData.Body) {
      console.log("comentData.Body: ", comentData.Body);
      var extension =
        comentData.Body.FileName.split(".").length > 1
          ? comentData.Body.FileName.split(".")[1]
          : "pdf";
      console.log("comentData.Body: extension ", extension);
      if (extension === "docx") {
        const docConvertido = convertWordB64ToPdfB64(
          comentData?.Body?.B64Content
        );
        docConvertido.then((value) => {
          // dispatch(setUrlDocument64(value));
          setBlobUrl(value);
        });
      } else {
        const blob = B64toBlob(comentData?.Body?.B64Content, "application/pdf");
        setBlobUrl(URL.createObjectURL(blob));
      }
    }
  };

  let handleSelectDocument = async (event) => {
    const fileService = FileService.getInstance();
    const comentData = await fileService.getDownloadDocument({
      document_Id: event.target.id,
      ConvertSfdt: false,
    });
    traerDocumentos(comentData);
    setCurrentNameDocument(event.target.innerText);
  };

  const active = (item) => {
    return documentSelect * 1 === item;
  };

  console.log("listPrerequisitesDocumentList", participantCreation);
  if (
    listPrerequisitesDocumentList.length == 0 &&
    stateFile.item.NotificationOptions.NotifySignedDocuments == false &&
    participantCreation == false
  ) {
    return (
      <Box
        sx={{
          mr: 2,
          mt: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <Box>
            <Image alt="lupa" src={noresult} />
          </Box>
          <Box sx={{ mt: 3 }}>
            <Typography variant="titulo">
              No hay documentos que mostrar
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <Fragment>
      <BackdropComponent loading={stateFile.loading} />
      <RootDocumentContent>
        <Box sx={{ mr: 2, mt: 2 }}>
          {listPrerequisitesDocumentList?.length > 0 ? (
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Requisitos Previos
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {listPrerequisitesDocumentList?.map((document) => (
                  <ButtonDocLIst
                    sx={(theme) => ({
                      background: active(document.Document_Id)
                        ? theme.palette.primary.main10
                        : "#F7F8FA",
                      border: active(document.Document_Id)
                        ? "2px solid #C20E30"
                        : "2px solid #848484",
                    })}
                    variant="outlined"
                    id={document.Document_Id}
                    onClick={(e) => {
                      setDocumetSelect(e.target.id);
                      handleSelectDocument(e);
                    }}
                  >
                    {document.DocumentName}
                  </ButtonDocLIst>
                ))}
              </AccordionDetails>
            </Accordion>
          ) : null}
          {stateFile.item.NotificationOptions.NotifySignedDocuments == false &&
          participantCreation == false ? null : (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Documento Firmado
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ButtonDocLIst
                  sx={(theme) => ({
                    background: active(listDigitalDocument.Id)
                      ? theme.palette.primary.main10
                      : "#F7F8FA",
                    border: active(listDigitalDocument.Id)
                      ? "2px solid #C20E30"
                      : "2px solid #848484",
                  })}
                  variant="outlined"
                  id={listDigitalDocument.Id}
                  onClick={(e) => {
                    setDocumetSelect(e.target.id);
                    handleSelectDocument(e);
                  }}
                >
                  {listDigitalDocument.Name}
                </ButtonDocLIst>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
        <Box>
          {blobUrl ? (
            <VisorPDF
              downloadActive={userAdmin === userData ? true : null}
              pdfUrl={blobUrl ?? ""}
              pdfName={currentNameDocument ?? ""}
            />
          ) : (
            <Spinner />
          )}
        </Box>
      </RootDocumentContent>
    </Fragment>
  );
};

const Spinner = () => {
  return (
    <Box sx={{ display: "grid", placeContent: "center", height: 500 }}>
      <CircularProgress size={60} />
    </Box>
  );
};
