import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

export default function Spinner() {
  return (
    <Box sx={{ display: "grid", placeContent: "center", height: 500 }}>
      <CircularProgress size={60} />
    </Box>
  )
}
