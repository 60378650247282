import crypto from "crypto-browserify";
import moment from "moment";
import axios from "axios";

moment.locale('es');

export function getHash(string) {
  return crypto.createHash('sha256').update(string).digest('hex');
}

export const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const B64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  var atob = require("atob");
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const b64toUrl = async (base64Data) => {
  const r = await fetch(base64Data);
  const blob = await r.blob();
  return URL.createObjectURL(blob);
};

export const UTCtoLocal = (date, formatHour) => {
  if (date === null || date === undefined || date === '') {
    return <b>-</b>
  } else {
    if (formatHour) {
      return moment.utc(date).local().format('DD-MM-YYYY hh:mm:ss A');
    } else {
      return moment.utc(date).local().format('DD-MM-YYYY');
    }
  }
}

export const clearCacheData = () => {
  try {
    caches.keys().then((names) => {
      console.log("clearCacheData: names: ", names)
      names.forEach((name) => {
        console.log("clearCacheData: forEach delete: ", name)
        caches.delete(name);
      });
    });
    console.log('Complete Cache Cleared')
  } catch (e) {
    console.log("clearCacheData: Error: ", e)
  }
}

export function obtenerDireccionIP(callback) {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    if (xhr.status >= 200 && xhr.status < 300) {
      const respuesta = JSON.parse(xhr.responseText);
      const ip = respuesta.ip;
      callback(ip);
    } else {
      console.error('ERROR: No se pudo obtener direccion IP, API.')
      callback(null);
    }
  };
  xhr.open('GET', 'https://api.ipify.org?format=json');
  xhr.send();
}

export async function obtenerGeolocalizacionIP() {
  try {
    const response = await axios.post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
    console.log('Respuesta exitosa:', response);
    return response;
  } catch (error) {
    console.error('Error:', error);
    return error;
  }
}


export const GenerateRandom = () => {
  const randomArray = new Uint32Array(1);
  window.crypto.getRandomValues(randomArray);
  const randomValue = randomArray[0];
  return randomValue;
}