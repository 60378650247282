import { fileConstants } from "../constants";

const initialState = {
  loading: false,
  error: null,
  items: null,
};

export const Files = (state = initialState, action) => {
  switch (action.type) {
    case fileConstants.FILES_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case fileConstants.FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case fileConstants.FILES_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };

    case fileConstants.SET_FILES:
      return {
        ...state,
        items: action.payload,
      };

    default:
      return state;
  }
};

export const TextEvidence = (state = initialState, action) => {
  switch (action.type) {
    case fileConstants.TEXT_EVIDENCE_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case fileConstants.TEXT_EVIDENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case fileConstants.TEXT_EVIDENCE_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const ReorderParticipantData = (state = initialState, action) => {
  switch (action.type) {
    case fileConstants.REORDER_PARTICIPANT_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case fileConstants.REORDER_PARTICIPANT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case fileConstants.REORDER_PARTICIPANT_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const RegisterPrerequisitesData = (state = initialState, action) => {
  switch (action.type) {
    case fileConstants.REGISTER_PREREQUISITES_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case fileConstants.REGISTER_PREREQUISITES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case fileConstants.REGISTER_PREREQUISITES_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const IntegrateDocumentToSignData = (state = initialState, action) => {
  switch (action.type) {
    case fileConstants.INTEGRATE_DOCUMENT_TOSIGN_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case fileConstants.INTEGRATE_DOCUMENT_TOSIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case fileConstants.INTEGRATE_DOCUMENT_TOSIGN_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };

    case fileConstants.INTEGRATE_DOCUMENT_TOSIGN_EMPTY:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};

export const RegisterDocumentFieldsData = (state = initialState, action) => {
  switch (action.type) {
    case fileConstants.REGISTER_DOCUMENT_FIELDS_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case fileConstants.REGISTER_DOCUMENT_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case fileConstants.REGISTER_DOCUMENT_FIELDS_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
      
    case fileConstants.REGISTER_DOCUMENT_FIELDS_EMPTY:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};

export const GetFilesCustomMasiveData = (state = initialState, action) => {
  switch (action.type) {
    case fileConstants.GETFILES_CUSTOM_MASSIVE_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case fileConstants.GETFILES_CUSTOM_MASSIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case fileConstants.GETFILES_CUSTOM_MASSIVE_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
      
    case fileConstants.GETFILES_CUSTOM_MASSIVE_EMPTY:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};

export const RegisterBatchUploadSignedProcessData = (state = initialState, action) => {
  switch (action.type) {
    case fileConstants.REGISTERBATCH_UPLOADSIGNEDPROCESS_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case fileConstants.REGISTERBATCH_UPLOADSIGNEDPROCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case fileConstants.REGISTERBATCH_UPLOADSIGNEDPROCESS_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
      
    case fileConstants.REGISTERBATCH_UPLOADSIGNEDPROCESS_EMPTY:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};

export const UploadMetadataBatchFileFCData = (state = initialState, action) => {
  switch (action.type) {
    case fileConstants.UPLOADMETADATAFC_BATCHFILE_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case fileConstants.UPLOADMETADATAFC_BATCHFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case fileConstants.UPLOADMETADATAFC_BATCHFILE_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };
      
    case fileConstants.UPLOADMETADATAFC_BATCHFILE_EMPTY:
      return {
        ...state,
        loading: false,
        items: null,
        error: null,
      };
    default:
      return state;
  }
};