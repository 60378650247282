import React, { useEffect, useState } from "react";
import { List, arrayMove } from "react-movable";
import { DocumentButton } from "./DocumentButton";
import { Box } from "@mui/system";

export default function ReorderDocuments(propsComponent) {
  useEffect(() => {
    if (propsComponent.data !== null && propsComponent.data.length > 0) {
      propsComponent.setNewOrdenDocs(propsComponent.data);
    } else {
      propsComponent.setNewOrdenDocs([]);
    }
  }, [propsComponent.data]);

  return (
    <div>
      {" "}
      <List
        values={propsComponent.newOrdenDocs}
        onChange={({ oldIndex, newIndex }) => {
          propsComponent.setNewOrdenDocs(
            arrayMove(propsComponent.newOrdenDocs, oldIndex, newIndex)
          );
          propsComponent.setActiveReorder(true);
        }}
        renderList={({ children, props }) => <ul {...props}>{children}</ul>}
        renderItem={({ value, props, isDragged }) => {
          const rowcontent = (
            <DocumentButton
              key={value.DocResponseId}
              doc={value}
              listDocs={true}
              IsMassive={false}
            />
          );
          if (isDragged) {
            return (
              <Box {...props} sx={{ cursor: "grabbing" }}>
                {rowcontent}
              </Box>
            );
          }
          return (
            <Box {...props} sx={{ cursor: "grab" }}>
              {rowcontent}
            </Box>
          );
        }}
      />
    </div>
  );
}
