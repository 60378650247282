import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Grid,
  IconButton,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getFile } from "../../store/actions";
import BackdropComponent from "../../components/BackDrop";
import ParticipantSortTable from "./ParticipantSortTable";
import ParticipantSortTablePrerequites from "./ParticipantSortTablePrerequites";
import { Icon } from "@iconify/react";
import { ReactComponent as PasoUno } from "../../assets/icons/imagesFlow/paso_1.svg";
import { ReactComponent as PasoDos } from "../../assets/icons/imagesFlow/paso_2.svg";
import { ReactComponent as PasoTres } from "../../assets/icons/imagesFlow/paso_3.svg";
import { ReactComponent as PasoCuatro } from "../../assets/icons/imagesFlow/paso_4.svg";
import { ReactComponent as PasoCinco } from "../../assets/icons/imagesFlow/paso_5.svg";
import { ReactComponent as PasoSeis } from "../../assets/icons/imagesFlow/paso_6.svg";
import { styled } from "@mui/system";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  iconFlecha: {
    fontSize: 28,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  buttonCircle: {
    width: 120,
    height: 120,
    [theme.breakpoints.down("sm")]: {
      width: 55,
      height: 55,
    },
  },
}));

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
}));

export const FlowExpedient = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stateFile = useSelector((state) => state.File);
  const stateAuth = useSelector((state) => state.Authentication);
  const newFileInfo = useSelector((state) => state.NewFile.file);
  const [stateForTable, setStateForTable] = useState("");

  const [step1, setStep1] = useState("");
  const [step2, setStep2] = useState("");
  const [step3, setStep3] = useState("");
  const [step4, setStep4] = useState("");
  const [step5, setStep5] = useState("");
  // captura de datos
  const [step6, setStep6] = useState("");

  const listParticipants = useSelector(
    (state) => state.File?.item?.Participants
  );

  const checkParticipants = listParticipants?.filter(
    (item) => item.Reviewer === true || item?.Check === true
  );

  const statePrevious = useSelector(
    (state) => state.File.item?.Prerequisites?.ActivePrerequisites
  );

  useEffect(() => {
    if (newFileInfo !== undefined && newFileInfo !== null) {
      dispatch(getFile({ FileId: newFileInfo.Id }));
    }
  }, [newFileInfo]);

  const renderSinglist = listParticipants?.filter((user) => user.Sign === true);
  const renderCaptureList = listParticipants?.filter(
    (user) => user.Capture === true
  );
  const renderChecklist = listParticipants?.filter(
    (user) => user.Check === true || user.Reviewer === true
  );

  let adminArray = [
    {
      FullName: stateFile?.item?.FileData?.CreationUser,
      Funtion: "Administrador",
      SignDeadlineFormat: stateFile?.item?.ValidityFormat,
      SignStatus: "En resguardo",
      UserId:
        stateAuth?.items?.UserLoginData?.Email ===
        stateFile?.item?.FileData?.CreationUser
          ? stateAuth?.items?.UserLoginData?.Id
          : null,
    },
  ];

  const stagesFileImage = () => {
    switch (stateFile?.item?.FileData?.Status) {
      case "Carga de prerrequisitos":
        return (
          setStep1("#5CCD75"),
          setStep2("#FFE712"),
          setStep3("#D9D9D9"),
          setStep4("#D9D9D9"),
          setStep5("#D9D9D9"),
          setStep6("#D9D9D9")
        );
      case "Validación de prerrequisitos":
        return (
          setStep1("#5CCD75"),
          setStep2("#FFE712"),
          setStep3("#D9D9D9"),
          setStep4("#D9D9D9"),
          setStep5("#D9D9D9"),
          setStep6("#D9D9D9")
        );

      case "Nuevo expediente":
        return (
          setStep1("#5CCD75"),
          setStep2("#D9D9D9"),
          setStep3("#D9D9D9"),
          setStep4("#D9D9D9"),
          setStep5("#D9D9D9"),
          setStep6("#D9D9D9")
        );
      case "NotificacionAsignacion":
        return (
          setStep1("#5CCD75"),
          setStep2("#D9D9D9"),
          setStep3("#D9D9D9"),
          setStep4("#D9D9D9"),
          setStep5("#D9D9D9"),
          setStep6("#D9D9D9")
        );
      case "Revisión de documentos":
        return (
          setStep1("#5CCD75"),
          setStep2("#5CCD75"),
          setStep3("#FFE712"),
          setStep4("#D9D9D9"),
          setStep5("#D9D9D9"),
          setStep6("#D9D9D9")
        );
      case "Notificacion De Asignacion de Revisión":
        return (
          setStep1("#5CCD75"),
          setStep2("#5CCD75"),
          setStep3("#FFE712"),
          setStep4("#D9D9D9"),
          setStep5("#D9D9D9"),
          setStep6("#D9D9D9")
        );
      case "Revisión de Administrador":
        return (
          setStep1("#5CCD75"),
          setStep2("#5CCD75"),
          setStep3("#FFE712"),
          setStep4("#D9D9D9"),
          setStep5("#D9D9D9"),
          setStep6("#D9D9D9")
        );
      case "Asignando solicitud de revisión":
        return (
          setStep1("#5CCD75"),
          setStep2("#5CCD75"),
          setStep3("#FFE712"),
          setStep4("#D9D9D9"),
          setStep5("#D9D9D9"),
          setStep6("#D9D9D9")
        );
      case "Firma de documentos":
        return (
          setStep1("#5CCD75"),
          setStep2("#5CCD75"),
          setStep3("#5CCD75"),
          setStep4("#FFE712"),
          setStep5("#D9D9D9"),
          setStep6("#5CCD75")
        );

      case "Notificacion De Asignacion de Firma":
        return (
          setStep1("#5CCD75"),
          setStep2("#5CCD75"),
          setStep3("#5CCD75"),
          setStep4("#FFE712"),
          setStep5("#D9D9D9"),
          setStep6("#5CCD75")
        );
      case "Asignando solicitud de firma":
        return (
          setStep1("#5CCD75"),
          setStep2("#5CCD75"),
          setStep3("#5CCD75"),
          setStep4("#FFE712"),
          setStep5("#D9D9D9"),
          setStep6("#5CCD75")
        );
      case "Finalización de xml":
        return (
          setStep1("#5CCD75"),
          setStep2("#5CCD75"),
          setStep3("#5CCD75"),
          setStep4("#FFE712"),
          setStep5("#D9D9D9"),
          setStep6("#5CCD75")
        );
      case "Integración de documentos":
        return (
          setStep1("#5CCD75"),
          setStep2("#5CCD75"),
          setStep3("#5CCD75"),
          setStep4("#FFE712"),
          setStep5("#D9D9D9"),
          setStep6("#5CCD75")
        );
      case "OTP Evidencias firma de documentos":
        return (
          setStep1("#5CCD75"),
          setStep2("#5CCD75"),
          setStep3("#5CCD75"),
          setStep4("#FFE712"),
          setStep5("#D9D9D9"),
          setStep6("#5CCD75")
        );
      case "Evidencias firma de documentos":
        return (
          setStep1("#5CCD75"),
          setStep2("#5CCD75"),
          setStep3("#5CCD75"),
          setStep4("#FFE712"),
          setStep5("#D9D9D9"),
          setStep6("#5CCD75")
        );
      case "Validación de evidencias":
        return (
          setStep1("#5CCD75"),
          setStep2("#5CCD75"),
          setStep3("#5CCD75"),
          setStep4("#FFE712"),
          setStep5("#D9D9D9"),
          setStep6("#5CCD75")
        );
      case "Finalizado":
        return (
          setStep1("#5CCD75"),
          setStep2("#5CCD75"),
          setStep3("#5CCD75"),
          setStep4("#5CCD75"),
          setStep5("#5CCD75"),
          setStep6("#5CCD75")
        );
      case "Carga Docflow":
        return (
          setStep1("#5CCD75"),
          setStep2("#5CCD75"),
          setStep3("#5CCD75"),
          setStep4("#5CCD75"),
          setStep5("#5CCD75"),
          setStep6("#5CCD75")
        );
      case "Finalizado cancelado":
        return (
          setStep1("#D9D9D9"),
          setStep2("#D9D9D9"),
          setStep3("#D9D9D9"),
          setStep4("#D9D9D9"),
          setStep5("#D9D9D9"),
          setStep6("#D9D9D9")
        );
      case "Finalizado rechazado":
        return (
          setStep1("#D9D9D9"),
          setStep2("#D9D9D9"),
          setStep3("#D9D9D9"),
          setStep4("#D9D9D9"),
          setStep5("#D9D9D9"),
          setStep6("#D9D9D9")
        );
      case "Captura de Información":
        return (
          setStep1("#5CCD75"),
          setStep2("#5CCD75"),
          setStep3("#5CCD75"),
          setStep4("#D9D9D9"),
          setStep5("#D9D9D9"),
          setStep6("#FFE712")
        );
      default:
        break;
    }
  };

  const stageFile = () => {
    switch (stateFile?.item?.FileData.StageName) {
      case "CargaPrerrequisitos":
        return "Previos";
      case "ValidacionPrerrequisitos":
        return "Previos";

      case "Nuevo expediente":
        return "Revision";
      case "Revisión de documentos":
        return "Revision";
      case "NotificacionAsignacion":
        return "Notificacion de Asignacion";
      case "Revisión de Administrador":
        return "Revision";
      case "Asignando solicitud de revisión":
        return "Revision";
      case "SeleccionParticipantes":
        return "Revision";

      case "Firma de documentos":
        return "Firma";
      case "NotificacionFirma":
        return "Firma";
      case "Asignando solicitud de firma":
        return "Firma";
      case "Notificacion De Finalización de Firma":
        return "Firma";
      case "Integración de documentos":
        return "Firma";
      case "OTP Evidencias firma de documentos":
        return "Firma";
      case "Evidencias":
        return "Firma";
      case "EvidenciasValidacion":
        return "Firma";

      case "CapturaInformacion":
        return "Captura de información";

      case "FinalizacionXml":
        return "Final";
      case "Finalizado":
        return "Final";
      case "Carga Docflow":
        return "Final";
      case "Resumen":
        return "Final";
      case "Finalizado rechazado":
        return "Final";
      default:
        break;
    }
  };

  useEffect(() => {
    stagesFileImage();
    setStateForTable(stageFile());
  }, []);

  return (
    <Fragment>
      <BackdropComponent loading={stateFile.loading} />
      <Grid item lg={12}>
        <Box
          sx={{
            mb: 3,
            mt: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon
            icon="material-symbols:line-start-circle"
            className={classes.iconFlecha}
            color={step1}
          />
          <Tip
            title={"Creación de la solicitud."}
            placement="top"
            enterTouchDelay={0}
          >
            <spam>
              <IconButton
                className={classes.buttonCircle}
                size={"small"}
                onClick={() => setStateForTable("Final")}
              >
                <PasoUno
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: step1,
                    borderRadius: "50%",
                  }}
                />
              </IconButton>
            </spam>
          </Tip>
          <Icon
            icon="material-symbols:line-end-arrow-notch"
            className={classes.iconFlecha}
            color={step1}
          />

          {statePrevious ? (
            <>
              <Tip
                title={"Carga de requisitos previos."}
                placement="top"
                enterTouchDelay={0}
              >
                <spam>
                  <IconButton
                    className={classes.buttonCircle}
                    size={"small"}
                    onClick={() => setStateForTable("Previos")}
                  >
                    <PasoDos
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: step2,
                        borderRadius: "50%",
                      }}
                    />
                  </IconButton>
                </spam>
              </Tip>
              <Icon
                icon="material-symbols:line-end-arrow-notch"
                className={classes.iconFlecha}
                color={step2}
              />
            </>
          ) : null}
          {checkParticipants !== undefined && checkParticipants !== null ? (
            checkParticipants.length > 0 ? (
              <>
                <Tip
                  title={"Revisión de documentos."}
                  placement="top"
                  enterTouchDelay={0}
                >
                  <spam>
                    <IconButton
                      className={classes.buttonCircle}
                      size={"small"}
                      onClick={() => setStateForTable("Revision")}
                    >
                      <PasoTres
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: step3,
                          borderRadius: "50%",
                        }}
                      />
                    </IconButton>
                  </spam>
                </Tip>
                <Icon
                  icon="material-symbols:line-end-arrow-notch"
                  className={classes.iconFlecha}
                  color={step3}
                />
              </>
            ) : null
          ) : null}
          {renderCaptureList !== undefined && renderCaptureList !== null ? (
            renderCaptureList?.length > 0 ? (
              <>
                <Tip
                  title={"Captura de Información."}
                  placement="top"
                  enterTouchDelay={0}
                >
                  <spam>
                    <IconButton
                      className={classes.buttonCircle}
                      size={"small"}
                      onClick={() => setStateForTable("Captura de información")}
                    >
                      <PasoSeis
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: step6,
                          borderRadius: "50%",
                        }}
                      />
                    </IconButton>
                  </spam>
                </Tip>
                <Icon
                  icon="material-symbols:line-end-arrow-notch"
                  className={classes.iconFlecha}
                  color={step3}
                />
              </>
            ) : null
          ) : null}

          <Tip
            title={"Firma de documentos."}
            placement="top"
            enterTouchDelay={0}
          >
            <spam>
              <IconButton
                className={classes.buttonCircle}
                size={"small"}
                onClick={() => setStateForTable("Firma")}
              >
                <PasoCuatro
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: step4,
                    borderRadius: "50%",
                  }}
                />
              </IconButton>
            </spam>
          </Tip>
          <Icon
            icon="material-symbols:line-end-arrow-notch"
            className={classes.iconFlecha}
            color={step4}
          />
          <Tip
            title={"Finalización de la solicitud."}
            placement="top"
            enterTouchDelay={0}
          >
            <spam>
              <IconButton
                className={classes.buttonCircle}
                size={"small"}
                onClick={() => setStateForTable("Final")}
              >
                <PasoCinco
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: step5,
                    borderRadius: "50%",
                  }}
                />
              </IconButton>
            </spam>
          </Tip>
          <Icon
            icon="material-symbols:line-end-circle"
            className={classes.iconFlecha}
            color={step5}
          />
        </Box>
      </Grid>
      <Grid container>
        <Box overflow={"auto"} sx={{ mt: 3, maxHeight: "60vh", width: "100%" }}>
          <Paper elevation={1} sx={{ width: "100%" }}>
            {stateForTable === "Previos" ? (
              <ParticipantSortTablePrerequites
                docsStackPrerre={stateFile?.item?.Prerequisites}
              />
            ) : stateForTable === "Revision" ? (
              <ParticipantSortTable listRows={renderChecklist} type={"check"} />
            ) : stateForTable === "Firma" ? (
              <ParticipantSortTable listRows={renderSinglist} type={"sing"} />
            ) : stateForTable === "Captura de información" ? (
              <ParticipantSortTable
                listRows={renderCaptureList}
                type={"Captura"}
              />
            ) : stateForTable === "Final" ? (
              <ParticipantSortTable
                listRows={adminArray}
                type={"admin"}
                finally={stageFile() === "Final" ? true : false}
              />
            ) : null}
          </Paper>
        </Box>
      </Grid>
    </Fragment>
  );
};
