import { genericConstants } from "../constants";

export const GenericData = (state = {}, action) => {
  switch (action.type) {
    case genericConstants.SET_TITLE_GRAL_SUCCESS:
      return {
        loading: false,
        title: action.payload, //Obtener respuesta correcta del API
        /* usuario: action.authenticationData.Body.UserLoginData.Username, */
        error: undefined,
      };
    default:
      return state; //Retornar state vacío o actual
  }
};
