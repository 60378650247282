import styled from "@emotion/styled";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import React from "react";
import { UseImage } from "./UseImage";
import { Icon } from "@iconify/react";

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    // fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
}));



export default function IconToolTip(props) {
  return (
    <Tip title={props.title} placement="top" enterTouchDelay={0}>
      <Box
        sx={{
          m: .8,
          pointerEvents: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {props.icon ? (
          <Icon icon={props.src} fontSize={props.width} color={props.iconColor ?? '#D65E74'} />
        ) : (
          <UseImage
            type="Image"
            width={props.width}
            height={props.height}
            src={props.src}
          />
        )}
      </Box>
    </Tip>
  );
}
