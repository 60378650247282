export const fileConstants = {
  FILES_REQUEST: "FILES_REQUEST",
  FILES_SUCCESS: "FILES_SUCCESS",
  FILES_FAILURE: "FILES_FAILURE",
  SET_FILES: "SET_FILES",
  TEXT_EVIDENCE_REQUEST: "TEXT_EVIDENCE_REQUEST",
  TEXT_EVIDENCE_SUCCESS: "TEXT_EVIDENCE_SUCCESS",
  TEXT_EVIDENCE_FAILURE: "TEXT_EVIDENCE_FAILURE",
  REORDER_PARTICIPANT_REQUEST: "REORDER_PARTICIPANT_REQUEST",
  REORDER_PARTICIPANT_SUCCESS: "REORDER_PARTICIPANT_SUCCESS",
  REORDER_PARTICIPANT_FAILURE: "REORDER_PARTICIPANT_FAILURE",
  REGISTER_PREREQUISITES_REQUEST: "REGISTER_PREREQUISITES_REQUEST",
  REGISTER_PREREQUISITES_SUCCESS: "REGISTER_PREREQUISITES_SUCCESS",
  REGISTER_PREREQUISITES_FAILURE: "REGISTER_PREREQUISITES_FAILURE",
  INTEGRATE_DOCUMENT_TOSIGN_REQUEST: "INTEGRATE_DOCUMENT_TOSIGN_REQUEST",
  INTEGRATE_DOCUMENT_TOSIGN_SUCCESS: "INTEGRATE_DOCUMENT_TOSIGN_SUCCESS",
  INTEGRATE_DOCUMENT_TOSIGN_FAILURE: "INTEGRATE_DOCUMENT_TOSIGN_FAILURE",
  INTEGRATE_DOCUMENT_TOSIGN_EMPTY: "INTEGRATE_DOCUMENT_TOSIGN_EMPTY",  
  REGISTER_DOCUMENT_FIELDS_REQUEST: "REGISTER_DOCUMENT_FIELDS_REQUEST",
  REGISTER_DOCUMENT_FIELDS_SUCCESS: "REGISTER_DOCUMENT_FIELDS_SUCCESS",
  REGISTER_DOCUMENT_FIELDS_FAILURE: "REGISTER_DOCUMENT_FIELDS_FAILURE",
  REGISTER_DOCUMENT_FIELDS_EMPTY: "REGISTER_DOCUMENT_FIELDS_EMPTY",
  GETFILES_CUSTOM_MASSIVE_REQUEST: "GETFILES_CUSTOM_MASSIVE_REQUEST",
  GETFILES_CUSTOM_MASSIVE_SUCCESS: "GETFILES_CUSTOM_MASSIVE_SUCCESS",
  GETFILES_CUSTOM_MASSIVE_FAILURE: "GETFILES_CUSTOM_MASSIVE_FAILURE",
  GETFILES_CUSTOM_MASSIVE_EMPTY: "GETFILES_CUSTOM_MASSIVE_EMPTY",
  REGISTERBATCH_UPLOADSIGNEDPROCESS_REQUEST: "REGISTERBATCH_UPLOADSIGNEDPROCESS_REQUEST",
  REGISTERBATCH_UPLOADSIGNEDPROCESS_SUCCESS: "REGISTERBATCH_UPLOADSIGNEDPROCESS_SUCCESS",
  REGISTERBATCH_UPLOADSIGNEDPROCESS_FAILURE: "REGISTERBATCH_UPLOADSIGNEDPROCESS_FAILURE",
  REGISTERBATCH_UPLOADSIGNEDPROCESS_EMPTY: "REGISTERBATCH_UPLOADSIGNEDPROCESS_EMPTY",
  UPLOADMETADATAFC_BATCHFILE_REQUEST: "UPLOADMETADATAFC_BATCHFILE_REQUEST",
  UPLOADMETADATAFC_BATCHFILE_SUCCESS: "UPLOADMETADATAFC_BATCHFILE_SUCCESS",
  UPLOADMETADATAFC_BATCHFILE_FAILURE: "UPLOADMETADATAFC_BATCHFILE_FAILURE",
  UPLOADMETADATAFC_BATCHFILE_EMPTY: "UPLOADMETADATAFC_BATCHFILE_EMPTY",
};