import React, { Fragment, useState } from "react";
import {
  AccordionDetails as AccDet,
  Accordion as Acc,
  AccordionSummary as AccSum,
  Box,
  Typography,
  styled,
  Button,
} from "@mui/material";
import iconoMenu from "../../assets/icons/Dragable.svg";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { InputField } from "../../components/InputField";
import { useForm } from "react-hook-form";
import { FileService } from "../../services/FileService";
import { setlistCheckers, setlistSigners } from "../../store/actions";
import { UTCtoLocal } from "../../utils";
import { Icon } from "@iconify/react";
import IconToolTip from "../../components/IconToolTip";

const Accordion = styled(Acc)(({ theme }) => ({
  "&.MuiAccordion-root": {
    boxShadow: "none !important",
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled(AccSum)(({ theme }) => ({
  padding: 0,
  minHeight: 37,
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
}));

const AccordionDetails = styled(AccDet)(({ theme }) => ({
  padding: 0,
}));

export default function ParticipantListResponsive(props) {
  const { control, setValue, watch } = useForm();
  const dispatch = useDispatch();
  const watchDateEdit = watch("dateEdit");
  const [editing, setediting] = useState(null);
  const newFileState = useSelector((state) => state.NewFile);
  const fileState = useSelector((state) => state.File);

  const typoSing = useSelector(
    (state) =>
      state.NewFile?.file?.fileForm?.FileConfiguration?.SignatureOptions
  );
  const typoSingEdit = useSelector(
    (state) => state.File?.item?.SignatureOptions
  );

  const renderDate = (value) => {
    if (value.Check && value.CheckDeadline) {
      return UTCtoLocal(value.CheckDeadline, false);
    }
    if (value.Sign && value.SignDeadline) {
      return UTCtoLocal(value.SignDeadline, false);
    }
    return "-";
  };

  const handleEdit = (value) => {
    if (value?.Check === true && value?.CheckDeadline !== null) {
      const limDate = new Date(value?.CheckDeadline);
      var lmd2 = limDate.setDate(limDate.getDate());
      setValue("dateEdit", lmd2);
    } else if (value?.Sign === true && value?.SignDeadline !== null) {
      const limDate = new Date(value?.SignDeadline);
      var lmd2 = limDate.setDate(limDate.getDate());
      setValue("dateEdit", lmd2);
    }
    setediting(value);
  };

  const updatedate = async (newdate) => {
    if (newdate === undefined || newdate === null || newdate === "") {
      return;
    }
    try {
      const fileService = FileService.getInstance();
      const participant = {
        ...editing,
        ...(editing && {
          SignDeadline: newdate.toISOString(),
        }),
      };

      await fileService.registerParticipantInFile({
        FileId: newFileState.editStack
          ? fileState.item?.FileData?.Id
          : newFileState.file.Id,
        Participant: participant,
      });
      if (editing) {
        if (editing.Check) {
          const newCheckList = newFileState.participants.check.map((p) => {
            if (p.UserId === participant.UserId) {
              return participant;
            }
            return p;
          });
          dispatch(setlistCheckers(newCheckList));
        }
        if (editing.Sign) {
          const newSignList = newFileState.participants.sign.map((p) => {
            if (p.UserId === participant.UserId) {
              return participant;
            }
            return p;
          });
          dispatch(setlistSigners(newSignList));
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setediting(null);
    }
  };

  const cancelEdit = () => {
    setediting(null);
  };

  const handleValidateDate = (typeDate, key) => {
    try {
      const listchecks =
        props.type === "checkers"
          ? props.data?.filter((l) => l.CheckDeadline)
          : props?.dataValidate?.filter((l) => l.CheckDeadline);
      const listsigns =
        props.type === "signers"
          ? props.data?.filter((l) => l.SignDeadline)
          : props?.dataValidate?.filter((l) => l.SignDeadline);

      const lclength = listchecks?.length;
      const lslength = listsigns?.length;
      if (typeDate === "checkers") {
        if (lclength === 0) {
          return new Date();
        }

        var lastdatecheck =
          key <= 0
            ? new Date()
            : lclength > 0
            ? listchecks[key - 1]?.CheckDeadline
            : new Date();

        const limDate = new Date(lastdatecheck);
        var dateCalcule = key <= 0 ? limDate.getDate() : limDate.getDate() + 1;
        return limDate.setDate(dateCalcule);
      }
      if (typeDate === "signers") {
        if (lclength === 0) {
          if (lslength === 0) {
            const limDate = new Date(watchDateEdit);
            return limDate.setDate(limDate.getDate() + 1);
          }
          return new Date();
        }
        if (lslength === 0) {
          const lastdatecheck = listchecks[lclength - 1].CheckDeadline;
          const limDate = new Date(lastdatecheck);
          return limDate.setDate(limDate.getDate());
        }

        var lastdatesign =
          key <= 0
            ? lclength > 0
              ? listchecks[lclength - 1].CheckDeadline
              : new Date()
            : lslength > 0
            ? listsigns[key - 1].SignDeadline
            : new Date();
        const limDate = new Date(lastdatesign);
        var dateCalcule = limDate.getDate();
        return limDate.setDate(dateCalcule);
      }
    } catch (ex) {
      console.log(".:handleValidateDate:. Error: ", ex);
    }
  };

  const handleValidateDateMax = (typeDate, key) => {
    try {
      const ValidityDate =
        newFileState.file?.fileForm?.FileConfiguration?.Validity;
      const listchecks =
        props.type === "checkers"
          ? props.data?.filter((l) => l.CheckDeadline)
          : props?.dataValidate?.filter((l) => l.CheckDeadline);
      const listsigns =
        props.type === "signers"
          ? props.data?.filter((l) => l.SignDeadline)
          : props?.dataValidate?.filter((l) => l.SignDeadline);

      if (typeDate === "checkers" && listsigns?.length > 0) {
        var lastdatesign =
          key >= 0 && listchecks?.length - 1 > key
            ? listchecks[key + 1].CheckDeadline
            : listsigns[0].SignDeadline;
        console.log("handleValidateDateMax checkers entro", lastdatesign);
        const limDate = new Date(lastdatesign);
        return limDate.setDate(limDate.getDate() - 1);
      }

      if (typeDate === "signers" && listsigns?.length > 0) {
        var lastdatesign =
          key >= 0 && listsigns?.length - 1 > key
            ? listsigns[key + 1].SignDeadline
            : new Date(ValidityDate);

        console.log("handleValidateDateMax signers entro", lastdatesign);
        const limDate = new Date(lastdatesign);
        var dateCalculate =
          listsigns?.length - 1 === key ? limDate.getDate() : null;

        return limDate.setDate(dateCalculate);
      }
      const limDate = new Date(ValidityDate);
      return limDate.setDate(limDate.getDate());
    } catch (ex) {
      console.log(".::. Error: ", ex);
    }
  };

  return (
    <Fragment>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {props?.handleDelete && props?.type === "signers" ? (
            <>
              <img
                src={iconoMenu}
                style={{ width: 17, marginRight: 3 }}
                alt="imagen"
              />
              <Typography
                variant="subtitle"
                sx={{ mr: 1, ml: 1, fontWeight: 450 }}
              >
                {props.userComplete.SignOrder}
              </Typography>
            </>
          ) : null}
          <Typography variant="subtitle" sx={{ fontWeight: 450 }}>
            {props.user}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              p: 1,
              bgcolor: "background.paper",
              borderRadius: 1,
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Box>
                <Typography variant="subtitle" sx={{ fontWeight: 450 }}>
                  Fecha limite
                </Typography>
              </Box>
              <Box sx={{ mt: 1.3, mb: 0.5 }}>
                <Typography variant="subtitle" sx={{ fontWeight: 450 }}>
                  Función
                </Typography>
              </Box>
              {props.action ? (
                <Box sx={{ mt: 1.3 }}>
                  <Typography variant="subtitle" sx={{ fontWeight: 450 }}>
                    Acciones
                  </Typography>
                </Box>
              ) : null}
            </Box>
            <Box>
              <Box
                sx={{
                  textAlign: "right",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle">
                  {props?.view === "filePreview" ? (
                    UTCtoLocal(props.dateDeadline, false) ?? <b>-</b>
                  ) : props?.userComplete?.UserId === editing?.UserId ? (
                    <InputField
                      inputType="date"
                      control={control}
                      name="dateEdit"
                      sxInput={{ maxWidth: 200 }}
                      onSelect={updatedate}
                      defaultValue={""}
                      minDate={handleValidateDate(props?.type, props?.key)}
                      maxDate={handleValidateDateMax(props?.type, props?.key)}
                      /*  minDate={new Date()} */
                    />
                  ) : (
                    <span>{renderDate(props?.userComplete)}</span>
                  )}
                </Typography>
              </Box>
              <Box sx={{ mt: 1.3, mb: 0.5 }}>
                {console.log("props?.functionUser", props?.functionUser)}
                {props?.functionUser === "aprobador" ? (
                  <IconToolTip
                    src={"Aprobador.png"}
                    title={"Usuario Aprobador."}
                    width={15}
                  />
                ) : props?.functionUser === "uploadUser" ? (
                  <IconToolTip
                    src={"Cargador.png"}
                    title={"Usuario a subir documentos."}
                    width={13}
                  />
                ) : // AQUI EVALUAMOS A LOS REVISORES AHORA
                props?.functionUser === "capture" ? (
                  <IconToolTip
                    src={"CaptureIcon.png"}
                    title={"Usuario Capturador."}
                    width={15}
                  />
                ) : (props?.functionUser === null ||
                    props?.functionUser === false) &&
                  props?.type === "checkers" ? (
                  <IconToolTip
                    src={"AprobadorRevision.png"}
                    title={"Usuario Aprobador."}
                    width={15}
                  />
                ) : props?.functionUser === true ? (
                  <IconToolTip
                    src={"Revisor.png"}
                    title={"Usuario Editor."}
                    width={15}
                  />
                ) : props?.userComplete?.SignatureOptions ? (
                  props?.userComplete?.SignatureOptions?.SignatureType ===
                  "Simple" ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IconToolTip
                        icon={true}
                        src={"teenyicons:otp-outline"}
                        title={"Contraseña de un solo uso."}
                        width={19}
                      />
                      <IconToolTip
                        src={"SignSimple.png"}
                        title={"Firma Autógrafa en Mensaje de Datos."}
                        width={24}
                      />
                      <IconToolTip
                        src={
                          props?.userComplete?.SignatureOptions?.VideoRecording
                            ? "VideoIcon.svg"
                            : "VideoIconDisabled.svg"
                        }
                        title={"Videograbación de consentimiento."}
                        width={24}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IconToolTip
                        icon={true}
                        iconColor={
                          props?.userComplete?.SignatureOptions?.OTP
                            ? "#D65E74"
                            : "#979797"
                        }
                        src={"teenyicons:otp-outline"}
                        title={"Contraseña de un solo uso."}
                        width={19}
                      />
                      <IconToolTip
                        src={"SignAvanzada.png"}
                        title={"Firma Electrónica Avanzada."}
                        width={22}
                      />
                      <IconToolTip
                        src={
                          props?.userComplete?.SignatureOptions?.VideoRecording
                            ? "VideoIcon.svg"
                            : "VideoIconDisabled.svg"
                        }
                        title={"Videograbación de consentimiento."}
                        width={24}
                      />
                    </Box>
                  )
                ) : props?.userComplete?.Sign ? (
                  typoSing?.SignatureType === "Simple" ||
                  typoSingEdit?.SignatureType === "Simple" ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IconToolTip
                        icon={true}
                        src={"teenyicons:otp-outline"}
                        title={"Contraseña de un solo uso."}
                        width={19}
                      />
                      <IconToolTip
                        src={"SignSimple.png"}
                        title={"Firma Autógrafa en Mensaje de Datos."}
                        width={24}
                      />
                      <IconToolTip
                        src={
                          typoSingEdit?.VideoRecording ||
                          typoSing?.VideoRecording
                            ? "VideoIcon.svg"
                            : "VideoIconDisabled.svg"
                        }
                        title={"Videograbación de consentimiento."}
                        width={24}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IconToolTip
                        icon={true}
                        src={"teenyicons:otp-outline"}
                        title={"Contraseña de un solo uso."}
                        width={19}
                        iconColor={
                          typoSingEdit?.OTP || typoSing?.OTP
                            ? "#D65E74"
                            : "#979797"
                        }
                      />
                      <IconToolTip
                        src={"SignAvanzada.png"}
                        title={"Firma Electrónica Avanzada."}
                        width={24}
                      />
                      <IconToolTip
                        src={
                          typoSingEdit?.VideoRecording ||
                          typoSing?.VideoRecording
                            ? "VideoIcon.svg"
                            : "VideoIconDisabled.svg"
                        }
                        title={"Videograbación de consentimiento."}
                        width={24}
                      />
                    </Box>
                  )
                ) : (
                  <b>-</b>
                )}
              </Box>
              {props?.action ? (
                <Box sx={{ display: "flex", mt: 1.3, p: 0 }}>
                  <Button
                    sx={{ pointerEvents: "auto", m: 0, p: 0 }}
                    size="small"
                    color="secondary"
                    onClick={(e) =>
                      props?.userComplete?.UserId === editing?.UserId
                        ? cancelEdit()
                        : handleEdit(props?.userComplete)
                    }
                  >
                    {props?.userComplete?.UserId === editing?.UserId ? (
                      <ClearIcon sx={{fontSize: 17}} />
                    ) : (
                      <ModeEditOutlinedIcon sx={{fontSize: 17}} />
                    )}
                  </Button>
                  <Button
                    sx={{ pointerEvents: "auto", p: 0, m: 0 }}
                    size="small"
                    color="secondary"
                    onClick={(e) =>
                      props.handleDelete(props.userComplete, props.type)
                    }
                  >
                    <Icon icon="ph:trash-light" fontSize={17} />
                  </Button>
                </Box>
              ) : null}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
}
