import { LoadingButton } from '@mui/lab';
import { Button, Grid, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert } from '../../../components/Alert';
import BackdropComponent from '../../../components/BackDrop';
import { InputField } from '../../../components/InputField'
import { AuthService } from '../../../services/AuthService';
import imageFondo from '../../../assets/imageFondoPass.svg'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    contenedorImage: {
        height: '100vh',
        backgroundImage: `url(${imageFondo})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    containerData: {
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        boxShadow: 'inset 18.0333px -18.0333px 18.0333px rgba(194, 194, 194, 0.1), inset -18.0333px 18.0333px 18.0333px rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(18.0333px)',
        borderRadius: "3%",
    }
}))

export default function RestartPassword() {
    const classes = useStyles()
    const { control, handleSubmit } = useForm();
    const location = useLocation();
    const navigate = useNavigate();
    const [reloadSing, setReloadSing] = useState(false);

    console.log(location.pathname);

    const handleService = async (data) => {
        setReloadSing(true);
        try {
            const authService = AuthService.getInstance();
            const resetPass = await authService.recoverPassword({ Body: data.email });
            console.log('resetPass', resetPass.data)
            if (resetPass.data.data.IsOK === true) {
                setReloadSing(false);
                Alert({
                    icon: "success",
                    text: "Se ha enviado un correo electrónico, verifica tu cuenta registrada.",
                });
                navigate('/auth/login')
            } else if (resetPass.data.data.IsOK === false) {
                setReloadSing(false);
                Alert({
                    icon: "error",
                    text: resetPass.data.data.Messages,
                });
            }
        } catch (error) {
            setReloadSing(false);
            Alert({
                icon: "error",
                title: "Error",
                text: "Hubo un error, por favor intentalo de nuevo. ",
            });
            navigate('/auth/login')
        }
    };

    return (
        <Fragment>
            <BackdropComponent loading={reloadSing} />
            <Grid
                className={classes.contenedorImage}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Paper
                    elevation={3}
                    className={classes.containerData}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <form onSubmit={handleSubmit(handleService)}>
                        <Box sx={{ p: 3, mt: 1 }}>
                            <Box textAlign={"center"}>
                                <Typography variant="titulo" sx={{ fontWeight: 700, letterSpacing: 1.6 }}>
                                    Recuperar contraseña
                                </Typography>
                            </Box>
                            <Box textAlign={"justify"}>
                                <Typography variant="subtitle" sx={{ mt: .5, mb: 1, fontWeight: 480 }}>
                                    Ingresa el correo asociado a tu cuenta y verifícalo
                                </Typography>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <InputField
                                    name="email"
                                    id="email"
                                    label="Correo electrónico"
                                    iconSpam={true}
                                    control={control}
                                    fullWidth
                                    sx={{ borderRadius: "12px" }}
                                    validations={{ required: true }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", p: 1 }}>
                            <Box width={"90%"} sx={{ p: 1 }}>
                                <Button
                                    fullWidth
                                    sx={{ height: 45 }}
                                    variant="outlined"
                                    size="medium"
                                    onClick={() => navigate("/inbox/files")}
                                >
                                    <Typography sx={{ letterSpacing: 1.2 }}>Cancelar</Typography>
                                </Button>
                            </Box>
                            <Box width={"90%"} sx={{ p: 1 }}>
                                <LoadingButton
                                    fullWidth
                                    sx={{ height: 45 }}
                                    variant="contained"
                                    size="medium"
                                    onClick={handleSubmit(handleService)}
                                    loading={reloadSing}
                                >
                                    <Typography sx={{ letterSpacing: 1.2 }}>Aceptar</Typography>
                                </LoadingButton>
                            </Box>
                        </Box>
                    </form>
                </Paper>
            </Grid>
        </Fragment>
    )
}
