import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box, Typography, Grid } from "@mui/material";

import { useForm, FormProvider } from "react-hook-form";
import { Divider } from "../../components/Divider";
import { useDispatch, useSelector } from "react-redux";
import {
  uplodadNewDocumentVersion,
  ContinueWorkFlow,
  UpdateSection,
  getFile,
} from "../../store/actions";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { DocumentListTable } from "./DocumentListTable";
import { UploadDcuments } from "./UploadDcuments";
import { CustomModalDialog } from "../../components/CustomModalDialog";
import { Camera } from "../../components/Camera/Camera";
import { blobToBase64 } from "../../utils/Tools";
import { FileFlowService } from "../../services/FileFlowService";
import { Alert, AlertConfirm } from "../../components/Alert";
import { DocumentListTablePreview } from "./DocumentListTablePreview";
import HelpPages from "../../components/HelpPages";
import HelpVideo from "../../assets/RequisitosPrevios.mp4";
import { LoadingButton } from "@mui/lab";

export const CapturePreviousDocuments = () => {
  const fileState = useSelector((state) => state.File);
  const userLogoutClose = true;
  const formMethods = useForm({ mode: "onChange" });
  const navigate = useNavigate();

  const [listDocuments, setListDocuments] = useState([]);
  const [openModalCamera, setOpenModalCamera] = useState(false);
  const [openModalFile, setOpenModalFile] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);

  const [cameraB64, setCameraB64] = useState(null);
  const [fileB64, setFileB64] = useState(null);
  const [extensionFile, setExtensionFile] = useState(null);
  const [nameDocumentCurrent, setNameDocumentCurrent] = useState(null);

  const [activeContinue, setActiveContinue] = useState(false);
  const [activeChangeReqPrevios, setActiveChangeReqPrevios] = useState(false);

  const dispatch = useDispatch();
  const newFileStage = useSelector((state) => state.File?.item?.FileData);
  const WorkFlowState = useSelector((state) => state.WorkFlowData);
  const FileFlowState = useSelector((state) => state.FileFlow);
  const rolUser = useSelector(
    (state) =>
      state.Authentication?.items?.UserLoginData?.SecurityLoginData?.Roles[0]
        ?.Code
  );

  useEffect(() => {
    if (newFileStage.Stage !== undefined && newFileStage.Stage !== null) {
      var listDocValidate =
        newFileStage.Actors[0]?.DocumentalGroups[0]?.Documents;
      var listDocStatus =
        newFileStage.Stage?.Actor?.DocumentalGroups[0]?.DocumentTypes[0]
          ?.Documents;
      // console.log("listDocStatus****************: ", listDocStatus);
      var listProp = newFileStage.Stage?.Properties?.find(
        (item) => item.Name === "DocumentListName"
      )?.Value;
      if (listProp !== undefined && listProp !== null && listProp !== "") {
        // console.log("listProp: ", listProp)
        var listProperties = JSON.parse(listProp);

        let newData = listProperties?.map((data) => {
          var statusValue = listDocStatus.find(
            (item) => item.Name === data.DocumentName
          )?.Status;
          // console.log("statusValue: ", statusValue);
          var statusValidate = listDocValidate?.find(
            (item) => item.Name === data.DocumentName
          )?.ValidationData?.Approved;

          console.log("statusValidate: ", statusValidate);

          return {
            Name: data.DocumentName,
            Required: data.Required,
            idDocument: 0,
            Status:
              statusValue !== undefined &&
              statusValue !== null &&
              statusValue !== ""
                ? statusValue
                : "Pendiente",
            Approved:
              statusValidate !== undefined &&
              statusValidate !== null &&
              statusValidate !== ""
                ? statusValidate
                : null,
          };
        });
        setListDocuments(newData);
      }
    }
  }, [newFileStage]);

  useEffect(() => {
    if (
      WorkFlowState.items !== undefined &&
      WorkFlowState.items !== null &&
      activeContinue
    ) {
      if (rolUser === "Operador" && userLogoutClose === true) {
        AlertConfirm(
          {
            icon: "success",
            title: "Tu proceso fue completado",
            okbtntext: "Aceptar",
            showCancelButton: false,
          },
          (result) => {
            if (result.isConfirmed) {
              sessionStorage.clear();
              localStorage.clear();
              navigate("/auth/login");
              window.location.reload();
            } else if (result.dismiss === "backdrop" && result.isDismissed) {
              sessionStorage.clear();
              localStorage.clear();
              navigate("/auth/login");
              window.location.reload();
            }
          }
        );
      } else {
        navigate("/inbox/files");
      }
    }
  }, [WorkFlowState]);

  useEffect(() => {
    if (
      FileFlowState.error !== undefined &&
      FileFlowState.error !== null &&
      activeChangeReqPrevios
    ) {
      console.log("REORDENAMIENTO", FileFlowState);
      var newList = listDocuments?.map((item) => {
        return {
          ...item,
          Status: item.Name === nameDocumentCurrent ? "Pendiente" : item.Status,
          Approved: item.Name === nameDocumentCurrent ? null : item.Approved,
        };
      });
      setListDocuments(newList);
      setNameDocumentCurrent(null);
      setActiveChangeReqPrevios(false);
    }
  }, [FileFlowState]);

  let handleCamera = (event) => {
    // console.log("handleCamera******:", event)
    var documentName = event.target.parentNode.id;
    // console.log(".:handleCamera:.  event.target ", event.target);
    // console.log(".:handleCamera:. documentName: currente: ", documentName);
    if (
      documentName === undefined ||
      documentName === null ||
      documentName === ""
    ) {
      // console.log(".:handleCamera:.  event.target 1 ", event.target.id);
      documentName = event.target.id;
      // console.log(".:handleCamera:.  documentName 1 ", documentName);
    }
    setNameDocumentCurrent(null);
    setNameDocumentCurrent(documentName);
    // alert("Event Camera")
    setOpenModalCamera(true);
    // setPlaying(true)
  };

  let handleFile = (event) => {
    var documentName = event.target.parentNode.id;
    // console.log(".:handleFile:.  event.target ", event.target);
    // console.log(".:handleFile:. documentName: currente: ", documentName);
    if (
      documentName === undefined ||
      documentName === null ||
      documentName === ""
    ) {
      // console.log(".:handleFile:.  event.target 1 ", event.target.id);
      documentName = event.target.id;
      // console.log(".:handleFile:.  documentName 1 ", documentName);
    }
    setNameDocumentCurrent(null);
    setNameDocumentCurrent(documentName);
    setOpenModalFile(true);
  };

  let handleDelete = (event) => {
    alert("Event delete");
  };

  let handleCloseFile = (event) => {
    // setStopVideo(true)
    setOpenModalFile(false);
    setOpenModalCamera(false);
    // setNameDocumentCurrent(null);
    setExtensionFile(null);
  };

  let handleFileChange = (fileB64, extension) => {
    // console.log(
    //   "handleFileChange***********************extension: ",
    //   extension,
    //   " fileB64: ",
    //   fileB64
    // );
    setFileB64(fileB64);
    setExtensionFile(extension);
  };

  let handleSendCameraData = (event) => {
    var stateValidateSave = listDocuments?.find(
      (item) => item.Name === nameDocumentCurrent
    )?.Approved;
    var newList = listDocuments?.map((item) => {
      return {
        ...item,
        Status: item.Name === nameDocumentCurrent ? "Cargado" : item.Status,
        Approved: item.Name === nameDocumentCurrent ? null : item.Approved,
      };
    });

    console.log("newList: ", newList);
    setListDocuments(newList);
    if (stateValidateSave === true || stateValidateSave === false) {
      console.log("Ejecuta actualizacion: ");
      let Body = {
        DocumentSectionId:
          newFileStage.Actors[0]?.DocumentalGroups[0]?.Documents?.find(
            (item) => item.Name === nameDocumentCurrent
          )?.Sections[0]?.Id,
        B64Content: fileB64,
        Extension: `.${extensionFile}`,
        Metadata: null,
        ContinueWorkFlow: false,
        Geolocation: null,
        DeferLoad: false,
        OmitirMergeVideo: false,
        OmitirRemovebg: false,
        AsignarEstatusCarga: false,
        IsCloseVersionDocument: null,
      };
      console.log("Ejecuta actualizacion: request:  ", Body);
      dispatch(UpdateSection({ Body }));
    } else {
      console.log("Ejecuta ESTO: ");
      const Body = {
        B64Content: cameraB64,
        FileId: newFileStage.Stage?.File?.Id,
        Actor_Id: null,
        SkipActor: false,
        SectionId:
          newFileStage.Stage?.Actor?.DocumentalGroups[0]?.DocumentTypes[0]
            ?.Sections[0]?.Id,
        Extension: ".jpg",
        Metadata: null,
        ContinueWorkFlow: false,
        Geolocation: null,
        DeferLoad: false,
        OmitirMergeVideo: false,
        OmitirRemovebg: false,
        AsignarEstatusCarga: false,
        Versionar: false,
        RegisterByUser: false,
        DocumentName: nameDocumentCurrent,
      };

      console.log("handleSendFileData******************Body*****", Body);
      dispatch(uplodadNewDocumentVersion({ Body }));
    }
    handleCloseFile();
  };

  let handleSendFileData = async (event) => {
    try {
      setLoadingSend(true);
      console.log("listDocuments: ", listDocuments, nameDocumentCurrent);

      var stateValidateSave =
        listDocuments?.find((item) => item.Name === nameDocumentCurrent)
          .Status === "Pendiente";

      // return console.log('STATUS', stateValidateSave)

      var newList = listDocuments?.map((item) => {
        return {
          ...item,
          Status: item.Name === nameDocumentCurrent ? "Registro" : item.Status,
          Approved: item.Name === nameDocumentCurrent ? null : item.Approved,
        };
      });

      if (stateValidateSave === false) {
        console.log("Ejecuta actualizacion: ");
        var idDocumentFile =
          newFileStage.Actors[0]?.DocumentalGroups[0]?.Documents?.find(
            (item) => item.Name === nameDocumentCurrent
          )?.Sections[0]?.Id;

        var idDocumentList = listDocuments?.find(
          (item) => item.Name === nameDocumentCurrent
        )?.idDocument;

        let Body = {
          DocumentSectionId: idDocumentFile ? idDocumentFile : idDocumentList,
          B64Content: fileB64,
          Extension: `.${extensionFile}`,
          Metadata: null,
          ContinueWorkFlow: false,
          Geolocation: null,
          DeferLoad: false,
          OmitirMergeVideo: false,
          OmitirRemovebg: false,
          AsignarEstatusCarga: false,
          IsCloseVersionDocument: null,
        };
        console.log("Ejecuta actualizacion: request:  ", Body);
        // ESTA ACTUALIZAR
        console.log("ENTRA A ACTUALIZAR");
        const fileFlowService = FileFlowService.getInstance();
        const fileAprobatewData = await fileFlowService.UpdateSection({ Body });
        console.log("fileAprobatewData", fileAprobatewData.data.data.IsOK);
        if (fileAprobatewData.data.data.IsOK === false) {
          Alert({
            icon: "error",
            title: "Error",
            text:
              "Error al actualizar el documento: " +
              fileAprobatewData.data.data.Messages,
          });
          setLoadingSend(false);
        } else {
          setLoadingSend(false);
          setListDocuments(newList);
        }
      } else {
        const Body = {
          B64Content: fileB64,
          FileId: newFileStage.Stage?.File?.Id,
          Actor_Id: null,
          SkipActor: false,
          SectionId:
            newFileStage.Stage?.Actor?.DocumentalGroups[0]?.DocumentTypes[0]
              ?.Sections[0]?.Id,
          Extension: `.${extensionFile}`,
          Metadata: null,
          ContinueWorkFlow: false,
          Geolocation: null,
          DeferLoad: false,
          OmitirMergeVideo: false,
          OmitirRemovebg: false,
          AsignarEstatusCarga: false,
          Versionar: false,
          RegisterByUser: false,
          DocumentName: nameDocumentCurrent,
        };
        // ESTA CREA UNO NUEVO
        console.log("ENTRA A UNO NUEVO");
        console.log("handleSendFileData******************Body*****", Body);
        const fileFlowService = FileFlowService.getInstance();
        const fileAprobatewData =
          await fileFlowService.uplodadNewDocumentVersion({ Body });
        console.log("fileAprobatewData", fileAprobatewData);
        if (fileAprobatewData?.IsOK === true) {
          newList = listDocuments?.map((item) => {
            return {
              ...item,
              idDocument:
                item.Name === nameDocumentCurrent
                  ? fileAprobatewData?.Body?.Result?.Sections[0]?.Id
                  : item.idDocument,
              Status:
                item.Name === nameDocumentCurrent ? "Registro" : item.Status,
              Approved:
                item.Name === nameDocumentCurrent ? null : item.Approved,
            };
          });
          console.log(
            "LISTA ACEPTADA",
            fileAprobatewData?.Body?.Result?.Sections[0]?.Id,
            newList
          );
          setListDocuments(newList);
          setLoadingSend(false);
        } else {
          newList = listDocuments?.map((item) => {
            return {
              ...item,
              Status:
                item.Name === nameDocumentCurrent ? "Pendiente" : item.Status,
              Approved:
                item.Name === nameDocumentCurrent ? null : item.Approved,
            };
          });
          setListDocuments(newList);
          setNameDocumentCurrent(null);
          setActiveChangeReqPrevios(false);
          setLoadingSend(false);
          console.log("PART 2");
        }
        // setListDocuments(newList);
        setActiveChangeReqPrevios(true);
      }
      setLoadingSend(false);
      handleCloseFile();
    } catch (error) {
      Alert({
        icon: "error",
        title: "Error",
        text: error.message,
      });
      setLoadingSend(false);
      handleCloseFile();
      console.log("TOMANDERROR", error);
    }
  };

  let handleAceptedContinue = (event) => {
    console.log("ContinueWorkFlow: ");
    let Body = {
      File_Id: newFileStage.Stage?.File?.Id,
      Status: null,
      Result: null,
      Stage_Id: null,
      Comments: null,
    };
    dispatch(ContinueWorkFlow(Body));
    setActiveContinue(true);
  };

  console.log("ListDocuments", listDocuments);

  return (
    <FormProvider {...formMethods}>
      <HelpPages
        Body={
          <Box sx={{ mt: 2 }}>
            <video
              width={"100%"}
              className="HelpVideo"
              src={HelpVideo}
              autoPlay
              loop
              muted
            />
          </Box>
        }
      />
      <Box
        sx={{
          width: "100%",
          maxWidth: 1300,
          margin: "0 auto",
          mb: 5,
        }}
      >
        <Box sx={{ mb: 3 }}>
          <Box sx={{ mb: 1 }}>
            <Typography variant="titulo" sx={{ fontWeight: 550 }}>
              Documentos a editar y/o firmar
            </Typography>
          </Box>
          <Typography variant="subtitle" sx={{ mb: 3, textAlign: "justify" }}>
            Estos documentos son los que pasarán por el proceso de edición y/o
            firma,{" "}
            <spam style={{ fontWeight: 550 }}>
              {" "}
              no se podrán modificar en esta sección
            </spam>
            , solo podrán visualizarse.
          </Typography>
        </Box>
        <DocumentListTablePreview />
      </Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: 1300,
          margin: "0 auto",
        }}
      >
        <Box sx={{ mb: 3 }}>
          <Box sx={{ mb: 1, display: "flex", alignItems: "center" }}>
            <Typography variant="titulo" sx={{ fontWeight: 550 }}>
              Documentos a cargar
            </Typography>
            <Brightness1Icon
              className="intermitente"
              sx={{ ml: 1, fontSize: 16 }}
              color="primary"
            />
          </Box>
          <Typography
            variant="subtitle"
            sx={{ mb: 3, display: "flex", textAlign: "justify" }}
          >
            Ingresa los documentos requeridos, estos documentos podrán ser
            aprobados por otro participante, en caso de ser rechazados, deberás
            volver a ingresarlos en esta sección para poder continuar con el
            proceso de firma.
          </Typography>
        </Box>
        <DocumentListTable
          Rows={listDocuments}
          OnCamera={handleCamera}
          OnFile={handleFile}
          OnDelete={handleDelete}
        />
      </Box>

      <CustomModalDialog
        Open={openModalFile}
        Title={`Carga tu ${nameDocumentCurrent}`}
        OnClose={handleCloseFile}
        Body={
          <UploadDcuments
            onChange={handleFileChange}
            setOpenModalFile={setOpenModalFile}
          />
        }
        Buttons={
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                variant="outlined"
                sx={{ height: 38, px: 10 }}
                onClick={handleCloseFile}
              >
                Cancelar
              </Button>
            </Grid>
            {fileB64 ? (
              <Grid item xs={12} sm={4}>
                <LoadingButton
                  loading={loadingSend}
                  fullWidth
                  variant="contained"
                  sx={{ height: 38, px: 10 }}
                  onClick={handleSendFileData}
                >
                  Enviar
                </LoadingButton>
              </Grid>
            ) : null}
          </Grid>
        }
      />

      <CustomModalDialog
        Open={openModalCamera}
        Title={`Carga tu ${nameDocumentCurrent}`}
        OnClose={handleCloseFile}
        Body={
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={7}>
              {openModalCamera && (
                <Camera
                  onCapture={(blob) => {
                    blobToBase64(blob).then((res) => {
                      console.log(
                        "blobToBase64-------- Document Request----------",
                        res
                      );
                      var resultReplace = res.replace(
                        "data:image/jpeg;base64,",
                        ""
                      );
                      setCameraB64(resultReplace);
                    });
                  }}
                  onClear={() => {
                    setCameraB64(null);
                  }}
                />
              )}
            </Grid>
          </Grid>
        }
        Buttons={
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                variant="outlined"
                sx={{ height: 45, px: 10 }}
                onClick={handleCloseFile}
              >
                Cancelar
              </Button>
            </Grid>
            {cameraB64 ? (
              <Grid item xs={12} sm={4}>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  sx={{ height: 45, px: 10 }}
                  onClick={handleSendCameraData}
                >
                  Enviar
                </LoadingButton>
              </Grid>
            ) : null}
          </Grid>
        }
      />
      {console.log(
        "ListDocuments",
        listDocuments?.filter(
          (item) =>
            (item.Status === "Pendiente" && item.Required === true) ||
            item.Approved === false
        )?.length > 0
      )}
      <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
        <Divider />
        <Grid item xs={11} md={3}>
          <Button
            fullWidth
            variant="contained"
            sx={{ height: 45, px: 10 }}
            onClick={handleAceptedContinue}
            disabled={
              listDocuments?.filter(
                (item) =>
                  (item.Status === "Pendiente" && item.Required === true) ||
                  item.Approved === false
              )?.length > 0
            }
            // onClick={() => {
            //     setconfirmFile(true);
            //     setopenaccordion(false);
            //     if (file) setValue("docName", file.name.split(".")[0]);
            // }}
          >
            Enviar
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
