import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  styled,
  tableCellClasses,
  Paper,
  TableContainer,
} from "@mui/material";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { InputField } from "../../components/InputField";
import ClearIcon from "@mui/icons-material/Clear";
import { useForm } from "react-hook-form";
import { FileService } from "../../services/FileService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setlistCheckers,
  setlistSigners,
  ReorderParticipant,
} from "../../store/actions";
import { UTCtoLocal } from "../../utils";
import { Icon } from "@iconify/react";
import IconToolTip from "../../components/IconToolTip";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  pointerEvents: "none",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.TableHeader,
    color: theme.palette.primary.mainLyrics,
    fontWeight: 500,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const ParticipantDraggableTableRevisor = (propsComponent) => {
  const [editing, setediting] = useState(null);
  const [listTypeUpdateOrder, setListTypeUpdateOrder] = useState("");
  const [userIdOrder, setUserIdOrder] = useState(null);
  const [newOrderCurent, setNewOrderCurent] = useState(null);
  const [templateActiveData, setTemplateActiveData] = useState(false);
  const [loadUpdateOrder, setLoadUpdateOrder] = useState(false);

  const { control, setValue } = useForm();

  const newFileState = useSelector((state) => state.NewFile);
  const fileState = useSelector((state) => state.File);
  const reorderPartData = useSelector((state) => state.ReorderParticipantData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (propsComponent.data.length > 0) {
      const statusTemplate = propsComponent.data.some(
        (objeto) => objeto.Capture === true
      );
      setTemplateActiveData(statusTemplate);
    }
  }, [propsComponent.data]);

  useEffect(() => {
    if (
      listTypeUpdateOrder !== null &&
      listTypeUpdateOrder !== "" &&
      userIdOrder !== null &&
      userIdOrder !== "" &&
      newOrderCurent !== null &&
      newOrderCurent !== ""
    ) {
      var ordType = listTypeUpdateOrder === "checkers" ? "check" : "sign";
      const request = {
        File_Id: newFileState.file.Id,
        User_Id: userIdOrder,
        OrderType: ordType,
        NewOrder: newOrderCurent,
        CleanDate: false,
      };
      dispatch(ReorderParticipant(request));
      // console.log(".:ReorderParticipant:. despues de la peticion")
      setLoadUpdateOrder(true);
      setUserIdOrder(null);
      setNewOrderCurent(null);
    }
  }, [listTypeUpdateOrder, userIdOrder, newOrderCurent]);

  useEffect(() => {
    if (
      loadUpdateOrder === true &&
      reorderPartData.items !== undefined &&
      reorderPartData.items !== null
    ) {
      setLoadUpdateOrder(false);
      if (listTypeUpdateOrder === "checkers") {
        let newData = reorderPartData.items
          ?.filter((item) => item.Check !== null)
          ?.map((data) => {
            return {
              Check: data.Check,
              CheckDeadline: data.CheckDeadline,
              CheckOrder: data.CheckOrder,
              UserId: data.UserId,
              UserEmail: data.Email,
            };
          });
        console.log("newData: checkers", newData);
        dispatch(setlistCheckers(newData));
        setListTypeUpdateOrder("");
      } else if (listTypeUpdateOrder === "signers") {
        let newData1 = reorderPartData.items
          ?.filter((item) => item.Sign !== null)
          ?.map((data) => {
            return {
              ApplyNOM151: true,
              Sign: data.Sign,
              SignDeadline: data.SignDeadline,
              SignOrder: data.SignOrder,
              Role: data.Role,
              UserEmail: data.Email,
              UserId: data.UserId,
            };
          });
        dispatch(setlistSigners(newData1));
        setListTypeUpdateOrder("");
      }
    }
  }, [reorderPartData]);

  const renderDate = (value) => {
    console.log("valuevalue", value);
    if (value.Check && value.CheckDeadline) {
      return UTCtoLocal(value.CheckDeadline, false);
    }
    if (value.Reviewer && value.CheckDeadline) {
      return UTCtoLocal(value.ReviewerDeadLine || value.CheckDeadline, false);
    }
    // if (value.Sign && value.SignDeadline) {
    //   return UTCtoLocal(value.SignDeadline, false);
    // }
    if (value.Capture && value.CaptureDeadline) {
      return UTCtoLocal(value.CaptureDeadline, false);
    }
    return "-";
  };

  const handleEdit = (value) => {
    console.log("value*******: ", value);
    if (value?.Check === true && value?.CheckDeadline !== null) {
      // console.log("value?.CheckDeadline****: ", value?.CheckDeadline)
      const limDate = new Date(value?.CheckDeadline);
      var lmd2 = limDate.setDate(limDate.getDate());
      setValue("dateEdit", lmd2);
    } else if (value?.Sign === true && value?.SignDeadline !== null) {
      // console.log("value?.SignDeadline****: ", value?.SignDeadline)
      const limDate = new Date(value?.SignDeadline);
      var lmd2 = limDate.setDate(limDate.getDate());
      setValue("dateEdit", lmd2);
    }
    setediting(value);
  };

  const updatedate = async (newdate) => {
    if (newdate === undefined || newdate === null || newdate === "") {
      return;
    }
    try {
      const fileService = FileService.getInstance();
      const participant = {
        ...editing,
        ...(editing && {
          CheckDeadline: newdate.toISOString(),
          [editing.Reviewer === true ? "ReviewerDeadLine" : ""]:
            newdate.toISOString(),
        }),
      };

      await fileService.registerParticipantInFile({
        FileId: newFileState.editStack
          ? fileState.item?.FileData?.Id
          : newFileState.file.Id,
        Participant: participant,
      });
      if (editing) {
        if (editing.Check || editing.Reviewer) {
          const newCheckList = newFileState.participants.check.map((p) => {
            if (p.UserId === participant.UserId) {
              return participant;
            }
            return p;
          });
          dispatch(setlistCheckers(newCheckList));
        }
        if (editing.Sign) {
          const newSignList = newFileState.participants.sign.map((p) => {
            if (p.UserId === participant.UserId) {
              return participant;
            }
            return p;
          });
          dispatch(setlistSigners(newSignList));
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setediting(null);
    }
  };

  const cancelEdit = () => {
    setediting(null);
  };

  const handleValidateDate = (typeDate, key) => {
    console.log("KEY", key);
    try {
      // const ValidityDate = newFileState.editStack
      //   ? fileState.item?.Validity
      //   : newFileState.file?.fileForm?.FileConfiguration?.Validity;

      const listchecks =
        propsComponent.listType === "checkers"
          ? propsComponent.data?.filter((l) => l.CheckDeadline)
          : propsComponent?.dataValidate?.filter((l) => l.CheckDeadline);
      const lclength = listchecks.length;
      if (typeDate === "checkers") {
        if (lclength === 0) {
          return new Date();
        }
        const limDate = new Date();
        var dateCalcule = key <= 0 ? limDate.getDate() : null;
        return limDate.setDate(dateCalcule);
      }
    } catch (ex) {
      console.log(".:handleValidateDate:. Error: ", ex);
    }
  };

  const handleValidateDateMax = (typeDate, key) => {
    try {
      // const ValidityDate = newFileState.editStack
      //   ? fileState.item?.Validity
      //   : newFileState.file?.fileForm?.FileConfiguration?.Validity;

      const listsigns =
        propsComponent.listType === "signers"
          ? propsComponent.data?.filter((l) => l.SignDeadline)
          : propsComponent?.dataValidate?.filter((l) => l.SignDeadline);
      if (typeDate === "checkers" && listsigns?.length > 0) {
        var lastdatesign = listsigns[0].SignDeadline;

        const limDate = new Date(lastdatesign);
        return limDate.setDate(limDate.getDate());
      }

      return null;
    } catch (ex) {
      console.log(".::. Error: ", ex);
    }
  };

  console.log("datauser", propsComponent.data);
  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead sx={{ backgroundColor: "primary.main" }}>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                {propsComponent.listType === "signers" ? (
                  <StyledTableCell align="center">Orden</StyledTableCell>
                ) : (
                  <>
                    <StyledTableCell
                      align="center"
                      sx={{ minWidth: 110 }}
                    ></StyledTableCell>
                  </>
                )}
                <StyledTableCell>Correo electrónico</StyledTableCell>
                <StyledTableCell align="center" sx={{ minWidth: 150 }}>
                  Fecha límite
                </StyledTableCell>
                {templateActiveData === true &&
                (propsComponent.listType === "signers" ||
                  propsComponent.listType === "capture") ? (
                  <StyledTableCell align="center" sx={{ minWidth: 150 }}>
                    Rol
                  </StyledTableCell>
                ) : null}
                <StyledTableCell align="center" sx={{ minWidth: 151 }}>
                  Función
                </StyledTableCell>
                <StyledTableCell align="center">Acciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {propsComponent.data?.map((value, index) => (
                  <>
                    <TableRow hover>
                      <StyledTableCell
                        sx={{ pointerEvents: "auto" }}
                      ></StyledTableCell>
                      <StyledTableCell
                        sx={{ pointerEvents: "auto" }}
                      ></StyledTableCell>
                      <StyledTableCell>
                        <span>{value.UserEmail || value.Email}</span>
                      </StyledTableCell>
                      {propsComponent.listType === "capture" ? (
                        <StyledTableCell
                          align="center"
                          sx={{ pointerEvents: "auto" }}
                        >
                          {value.UserId === editing?.UserId ? (
                            <InputField
                              inputType="date"
                              control={control}
                              name="dateEdit"
                              sxInput={{ maxWidth: 200 }}
                              onSelect={updatedate}
                              minDate={handleValidateDate(
                                propsComponent.listType,
                                index
                              )}
                              maxDate={handleValidateDateMax(
                                propsComponent.listType,
                                index
                              )}
                            />
                          ) : (
                            <span>
                              {UTCtoLocal(value.CaptureDeadline, false)}
                            </span>
                          )}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell
                          align="center"
                          sx={{ pointerEvents: "auto" }}
                        >
                          {value.UserId === editing?.UserId ? (
                            <InputField
                              inputType="date"
                              control={control}
                              name="dateEdit"
                              sxInput={{ maxWidth: 200 }}
                              onSelect={updatedate}
                              minDate={handleValidateDate(
                                propsComponent.listType,
                                index
                              )}
                              maxDate={handleValidateDateMax(
                                propsComponent.listType,
                                index
                              )}
                            />
                          ) : (
                            <span>{renderDate(value)}</span>
                          )}
                        </StyledTableCell>
                      )}

                      {templateActiveData === true &&
                      propsComponent.listType === "capture" ? (
                        <StyledTableCell align="center" sx={{ minWidth: 150 }}>
                          {value.Role}
                        </StyledTableCell>
                      ) : null}
                      <StyledTableCell align="center">
                        {propsComponent.listType === "checkers" ? (
                          value.Reviewer ? (
                            <IconToolTip
                              src={"AprobadorRevision.png"}
                              title={"Usuario Aprobador."}
                              width={23}
                            />
                          ) : (
                            <IconToolTip
                              src={"Revisor.png"}
                              title={"Usuario Editor."}
                              width={23}
                            />
                          )
                        ) : propsComponent.listType === "capture" ? (
                          <IconToolTip
                            src={"CaptureIcon.png"}
                            title={"Usuario Capturador."}
                            width={23}
                          />
                        ) : null}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            sx={{ pointerEvents: "auto" }}
                            size="small"
                            color="secondary"
                            onClick={(e) =>
                              value.UserId === editing?.UserId
                                ? cancelEdit()
                                : handleEdit(value)
                            }
                          >
                            {value.UserId === editing?.UserId ? (
                              <ClearIcon />
                            ) : (
                              <ModeEditOutlinedIcon />
                            )}
                          </Button>
                          <Button
                            sx={{ pointerEvents: "auto" }}
                            size="small"
                            color="secondary"
                            onClick={(e) =>
                              propsComponent.handleDelete(
                                value,
                                propsComponent.listType
                              )
                            }
                          >
                            <Icon icon="ph:trash-light" fontSize={19} />
                          </Button>
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                  </>
                ))}
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
