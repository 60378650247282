import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
// import { ReactComponent as ClearIcon } from "./clear.svg";
import SignaturePad from "react-signature-canvas";
import { SignatureService } from '../../../../../services/SignatureService';
import { makeStyles } from '@mui/styles';
import { Alert } from '../../../../../components/Alert';
import { LoadingButton } from '@mui/lab';
import { useLocation } from 'react-router-dom';
import { UseImage } from '../../../../../components/UseImage';
import { createCanvas, loadImage } from "canvas";
import { GeoLocationGeneric } from "../../../../../components/GeoLocationGeneric";
import { obtenerDireccionIP } from '../../../../../utils';
import { CustomModalDialogGeolocation } from '../../../../../components/CustomModalDialogGeolocation';

const useStyles = makeStyles((theme) => ({
  sigPad: {
    width: "102%",
    height: "102%",
  },
  paperResponsive: {
    display: "flex",
    padding: 12,
    flexWrap: "wrap",
    borderLeft: `1.8px solid ${theme.palette.secondary.main60}`,
    alignContent: "space-between",
    minHeight: 690,
    [theme.breakpoints.down("sm")]: {
      borderLeft: `0px solid ${theme.palette.secondary.main60}`,
      padding: 7,
      minHeight: "auto",
    },
  },
  botonReponsive: {
    minWidth: '70%',
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  firmaAqui: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '@media only screen and (max-width: 812px)': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  signPlaceholder: {
    color: theme.palette.primary.main,
    alignSelf: 'center',
    fontWeight: 550,
    fontSize: 18
  },
}));

export default function ScreenMovilPad() {
  const containerSignDetectRef = useRef(null);

  const classes = useStyles();
  const query = new URLSearchParams(useLocation().search);
  const guid = query.get("GUID");
  const userId = query.get("UserId");
  const fileState = useSelector((state) => state.File.item);
  const canvasRef = useRef(null);
  const [hidden, setHidden] = useState(false);
  const [reloadSing, setReloadSing] = useState(false);
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [blockFirma, setBlockFirma] = useState(false)
  const [inicioFirma, setInicioFirma] = useState();
  const [finFirma, setFinFirma] = useState();
  const [signBase64, setSignBase64] = useState(false);
  const [isWriteSign, setIsWriteSign] = useState(false);
  const [estaFirmando, setEstaFirmando] = useState(false)
  const [reloadGeolocation, setReloadGeolocation] = useState(true)
  const [ipDirection, setIpDirection] = useState(null);
  const [geolocationIP, setGeolocationIP] = useState(false);
  const [openAlerte, setOpenAlerte] = useState(false);


  useEffect(() => {
    const shouldRefresh = sessionStorage.getItem('refreshPadFirma');
    console.log("refreshPadFirma: ", shouldRefresh)
    if (shouldRefresh === 'false' || shouldRefresh === null || shouldRefresh === "null") {
      window.location.reload();
      sessionStorage.setItem('refreshPadFirma', 'true');
    } else {
      console.log("refreshPadFirma else: ", shouldRefresh)
    }
  }, [])

  useEffect(() => {
    obtenerDireccionIP((ip) => {
      setIpDirection(ip);
      console.log("Dirección IP:", ip);
    });
  }, []);

  const signSyncSave = async () => {
    try {
      setReloadSing(true)
      if (!lat && !lon) {
        // Alert({
        //   icon: "error",
        //   text: `Por favor verifica que tu ubicación este activada.`,
        //   okbtntext: "Aceptar",
        // });
        setReloadSing(false);
        setReloadGeolocation(true);
        return null;
      }

      let cnv = canvasRef.current.getCanvas().toDataURL("image/png");
      const puntos = canvasRef.current.toData();
      // const inicio = canvasRef.()
      const fin = canvasRef.current.toData();

      const resizeImage = async (base64Image, width, height) => {
        const canvas = createCanvas(width, height);
        const context = canvas.getContext("2d");
        const image = await loadImage(base64Image);
        context.drawImage(image, 0, 0, width, height);
        const resizedBase64Image = canvas.toDataURL("image/png");
        return resizedBase64Image;
      };
      const resizedImage = await resizeImage(cnv, 390, 190);

      let signSplitted = resizedImage.split(",");
      let sign = signSplitted[1];

      const Body = {
        GUID: guid,
        Status: null,
        SignData: sign,
        SignInfo: {
          OriginLocation: geolocationIP === true ? 'IP' : 'GPS',
          TimeStamp: `${new Date().getTime()}`,
          signetAtFSimple: new Date().toISOString(),
          Width: 500,
          Height: 290,
          MacAddress: ipDirection !== undefined && //Feliz cumple brayan  29/08/2023 
            ipDirection !== null && ipDirection !== "" ?
            ipDirection : "000.000.000.000",
          Latitude: lat,
          Longitud: lon,
          StartedAt: inicioFirma,
          EndedAt: finFirma,
          PuntosControl: puntos,
          Length: "",
        },
        userId: userId
      };

      console.log(Body)

      const signatureService = SignatureService.getInstance();
      const responseSync = await signatureService.autographSignSyncSave({ Body });

      console.log(responseSync)

      if (responseSync?.Body) {
        setReloadSing(false)
        setBlockFirma(true);
      } else {
        setReloadSing(false)
        setBlockFirma(false);
      }

    } catch (error) {
      Alert({
        icon: "error",
        title:
          "Ha ocurrido un error al firmar.",
      });
      setReloadSing(false);
      setBlockFirma(false);
      console.log(error)
    }
  }

  let setPlaceholder = () => {
    console.log("setPlaceholder................: ", isWriteSign)
    setIsWriteSign(true)
    console.log("setIsWriteSign(true) ................: ", isWriteSign)
    if (!inicioFirma) {
      setInicioFirma(new Date().toISOString());
    }
    setHidden(true);
    setSignBase64(true);
  };

  let finaliza = () => {
    console.log("finaliza................: ", isWriteSign)
    setIsWriteSign(false)
    setFinFirma(new Date().toISOString());
  };

  let clearCanvas = () => {
    canvasRef.current.clear();
    setFinFirma(null);
    setInicioFirma(null);
    setHidden(false);
    setSignBase64(false);
    sessionStorage.setItem('refreshFlag', null);
  };

  function detectOrientation() {
    if (window.orientation === 0 || window.orientation === 180) {
      // El dispositivo está en posición vertical
      return '40vh'
    } else if (window.orientation === 90 || window.orientation === -90) {
      // El dispositivo está en posición horizontal
      return '40vh'
    }
  }

  const handleMouseEnter = () => {
    setEstaFirmando(true);
  };

  const handleTouchMove = (event) => {
    try {
      if (!estaFirmando) return;

      const rect = containerSignDetectRef.current.getBoundingClientRect();
      const touch = event.touches[0];

      if (touch.clientX < rect.left || touch.clientX > rect.right ||
        touch.clientY < rect.top || touch.clientY > rect.bottom) {
        handleMouseLeave();
      }
    } catch (e) { }
  };

  const handleMouseLeave = () => {
    if (estaFirmando && isWriteSign) {
      // Realizar acciones necesarias cuando se sale del div al firmar
      Alert({
        icon: "warning",
        okbtntext: "Entendido",
        // okbtnclass: "confirmButtonSweetWarning",
        text: "Detectamos que tu firma se plasmó fuera de los límites, por favor, dibuja tu grafo sin salirte de los bordes.",
      }, (event) => {
        clearCanvas()
      });
      clearCanvas()
    }
    setEstaFirmando(false);
  };

  return (
    <>
      {reloadGeolocation &&
        <GeoLocationGeneric SuccessFN={(latitude, longitude) => {
          console.log("succes,latitude, longitude ", latitude, longitude);
          setLat(latitude);
          setLon(longitude);
        }}
          ErrorFN={(error) => console.log('error', error)}
          setOpenAlerte={setOpenAlerte}
          ReloadGeoLocation={reloadGeolocation}
          setGeolocationIP={setGeolocationIP}
          geolocationIP={geolocationIP}
          setReloadGeoLocation={setReloadGeolocation} />}
      <CustomModalDialogGeolocation Open={openAlerte} setOpenAlerte={setOpenAlerte} setGeolocationIP={setGeolocationIP} />


      {blockFirma === true ? (
        <Grid container justifyContent={'center'} alignItems={'center'} sx={{ background: 'white', height: "100vh" }}>
          <Box>
            <Box sx={{ display: "flex", justifyContent: 'center' }}>
              <UseImage type="Image" height={150} src="SuccessIcon.gif" />
            </Box>
            <Box sx={{ p: 3, textAlign: 'center' }}>
              <Typography variant='titulo'>
                Tú firma ha sido sincronizada con éxito, regresa a tu navegador para continuar con el proceso
              </Typography>
            </Box>
          </Box>
        </Grid>
      ) : (
        <Grid container justifyContent={'center'}>
          <Grid item sm={11} xs={12}>
            <Box sx={{ p: 1, textAlign: 'center' }}>
              <Typography variant='titulo'>
                Firma autógrafa
              </Typography>
            </Box>
            <Box sx={{ p: 2, textAlign: "justify" }}>
              <Typography>
                Al firmar estás aceptando la aplicación de tu Firma Autógrafa en Mensaje de Datos en los documentos mostrados en el visor
              </Typography>
            </Box>
            <Grid container justifyContent={'center'}>
              <Box sx={{ textAlign: 'center', overflow: 'hidden' }}>
                <Box sx={{
                  width: '100%',
                  display: 'flex',
                  height: '25vh',
                  alignContent: 'center',
                  marginTop: '0px',
                  alignItems: 'center',
                  overflow: 'hidden',
                  textAlign: 'center',
                  justifyContent: 'center',
                  border: `2px solid #C20E30`,
                  position: 'relative',
                  borderRadius: '8px',
                  '@media only screen and (min-width: 301px) and (max-width: 600px)': {
                    height: '33vh',
                    maxWidth: '300px',
                  },
                  '@media only screen and (min-width: 601px) and (max-width: 896px)': {
                    height: '55vh',
                    maxWidth: '600px',
                  },
                }}
                  ref={containerSignDetectRef}
                  onTouchStart={handleMouseEnter}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onTouchEnd={handleMouseLeave}
                  onTouchMove={handleTouchMove} >
                  <SignaturePad
                    ref={canvasRef}
                    backgroundColor="#FFFFFF"
                    penColor="black"
                    canvasProps={{ className: classes.sigPad }}
                    onBegin={setPlaceholder}
                    onEnd={finaliza}
                  />
                  {hidden ? null :
                    <Box className={classes.firmaAqui}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        className={classes.signPlaceholder}
                      >
                        Traza tu firma aquí
                      </Typography>
                    </Box>}
                </Box>
              </Box>
            </Grid>
          </Grid >
          <Grid xs={11} md={5} lg={5}>
            <Box sx={{ p: 1, display: "flex", alignContent: 'center', justifyContent: "center" }}>
              <Button
                fullWidth
                sx={{ height: 38 }}
                size='medium'
                disabled={!hidden}
                variant='outlined'
                onClick={clearCanvas}
              >
                <Typography sx={{ letterSpacing: 1.2 }}>Borrar</Typography>
              </Button>
            </Box>
            <Box sx={{ p: 1, display: "flex", alignContent: 'center', justifyContent: "center" }}>
              <LoadingButton
                fullWidth
                sx={{ height: 38 }}
                variant="contained"
                size="medium"
                disabled={blockFirma}
                onClick={() => signSyncSave()}
                loading={reloadSing}
              >
                <Typography sx={{ letterSpacing: 1.2 }}>Aceptar</Typography>
              </LoadingButton>
            </Box>
          </Grid>
        </Grid >
      )
      }
    </>
  )
}
