import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import "./style.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { Fragment } from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { FileFlowService } from "../../../services/FileFlowService";
import moment from "moment";


const BoxContainer = styled(Box)(({ theme }) => ({
  height: "auto",
  width: "100%",
  justifyContent: "space-between",
  marginTop: 0.3,
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const BoxTitulo = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "40%",
  mt: 0.3,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const BoxTexto = styled(Box)(({ theme }) => ({
  wordWrap: "break-word",
  width: "60%",
  mt: 0.3,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export default function XmlDocumento({ xmlData }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(false);
  const [dataFirmante, setDataFirmante] = useState(null);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      {!value ? (
        <Grid lg={12} md={12} xs={12}>
          <Box sx={{ p: 1, mt: 1 }}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Box sx={{ textAlign: "center", p: 1 }}>
                <Typography variant="titulo">
                  Verificación vía XML del documento firmado
                </Typography>
              </Box>
              <Box sx={{ p: 1 }}>
                <Box sx={{ mt: 0.5, display: "flex" }}>
                  <Typography variant="h6">
                    Información identificada del documento firmado
                  </Typography>
                </Box>
                <BoxContainer>
                  <BoxTitulo>
                    <Typography variant="subtitle1">
                      Nombre del documento:
                    </Typography>
                  </BoxTitulo>
                  <BoxTexto>
                    <Typography variant="subtitle1">
                      {xmlData?.NombreDocumento ?? ""}
                    </Typography>
                  </BoxTexto>
                </BoxContainer>
                <BoxContainer>
                  <BoxTitulo>
                    <Typography variant="subtitle1">
                      Hash Original:
                    </Typography>
                  </BoxTitulo>
                  <BoxTexto>
                    <Typography variant="subtitle1">
                      {xmlData?.hashDocumento ?? ""}
                    </Typography>
                  </BoxTexto>
                </BoxContainer>

                <BoxContainer>
                  <BoxTitulo>
                    <Typography variant="subtitle1">
                      Estatus:
                    </Typography>
                  </BoxTitulo>
                  {console.log(xmlData?.verificationResults?.VerifyXml)}
                  <BoxTexto>
                    {xmlData?.verificationResults?.VerifyXml ?
                      <Typography variant="subtitle1" color={'#4CAF50'}>
                        Aceptado
                      </Typography>
                      : xmlData?.verificationResults?.VerifyXml === false ?
                        <Typography variant="subtitle1" color={'#C20E30'}>
                          Rechazado
                        </Typography> : '---'}
                  </BoxTexto>
                </BoxContainer>

                {xmlData?.DetalleNom151 !== null ? (<>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="h6">
                      NOM-151
                    </Typography>
                  </Box>
                  <BoxContainer>
                    <BoxTitulo>
                      <Typography variant="subtitle1">
                        Emisor:
                      </Typography>
                    </BoxTitulo>
                    <BoxTexto>
                      <Typography variant="subtitle1">
                        {xmlData?.DetalleNom151?.PSC ?? ""}
                      </Typography>
                    </BoxTexto>
                  </BoxContainer>
                  <BoxContainer>
                    <BoxTitulo>
                      <Typography variant="subtitle1">
                        Fecha de emisión:
                      </Typography>
                    </BoxTitulo>
                    <BoxTexto>
                      <Typography variant="subtitle1">
                        {moment(xmlData?.DetalleNom151?.Timestamp).format('DD-MM-YYYY HH-mm-ss')}
                        {/* {UTCtoLocal(xmlData?.DetalleNom151?.Timestamp, true)} */}
                      </Typography>
                    </BoxTexto>
                  </BoxContainer>
                  <BoxContainer>
                    <BoxTitulo>
                      <Typography variant="subtitle1">
                        Estatus:
                      </Typography>
                    </BoxTitulo>
                    <BoxTexto>
                      {xmlData?.verificationResults?.VerifyNom151Content ?
                        <Typography variant="subtitle1" color={'#4CAF50'}>
                          Aceptado
                        </Typography>
                        : xmlData?.verificationResults?.VerifyNom151Content === false ?
                          <Typography variant="subtitle1" color={'#C20E30'}>
                            Rechazado
                          </Typography> : '---'}
                    </BoxTexto>
                  </BoxContainer>
                  <BoxContainer>
                    <BoxTitulo>
                      <Typography variant="subtitle1">
                        Validación:
                      </Typography>
                    </BoxTitulo>
                    <BoxTexto>
                      <Typography variant="subtitle1">
                        Validado con metadatos
                      </Typography>
                    </BoxTexto>
                  </BoxContainer>
                </>) : null}
                {/* ------------------LIISTA DE FIRMANTES--------------------------------------------------- */}
                <Box sx={{ mt: 3 }}>
                  <Typography variant="h6">
                    Detalle de firmantes
                  </Typography>
                </Box>
                <Box overflow={"auto"} sx={{ height: 280 }}>
                  {xmlData?.Signers?.map((firma) => (
                    <Box
                      sx={{ m: 1.5, border: ".5px solid #C2C2C2" }}
                      onClick={() => {
                        handleClick();
                        setDataFirmante(firma);
                      }}
                    >
                      <BoxContainer>
                        <BoxTitulo>
                          <Typography variant="subtitle1">
                            Nombre:
                          </Typography>
                        </BoxTitulo>
                        <BoxTexto>
                          <Typography variant="subtitle1">
                            {firma?.Name ?? ""}
                          </Typography>
                        </BoxTexto>
                      </BoxContainer>
                      {firma?.Id ? (
                        <BoxContainer>
                          <BoxTitulo>
                            <Typography variant="subtitle1">
                              Identificador:
                            </Typography>
                          </BoxTitulo>
                          <BoxTexto>
                            <Typography variant="subtitle1">
                              {firma?.Id ?? ""}
                            </Typography>
                          </BoxTexto>
                        </BoxContainer>
                      ) : null}
                      <BoxContainer>
                        <BoxTitulo>
                          <Typography variant="subtitle1">
                            Fecha firma UTC:
                          </Typography>
                        </BoxTitulo>
                        <BoxTexto>
                          <Typography variant="subtitle1">
                            {console.log(moment(firma?.Signature?.signedAt).format())}
                            {moment.utc(firma?.Signature?.signedAt).format("DD-MM-YYYY HH:mm:ss")}
                            {/* {UTCtoLocal(firma?.Signature?.signedAt, false)} */}
                          </Typography>
                        </BoxTexto>
                      </BoxContainer>
                      <BoxContainer>
                        <BoxTitulo>
                          <Typography variant="subtitle1">
                            Ubicación Obtenida:
                          </Typography>
                        </BoxTitulo>
                        <BoxTexto>
                          <Typography variant="subtitle1">
                            {console.log('FIRMADO', firma?.OriginLocation)}
                            {firma?.OriginLocation === "IP" ? "A través de IP" : firma?.OriginLocation === "GPS" ? "A través de GPS " : "Sin obtención"}
                          </Typography>
                        </BoxTexto>
                      </BoxContainer>
                      <Box sx={{ borderTop: ".5px solid #C2C2C2" }}>
                        <Typography variant="subtitle1" sx={{ mt: 0.3 }}>
                          {firma?.TipoFirma ?? ""}
                        </Typography>
                      </Box>
                      {firma.TipoFirma === "Firma Electrónica Avanzada" ? (
                        <Box sx={{ borderTop: ".5px solid #C2C2C2" }}>
                          <Box
                            sx={{
                              p: 1,
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                wordWrap: "break-word",
                                mt: 0.3,
                                width: "100%",
                              }}
                            >
                              <Typography variant="body2">
                                {firma?.Signature?.FirmaB64 ?? ""}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                              maxWidth: 170,
                              display: "flex",
                              justifyContent: "center",
                              p: 1
                            }}
                          >
                            <img
                              height={"100%"}
                              width={"100%"}
                              alt="imagen Firma"
                              src={`data:image/jpeg;base64,${firma?.Signature.FirmaB64}`}
                            />
                          </Box>
                        </Box>
                      )}
                      <Box
                        sx={{
                          borderTop: ".5px solid #C2C2C2",
                          backgroundColor: "#F6F6F6",
                        }}
                      >
                        <Box sx={{ p: 1, textAlign: "center" }}>
                          <Typography
                            variant="subtitle1"
                            color="primary"
                            onClick={() => {
                              setValue(true);
                              setDataFirmante(firma);
                            }}
                          >
                            <u>Ver más detalles</u>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>
      ) : (
        <>
          <Box sx={{ mb: 1, mt: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={() => setValue(false)}
            >
              Regresar
            </Button>
          </Box>
          <DataFirmante data={dataFirmante} />
        </>
      )}
    </Fragment>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const DataFirmante = ({ data }) => {
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const [page, setPage] = React.useState(0);
  const [b64Video, setb64Video] = useState(null);
  // const [dataVideo, setDataVideo] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
    page > 0
      ? Math.max(
        0,
        (1 + page) * rowsPerPage -
        data.SignatureEvent?.Points[0]?.Points.length
      )
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const agregarValores =()=>{
  //   for (let i = 0; i < data?.SignatureEvent?.Points.length; i++) {
  //     const element = data?.SignatureEvent.Points[i];
  //     console.log(element);
  //     setvalores({...element.Points});
  //     // setvalores(Object.assign({}, element.Points))
  //     // datos = Object.assign({}, element.Points);
  //   }

  // };
  // console.log(valores);
  // useEffect(() => {
  //   agregarValores()
  // }, [])

  const verVideo = async () => {
    const fileService = FileFlowService.getInstance();
    const res = await fileService.downloadDocumentByReference({
      reference: data.EvidenceVideo?.reference,
    });
    if (res.Body) {
      setb64Video(res?.Body?.B64Content);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isoDate = new Date().toISOString();
  const utcMoment = moment.utc(isoDate);
  const localMoment = utcMoment.local();
  const localTime = localMoment.format("DD/MM/YYYY HH:mm:ss");
  console.log('localTime', localTime);

  return (
    <Fragment>
      <Box sx={{ border: ".5px solid #C2C2C2" }}>
        <Box sx={{ border: ".5px solid #C2C2C2" }}>
          <Box sx={{ ml: 2, mt: 1, mb: 1 }}>
            <Typography variant={"h6"}>Información</Typography>
          </Box>
        </Box>
        <BoxContainer sx={{ ml: 2 }}>
          <BoxTitulo>
            <Typography variant="subtitle1">
              Nombre:
            </Typography>
          </BoxTitulo>
          <BoxTexto>
            <Typography variant="subtitle1">{data?.Name ?? ""}</Typography>
          </BoxTexto>
        </BoxContainer>
        {data?.Id ? (
          <BoxContainer sx={{ ml: 2 }}>
            <BoxTitulo>
              <Typography variant="subtitle1">
                Identificador:
              </Typography>
            </BoxTitulo>
            <BoxTexto>
              <Typography variant="subtitle1">{data?.Id ?? ""}</Typography>
            </BoxTexto>
          </BoxContainer>
        ) : null}
        <BoxContainer sx={{ ml: 2 }}>
          <BoxTitulo>
            <Typography variant="subtitle1">
              Fecha firma UTC:
            </Typography>
          </BoxTitulo>
          <BoxTexto>
            <Typography variant="subtitle1">
              {moment.utc(data?.Signature?.signedAt).format('DD-MM-YYYY HH:mm:ss')}
              {console.log('fechas', data?.Signature?.signedAt)}
              {/* {UTCtoLocal(data?.Signature?.signedAt, true)} */}
            </Typography>
          </BoxTexto>
        </BoxContainer>
        <BoxContainer sx={{ ml: 2 }}>
          <BoxTitulo>
            <Typography variant="subtitle1">
              Correo electrónico:
            </Typography>
          </BoxTitulo>
          <BoxTexto>
            <Typography variant="subtitle1">{data?.Email ?? ""}</Typography>
          </BoxTexto>
        </BoxContainer>
        <BoxContainer sx={{ ml: 2 }}>
          <BoxTitulo>
            <Typography variant="subtitle1">
              Ubicación Obtenida:
            </Typography>
          </BoxTitulo>
          <BoxTexto>
            {console.log('datadata', data)}
            <Typography variant="subtitle1">{data?.OriginLocation ?? "Sin obtención"}</Typography>
          </BoxTexto>
        </BoxContainer>
        <Box overflow={"auto"} sx={{ mt: 1, maxHeight: 500 }}>
          <Accordion>
            <AccordionSummary
              sx={{ borderBottom: "1px solid  #C2C2C2", height: 10 }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant={"h6"}>
                {data?.TipoFirma}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {data.TipoFirma === "Firma Electrónica Avanzada" ? (
                <>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="subtitle1">Firma:</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        wordWrap: "break-word",
                        mt: 0.3,
                        width: "100%",
                      }}
                    >
                      <Typography variant="subtitle1">
                        {data?.Signature?.FirmaB64 ?? ""}
                      </Typography>
                    </Box>
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: 170,
                      display: "flex",
                      justifyContent: "center",
                      borderBottom: "1px solid #C21133",
                    }}
                  >
                    <img
                      height={"100%"}
                      width={"100%"}
                      alt="imagen Firma"
                      src={`data:image/jpeg;base64,${data?.Signature.FirmaB64}`}
                    />
                  </Box>
                </Box>
              )}
              <BoxContainer>
                <BoxTitulo>
                  <Typography variant="subtitle1">
                    Identificador:
                  </Typography>
                </BoxTitulo>
                <BoxTexto>
                  <Typography variant="subtitle1">
                    {data?.Signature?.MacAddress}
                  </Typography>
                </BoxTexto>
              </BoxContainer>
              <BoxContainer>
                <BoxTitulo>
                  <Typography variant="subtitle1">
                    Latitud:
                  </Typography>
                </BoxTitulo>
                <BoxTexto>
                  <Typography variant="subtitle1">
                    {data?.Signature?.Latitud ? data?.Signature?.Latitud : <b>Sin detección</b>}
                  </Typography>
                </BoxTexto>
              </BoxContainer>
              <BoxContainer>
                <BoxTitulo>
                  <Typography variant="subtitle1">
                    Longitud:
                  </Typography>
                </BoxTitulo>
                <BoxTexto>
                  <Typography variant="subtitle1">
                    {data?.Signature?.Longitud ? data?.Signature?.Longitud : <b>Sin detección</b>}
                  </Typography>
                </BoxTexto>
              </BoxContainer>
              {data.TipoFirma === "Firma Electrónica Avanzada" ? null : (
                <>
                  <BoxContainer>
                    <BoxTitulo>
                      <Typography variant="subtitle1">
                        Fecha inicio UTC:
                      </Typography>
                    </BoxTitulo>
                    <BoxTexto>
                      <Typography variant="subtitle1">
                        {moment.utc(data?.SignatureEvent?.startedAt).format("DD-MM-YYYY HH:mm:ss")}
                        {/* {UTCtoLocal(data?.SignatureEvent?.startedAt, true)} */}
                      </Typography>
                    </BoxTexto>
                  </BoxContainer>
                  <BoxContainer>
                    <BoxTitulo>
                      <Typography variant="subtitle1">
                        Fecha inicio local:
                      </Typography>
                    </BoxTitulo>
                    <BoxTexto>
                      <Typography variant="subtitle1">
                        {moment.utc(data?.SignatureEvent?.startedAt).local().format('DD-MM-YYYY HH:mm:ss')}
                      </Typography>
                    </BoxTexto>
                  </BoxContainer>
                  <BoxContainer>
                    <BoxTitulo>
                      <Typography variant="subtitle1">
                        Fecha fin UTC:
                      </Typography>
                    </BoxTitulo>
                    <BoxTexto>
                      <Typography variant="subtitle1">
                        {moment.utc(data?.SignatureEvent?.endedAt).format("DD-MM-YYYY HH:mm:ss")}
                        {/* {UTCtoLocal(data?.SignatureEvent?.endedAt, true)} */}
                      </Typography>
                    </BoxTexto>
                  </BoxContainer>
                  <BoxContainer>
                    <BoxTitulo>
                      <Typography variant="subtitle1">
                        Fecha fin local:
                      </Typography>
                    </BoxTitulo>
                    <BoxTexto>
                      <Typography variant="subtitle1">
                        {moment.utc(data?.SignatureEvent?.endedAt).local().format('DD-MM-YYYY HH:mm:ss')}
                      </Typography>
                    </BoxTexto>
                  </BoxContainer>
                  <BoxContainer>
                    <BoxTitulo>
                      <Typography variant="subtitle1">
                        Cantidad de trazos:
                      </Typography>
                    </BoxTitulo>
                    <BoxTexto>
                      <Typography variant="subtitle1">
                        {data?.SignatureEvent?.Points.length ?? ""}
                      </Typography>
                    </BoxTexto>
                  </BoxContainer>
                  <Box sx={{ ml: 1 }}>
                    <Typography variant="subtitle1">Mapa de firma:</Typography>
                    <Box>
                      <TableContainer sx={{ mt: 1 }} component={Paper}>
                        <Table
                          // sx={{ minWidth: 300 }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow sx={{ background: "#F6F6F6" }}>
                              <TableCell
                                align="center"
                                sx={{ color: "#C20E30" }}
                              >
                                X
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ color: "#C20E30" }}
                              >
                                Y
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ color: "#C20E30" }}
                              >
                                TimeStamp UTC
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(rowsPerPage > 0 && data?.SignatureEvent?.Points[0]?.Points
                              ? data.SignatureEvent.Points[0].Points.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              : []
                            ).map((row) => (
                              <TableRow key={row.name}>
                                <TableCell align="center">{row.x}</TableCell>
                                <TableCell align="center">{row.y}</TableCell>
                                <TableCell align="center">
                                  {moment.utc(row.timestamp).format("DD-MM-YYYY HH:mm:ss")}
                                </TableCell>
                              </TableRow>
                            ))}
                            {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                rowsPerPageOptions={[0]}
                                colSpan={3}
                                count={
                                  data.SignatureEvent?.Points[0]?.Points.length
                                }
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                  inputProps: {
                                    "aria-label": "rows per page",
                                  },
                                  native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                              />
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                </>
              )}

              {data.TipoFirma === "Firma Electrónica Avanzada" ? (
                <>
                  <BoxContainer>
                    <BoxTitulo>
                      <Typography variant="subtitle1">
                        Verificación OCSP:
                      </Typography>
                    </BoxTitulo>
                    <BoxTexto>
                      <Typography variant="subtitle1">
                        {data?.Ocsp?.Name ?? ""}
                      </Typography>
                    </BoxTexto>
                  </BoxContainer>
                  <BoxContainer>
                    <BoxTitulo>
                      <Typography variant="subtitle1">
                        Estatus:
                      </Typography>
                    </BoxTitulo>
                    <BoxTexto>
                      <Typography variant="subtitle1">
                        {data?.Ocsp?.Status ?? ""}
                      </Typography>
                    </BoxTexto>
                  </BoxContainer>
                  <BoxContainer>
                    <BoxTitulo>
                      <Typography variant="subtitle1">
                        Fecha de verificación OCSP:
                      </Typography>
                    </BoxTitulo>
                    <BoxTexto>
                      <Typography variant="subtitle1">
                        {moment.utc(data?.Ocsp?.timestamp).format("DD-MM-YYYY HH:mm:ss")}
                        {/* {UTCtoLocal(data?.Ocsp?.timestamp, true)} */}
                      </Typography>
                    </BoxTexto>
                  </BoxContainer>
                </>
              ) : null}
            </AccordionDetails>
          </Accordion>
          {data.OTP ? (
            <Accordion>
              <AccordionSummary
                sx={{ borderBottom: "1px solid  #C2C2C2" }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant={"h6"}>Contraseña de un solo uso (OTP)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <BoxContainer>
                  <BoxTitulo>
                    <Typography variant="subtitle1">
                      Identificador:
                    </Typography>
                  </BoxTitulo>
                  <BoxTexto>
                    <Typography variant="subtitle1">
                      {data?.OTP?.Id}
                    </Typography>
                  </BoxTexto>
                </BoxContainer>
                <BoxContainer>
                  <BoxTitulo>
                    <Typography variant="subtitle1">
                      Fecha UTC:
                    </Typography>
                  </BoxTitulo>
                  <BoxTexto>
                    <Typography variant="subtitle1">
                      {moment.utc(data?.OTP?.validatedAtUTC).format("DD-MM-YYYY HH:mm:ss")}
                    </Typography>
                  </BoxTexto>
                </BoxContainer>
                <BoxContainer>
                  <BoxTitulo>
                    <Typography variant="subtitle1">
                      Fecha local:
                    </Typography>
                  </BoxTitulo>
                  <BoxTexto>
                    <Typography variant="subtitle1">
                      {moment.utc(data?.OTP?.validatedAt).format("DD-MM-YYYY HH:mm:ss")}
                    </Typography>
                  </BoxTexto>
                </BoxContainer>
                <BoxContainer>
                  <BoxTitulo>
                    <Typography variant="subtitle1">
                      Latitud:
                    </Typography>
                  </BoxTitulo>
                  <BoxTexto>
                    <Typography variant="subtitle1">
                      {data?.OTP?.Latitud ? data?.OTP?.Latitud : <b>Sin detección</b>}
                    </Typography>
                  </BoxTexto>
                </BoxContainer>
                <BoxContainer>
                  <BoxTitulo>
                    <Typography variant="subtitle1">
                      Longitud:
                    </Typography>
                  </BoxTitulo>
                  <BoxTexto>
                    <Typography variant="subtitle1">
                      {data?.OTP?.Longitud ? data?.OTP?.Longitud : <b>Sin detección</b>}
                    </Typography>
                  </BoxTexto>
                </BoxContainer>
              </AccordionDetails>
            </Accordion>
          ) : null}
          {data.EvidenceVideo ? (
            <Accordion>
              <AccordionSummary
                sx={{ borderBottom: "1px solid  #C2C2C2" }}
                expandIcon={<ExpandMoreIcon />}
                onClick={() => verVideo()}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant={"h6"}>Videograbación</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <BoxContainer>
                  <BoxTitulo>
                    <Typography variant="subtitle1">
                      Leyenda de consentimiento:
                    </Typography>
                  </BoxTitulo>
                  <BoxTexto>
                    <Typography variant="subtitle1">
                      <CheckCircleRoundedIcon
                        sx={{ color: "green", fontSize: 18 }}
                      />
                    </Typography>
                  </BoxTexto>
                </BoxContainer>
                <BoxContainer>
                  <BoxTitulo>
                    <Typography variant="subtitle1">
                      Fecha de grabación:
                    </Typography>
                  </BoxTitulo>
                  <BoxTexto>
                    <Typography variant="subtitle1">
                      {moment(data?.EvidenceVideo?.timestamp).format('DD-MM-YYYY HH:mm:ss')}
                      {/* {UTCtoLocal(data?.EvidenceVideo?.timestamp, true)} */}
                    </Typography>
                  </BoxTexto>
                </BoxContainer>
                {b64Video ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    <video controls height={250} width="80%">
                      <source
                        type="video/webm"
                        src={`data:video/webm;base64,${b64Video}`}
                      />
                      <source
                        type="video/mp4"
                        src={`data:video/mp4;base64,${b64Video}`}
                      />
                    </video>
                  </Box>
                ) : null}
              </AccordionDetails>
            </Accordion>
          ) : null}
        </Box>
      </Box>
    </Fragment>
  );
};
