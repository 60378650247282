import React from "react";
import { Divider as Dv } from "@mui/material";

export const Divider = () => {
  return (
    <Dv
      sx={{
        mt: 5,
        mb: 5,
        borderBottomColor: ({ palette }) => palette.secondary.main60,
      }}
    />
  );
};
