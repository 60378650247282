import React, { useEffect, useState } from "react";
import {
  Tooltip,
  tooltipClasses,
  styled,
  TextField,
  Box,
  autocompleteClasses,
} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Controller } from "react-hook-form";
import { IMaskInput } from "react-imask";

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
  [`& .${autocompleteClasses.noOptions}`]: {
    backgroundColor: "red",
  },
}));

const InputText = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    height: 25,
    padding: "10px 15px",
    [theme.breakpoints.down("sm")]: {
      height: 18,
    padding: "8px 10px",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 12,
    fontSize: 14,
    "& fieldset legend span": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {     
      borderRadius: 2,
    },
  },
  "& .MuiInputLabel-root": {
    color: "#000",
    position: "relative",
    transform: "none",
    fontSize: 14,
    marginBottom: 5,
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    pointerEvents: "auto",
    [theme.breakpoints.down("sm")]: {
      fontWeight: 400,
      fontSize: '9px',
      lineHeight: '144%',
    },
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
    letterSpacing: '15px !important',
    fontSize: '23px !important',

  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-input": {
    letterSpacing: '15px !important',
    fontSize: '23px !important',
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
    letterSpacing: '15px !important',
    fontSize: '23px !important',
  },
  "& label.Mui-focused": {
    color: theme.palette.primary.main,
  },
  "& label.Mui-error": {
    color: "#FF4842 !important",
  },
  "& .MuiSelect-select": {
    height: 25,
    "& .notranslate": {
      display: "block",
      margin: "2.45px 0",
    },
  },
  "& .MuiInputBase-multiline": {
    padding: "0",
  },
}));

export const InputCustomField = (props) => {
  const {
    inputType,
    defaultValue,
    value,
    label,
    multiline,
    sxLabel,
    sxInput,
    tooltipText,
    control,
    name,
    validations,
    disabled,
    error,
  } = props;

  const getInputType = () => {
    switch (inputType) {
      case "PIN":
        return (
          <Controller
          control={control}
          name={name}
          // defaultValue=""
          // rules={validations}
          render={({ field: { ref, ...restFields } }) => (
            <InputText
              {...restFields}
              fullWidth
              error={error}
              inputRef={ref}
              disabled={disabled}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {label}
                  {tooltipText && (
                    <Tip title={tooltipText} placement="top" arrow>
                      <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                    </Tip>
                  )}
                </Box>
              }
              InputProps={{ inputComponent: TextMaskCustom }}
              InputLabelProps={{ sx: sxLabel }}
              sx={{ ...(sxInput ?? {}) }}
            />
          )}
        />
        );
    }
  };

  return getInputType();
};

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  return (
    <IMaskInput
      {...props}
      mask='000000' 
      // mask="0  0  0  0"
      // definitions={{
      //   "#": { validator: "[0-9]-[0-9]-[0-9]-[0-9]" }
      // }}
      inputRef={ref}
      overwrite
    />
  );
});
