import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackdropComponent from "../../components/BackDrop";
import ExpedientFlow from "./ExpedientFlow";
import SignatureDocument from "./FirmaDocumento/SignatureDocument";
import DocumentVersions from "./DocumentVersions/DocumentVersions";
import RevisarDocumento from "./RevisarDocumento/RevisarDocumento";
import { getFile } from "../../store/actions";
import { useLocation, useNavigate } from "react-router-dom";

export default function FlowViews(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const ruta = location.pathname.includes("/inbox/file-information-search");
  const [value, setValue] = useState(0);
  const stateFile = useSelector((state) => state.File);
  const stateFileFlow = useSelector((state) => state.FileFlow);

  const stageFileFlow = useSelector(
    (state) => state.File?.item?.FileData?.Stage?.FlowState
  );

  useEffect(() => {
    if (stateFileFlow.loading) {
      dispatch(getFile({ FileId: stateFile?.item?.FileData?.Id }));
    }
    stageFirmas();
  }, [stateFileFlow.loading]);

  const stageFirmas = () => {
    switch (stageFileFlow) {
      case "Expediente por firmar":
        return false;
      case "Notificacion De Asignacion de Firma":
        return false;
      case "Asignando solicitud de firma":
        return false;
      case "OTP Evidencias firma de documentos":
        return false;
      case "Evidencias firma de documentos":
        return false;
      case "Firma de documentos":
        return false;
      case "Validación de evidencias":
        return false;
      case "Finalizado":
        return ruta ? true : navigate("/inbox/files");
      case "FinalizacionXml":
        return ruta ? true : navigate("/inbox/files");
      case "Carga a Docflow":
        return ruta ? true : navigate("/inbox/files");
      default:
        break;
    }
  };


  useEffect(() => {
    if (sessionStorage.getItem('OTP')) {
      setValue(2);
    }
  }, [])

  console.log('valuevalue', value)

  return (
    <Fragment>
      <BackdropComponent loading={stateFile.loading} />
      {value === 0 ? (
        <ExpedientFlow setValue={setValue} isActiveMassiveSignature={props.isActiveMassiveSignature} />
      ) : value === 1 ? (
        <RevisarDocumento setValue={setValue} />
      ) : value === 2 ? (
        <SignatureDocument setValue={setValue} isActiveMassiveSignature={props.isActiveMassiveSignature} />
      ) : (
        <DocumentVersions setValue={setValue} />
      )}
    </Fragment>
  );
}


