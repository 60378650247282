import {
  Button,
  InputAdornment,
  List,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getComent, setComments } from "../../../store/actions";

export default function Comentarios(props) {
  const dispatch = useDispatch();
  const { register, handleSubmit, resetField } = useForm();
  const document = useSelector((state) => state.File);

  const getComments = () => {
    dispatch(getComent({ Body: document.document?.DocumentData?.Id }));
  };

  useEffect(() => {
    getComments();
  }, []);

  useEffect(() => {
    getComments();
  }, [document.document?.DocumentData?.Id]);

  const handleService = async (formData) => {
    const { comentario } = formData;
    const data = {
      Body: {
        comentario: comentario,
        Document_Id: document.document?.DocumentData?.Id,
      },
    };
    dispatch(setComments(data));
    getComments();
    resetField("comentario");
  };

  const ComentarioComponent = ({ data }) => (
    <Box sx={{ ml: 5, mt: 1 }}>
      <div
        style={{
          whiteSpace: "nowrap",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography variant="body2" sx={{ mr: 1, fontWeight: 450 }}>
            {data.NombreUsuario}
          </Typography>
          <Box
            component="div"
            sx={{
              whiteSpace: "normal",
            }}
          >
            <Typography variant="body2">{data.Comentario}</Typography>
          </Box>
        </Box>
      </div>
      <Box sx={{ mt: 0.5 }}>
        <Typography color="secondary.main80" variant="caption">
          {data.FechaHoraComentario}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Fragment>
      <Box sx={{ mt: 1 }}>
        <Typography variant="h5" sx={{ fontWeight: 450 }}>
          Comentarios
        </Typography>
      </Box>
      <Box overflow={"auto"} sx={{ maxHeight: 200 }}>
        <List>
          {props.comment === true ? (
              document?.coments_doc?.map((comment) => (
                <ComentarioComponent data={comment} />
              ))
          ):(
              props?.data?.map((comment) => (
                <ComentarioComponent data={comment} />
              ))
          )}
        </List>
      </Box>
      {props.comment === true ? (
        <form onSubmit={handleSubmit(handleService)}>
          <Box sx={{ ml: 3, mt: 1 }}>
            <OutlinedInput
              fullWidth
              size="small"
              sx={{
                minWidth: 300,
              }}
              placeholder="Añade un comentario"
              {...register("comentario", { required: true })}
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    type="submit"
                    onClick={handleSubmit(handleService)}
                    sx={{ color: "secondary.main60" }}
                  >
                    Publicar
                  </Button>
                </InputAdornment>
              }
            />
          </Box>
        </form>
      ) : null}
    </Fragment>
  );
}
