import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({//Estílos solamente del componente "LoginComponent"
    formInput:{
      width: "100%",
      padding: 1
    },
    containerImage:{
      height: 250
    },
    imagen:{
      height: '100%'
    },
}));

export default useStyles;
