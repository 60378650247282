import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    "&.MuiDialogTitle-root": {
        fontSize: 20,
        color: theme.palette.primary.text,
        fontWeight: 300,
        lineHeight: '130%',
    },
    display: "inline-flex",
    alignItems: "center",
}));

export const CustomModalDialog = (props) => {
    return (
        <CustomDialog
            onClose={props.OnClose}
            aria-labelledby="customized-dialog-title"
            open={props.Open}
            fullWidth={true}
            maxWidth={"md"}
        >
            <StyledDialogTitle sx={{ m: 0, p: 2, color: "" }} >
                {props.Title}
                {props.OnClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={props.OnClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </StyledDialogTitle>
            <DialogContent>
                {props.Body}
            </DialogContent>
            {props.Buttons ?
                <DialogActions sx={{ textAling: 'center !important' }}>
                    {props.Buttons}
                </DialogActions>
                : null}
        </CustomDialog>
    );
}
