import { Button, Box, styled, Typography, useTheme } from "@mui/material";
import iconDocument from "../../assets/icons/Documento.png";

const DocButton = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  borderBottom: "1px solid #EEE",
  borderRadius: 0,
}));

const DocButtonLoaded = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  borderBottom: "1px solid #EEE",
  borderRadius: 0,
  padding: "6px 8px",
}));

export const DocumentButtonPrevious = (props) => {

  return (
    <DocButtonLoaded
      style={props.style}
      fullWidth
      sx={{ color: "#000" }}
      component={Box}
    >
      <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <img
            style={{ width: 13, marginRight: 5 }}
            alt="imagenLogo"
            src={iconDocument}
          />
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontSize: props?.preview ? "12px !important" : "14px !important",
            }}
          >
            Documento -{" "} {props.titulo}
           
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Typography
              variant="body2"
              component="span"
              sx={{
                fontSize: "11px !important",
              }}
            >
              Doc. Previo
            </Typography>
          </Box>
        </Box>
      </Box>
    </DocButtonLoaded>
  );
};
