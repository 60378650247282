import { fileFlowConstants } from "../constants";

const initialState = {
  loading: false,
  error: null,
  file_flow: null,
};

export const FileFlow = (state = initialState, action) => {
  switch (action.type) {
    case fileFlowConstants.FILE_FLOW_REQUEST:
      return {
        ...state,
        loading: true,
        file_flow: null,
        error: null,
      };
    case fileFlowConstants.RESET_FLOW_REQUEST:
      return {
        ...state,
        loading: false,
        file_flow: null,
        error: null,
      };
    case fileFlowConstants.FILE_FLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        file_flow: action.payload,
        error: null,
      };
    case fileFlowConstants.FILE_FLOW_FAILURE:
      return {
        ...state,
        loading: false,
        file_flow: null,
        error: action.payload,
      };
    case fileFlowConstants.SET_FILE_FLOW:
      return {
        ...state,
        file_flow: action.payload,
      };
    default:
      return state;
  }
};


export const UploadVideoUser = (state = initialState, action) => {
  switch (action.type) {
    case fileFlowConstants.UPLOAD_VIDEO_REQUEST:
      return {
        ...state,
        loading: true,
        file_flow: null,
        error: null,
      };
    case fileFlowConstants.UPLOAD_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        file_flow: action.payload,
        error: null,
      };
    case fileFlowConstants.UPLOAD_VIDEO_FAILURE:
      return {
        ...state,
        loading: false,
        file_flow: null,
        error: action.payload,
      };
    case fileFlowConstants.UPLOAD_VIDEO_RESET:
      return {
        loading: false,
        file_flow: null,
        error: null,
      };
    default:
      return state;
  }
};
