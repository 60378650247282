export const expedienteConstants = {
  FILE_REQUEST: "FILE_REQUEST",
  FILE_SUCCESS: "FILE_SUCCESS",
  FILE_FAILURE: "FILE_FAILURE",
  SET_FILE: "SET_FILE",
  RESET_FILE: "RESET_FILE",

  SET_DOCUMENT: "SET_DOCUMENT",
  SET_DOCUMENT_REQUEST: "SET_DOCUMENT_REQUEST",
  RESET_DOCUMENT: "RESET_DOCUMENT",

  RECORDS_REQUEST: "RECORDS_REQUEST",
  RECORDS_RESET: "RECORDS_RESET",
  RECORDS_SUCCESS: "RECORDS_SUCCESS",
  RECORDS_FAILURE: "RECORDS_FAILURE",
  SET_RECORDS: "SET_RECORDS",

  COMENTS_REQUEST: "COMENTS_REQUEST",
  COMENTS_SUCCESS: "COMENTS_SUCCESS",
  COMENTS_FAILURE: "COMENTS_FAILURE",

  SET_COMENTS: "SET_COMENTS",
  SET_COMENTS_FAILURE: "SET_COMENTS_FAILURE",
  SET_COMENTS_REQUEST: "SET_COMENTS_REQUEST",
  RESET_COMENTS: "RESET_COMENTS",

  SET_DOCUMENT64: "SET_DOCUMENT64",
  SET_DOCUMENT64_FAILURE: "SET_DOCUMENT64_FAILURE",
  SET_DOCUMENT64_REQUEST: "SET_DOCUMENT64_REQUEST",
  RESET_DOCUMENT64: "RESET_DOCUMENT64",

  GET_DOCUMENT_VERSIONS_SUCCESS: 'GET_DOCUMENT_VERSIONS_SUCCESS',
  GET_DOCUMENT_VERSIONS_FAILTURE: 'GET_DOCUMENT_VERSIONS_FAILTURE',
  GET_DOCUMENT_VERSIONS_REQUEST: 'GET_DOCUMENT_VERSIONS_REQUEST',

  GET_URL_DOCUMENT: 'GET_URL_DOCUMENT',
  REQUEST_URL_DOCUMENT: 'REQUEST_URL_DOCUMENT',
  RESET_URL_DOCUMENT: "RESET_URL_DOCUMENT",
  RESET_URL_DOCUMENT_ENLACE: "RESET_URL_DOCUMENT_ENLACE",

  LOADING_SUCCESS: 'LOADING_SUCCESS',
  LOADING_FEATURE: 'LOADING_FEATURE'
};
