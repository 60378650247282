import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    controlButtons: {
        margin: '3rem 0',
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    btnGRB: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    btn: {
        fontFamily: 'Verdana, Geneva, Tahoma, sans-serif',
        width: '10vw',
        height: '5vh',
        borderRadius: '14px',
        margin: '0px 6px',
        display: 'flex',
        border: '2px solid #e42a2a',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: '#f5f5f5',
    },
    btnOne: {
        backgroundColor: '#e42a2a',
    }
}));

export default function ControlButtons(props) {
    const classes = useStyles();

    const StartButton = (
        <div className={`${classes.btn} ${classes.btnOne}`}
            onClick={props.handleStart}>
            Start
        </div>
    );
    const ActiveButtons = (
        <div className="btn-grp">
            <div className={`${classes.btn}`}
                onClick={props.handleReset}>
                Reset
            </div>
            <div className={`${classes.btn} ${classes.btnOne}`}
                onClick={props.handlePauseResume}>
                {props.isPaused ? "Resume" : "Pause"}
            </div>
        </div>
    );

    return (
        <div className={classes.controlButtons}>
            <div>{props.active ? ActiveButtons : StartButton}</div>
        </div>
    );
}