import React from "react";
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  Typography,
  styled,
} from "@mui/material";
import { Icon } from "@iconify/react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetFile } from "../store/actions";

const DrawerStyled = styled(Box)(({ theme }) => ({
  width: 290,
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const menuItems = [
  {
    name: "Inicio",
    icon: <Icon icon="mdi-light:home" fontSize={22} />,
    link: "/inbox/dashboards",
  },
  {
    name: "Bandeja",
    icon: <Icon icon="ion:file-tray-full-outline" fontSize={20} />,
    link: "files",
  },
  {
    name: "Nuevo",
    icon: <Icon icon="carbon:new-tab" fontSize={20} />,
    link: "new-file",
  },
  {
    name: "Buscar",
    icon: <Icon icon="akar-icons:search" fontSize={20} />,
    link: "search",
  },
  {
    name: "Verificar documento",
    icon: <Icon icon="ic:outline-security" fontSize={20} />,
    link: "/inbox/verify-xml",
  },
  {
    name: "Usuarios",
    icon: <Icon icon="ph:users-light"  fontSize={23} />,
    link: "/inbox/users",
  },
];

const menuSecondItems = [
  {
    text: "Editar Perfil",
    icon: <AccountCircleOutlinedIcon />,
    link: `/inbox/profileUser?tab=${0}`,
  },
  {
    text: "Cambiar contraseña",
    icon: <Icon icon="material-symbols:password-rounded" fontSize={20} />,
    link: `/inbox/profileUser?tab=${1}`,
  },
];

export const Drawer = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateRedux = useSelector((state) => state);
  const newFile = useSelector((state) => state.NewFile?.editStack);

  let optionsHeader =
    stateRedux?.Authentication?.items?.UserLoginData?.SecurityLoginData
      ?.Applications[0]?.Modules[0]?.Functions;

  const cerrarSesion = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/auth/login");
    window.location.reload();
  };

  const closeDrawer = (item) => {
    navigate(item.link);
    props.onClose();
    if (item.text === "Nuevo") {
      dispatch(resetFile());
    }
  };

  return (
    <MuiDrawer open={props.open} onClose={props.onClose}>
      <DrawerStyled>
        <Box sx={{ p: "30px 20px 5px 20px" }}>
          <Box sx={{ pl: 1, textAlign: "center" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Avatar
                sx={{
                  color: "primary.mainLyrics",
                  backgroundColor: "primary.main",
                  fontWeight: 600,
                  width: 100,
                  height: 100,
                  fontSize: 40,
                  mt: 0.7,
                }}
                alt={sessionStorage.userName}
                src="/static/images/avatar/2.jpg"
              />
            </Box>
            <Typography variant="subtitle1" sx={{ mt: 3 }} color="primary">
              {sessionStorage.userName}
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              {sessionStorage.Rol}
            </Typography>
          </Box>
          <List>
            {optionsHeader?.map((item) =>
              menuItems.map((menuItems) =>
                item.Name === menuItems.name ? (
                  <ListItem
                    button
                    key={menuItems.text}
                    onClick={() => closeDrawer(menuItems)}
                  >
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      {menuItems.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{ color: "secondary.main" }}
                      primary={
                        menuItems.name === "Nuevo" && newFile === true
                          ? "Borrador"
                          : menuItems.name
                      }
                    />
                  </ListItem>
                ) : null
              )
            )}
          </List>
        </Box>

        <Divider />

        <Box sx={{ p: "5px 20px 5px 20px" }}>
          <List>
            {menuSecondItems.map((item) => (
              <ListItem
                button
                key={item.text}
                onClick={() => {
                  navigate(item.link);
                  props.onClose();
                }}
              >
                {item.icon ? (
                  <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
                ) : null}
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Box>

        {/* <Divider /> */}

        {/* <Box sx={{ p: "5px 0 5px 20px" }}>
          <List>
            <ListItem>
              <ListItemIcon sx={{ minWidth: 40 }}>
                <WifiOff fontSize="small" sx={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText primary="Modo offline" sx={{ flexGrow: 1 }} />
              <Switch />
            </ListItem>
          </List>
        </Box> */}

        <Box sx={{ p: "5px 20px 5px 20px", mt: "auto" }}>
          <List>
            <ListItem button onClick={() => cerrarSesion()}>
              <ListItemIcon sx={{ minWidth: 40 }}>
                <Logout fontSize="small" sx={{ color: "primary.main" }} />
              </ListItemIcon>
              <ListItemText
                sx={{ color: "secondary.main" }}
                primary="Cerrar sesión"
              />
            </ListItem>
          </List>
        </Box>
      </DrawerStyled>
    </MuiDrawer>
  );
};
