import {
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDocument } from "../../store/actions";

export default function SupplementaryDocument({ document }) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.Authentication?.items?.UserLoginData);
  const statusAdmin =
    userData?.Username === document?.item?.FileData?.CreationUser;

  const selectedDocument = (item) => {
    dispatch(setDocument(item));
  };

  const renderOnlyViewList = () => {
    const OnlyViewDocs = document.item?.Documents?.filter(
      (doc) => doc.OnlyView === true
    );

    if (OnlyViewDocs?.length === 0) {
      return null;
    };
    
    return (
      <ButtonList
        statusAdmin={statusAdmin}
        docuemnts={OnlyViewDocs}
        selectedDocument={selectedDocument}
      />
    );
  };

  return (
    <Fragment>
      <Box sx={{ mt: 1 }}>
        {renderOnlyViewList() ? (
          <>
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              Documentos Complementarios
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 300 }}>
              (Solo consulta)
            </Typography>
          </>
        ) : null}
      </Box>
      <Box overflow={"auto"} sx={{ maxWidth: '80%', maxHeight: 250 }}>
        <List>
          {renderOnlyViewList()}
        </List>
      </Box>
    </Fragment>
  );
};

const ButtonList = (props) => {
  const active = (item) => {
    return props?.documentSeleted?.DocumentTypeId === item?.DocumentTypeId;
  };

  return (
    <>
      {props.docuemnts?.map((item, index) => (
        <ListItemDocument
          item={item}
          active={active}
          index={index}
          documents={props.docuemnts}
          selectedDocument={props.selectedDocument}
        />
      ))}
    </>
  );
};

const ListItemDocument = (props) => {

  return (
    <ListItem
      button
      onClick={() => props.selectedDocument(props.item)}
      sx={(theme) => ({
        border: "1px solid",
        width: "100%",
        borderColor: '#F6F6F6',
      })}
    >
      <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
        <Box sx={{ flexGrow: 8, ml: 1 }}>
          <Typography
            align="left"
            sx={(theme) => ({
              fontSize: "12px !important",
              color: '#000',
            })}
          >
            Documento
          </Typography>
          <Typography
            align="left"
            sx={(theme) => ({
              fontSize: "12px !important",
              color: '#000',
            })}
          >
            {props.item.FileName}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            ml: 1,
          }}
        >
          <ArrowForwardIosIcon
            sx={(theme) => ({
              fontSize: 25,
              color: theme.palette.secondary.main80,
            })}
          />
        </Box>
      </Box>
    </ListItem>
  )
}
