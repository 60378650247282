import { userConstants } from "../constants";

const initialState = {
  loading: false,
  error: null,
  items: null,
};

export const UsersData = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.GET_USERS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        items: null,
        error: null,
      };

    case userConstants.GET_USERS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case userConstants.GET_USERS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        items: null,
        error: action.payload,
      };

    default:
      return state;
  }
};