import React from "react";
import { styled, Tooltip, tooltipClasses } from "@mui/material";

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
}));

export const WhiteTooltip = (props) => {
  return (
    <Tip
      arrow
      title={props.title}
      placement={props.placement ?? "top"}
      open={props.open}
      onClose={props.handleTooltipClose}
    >
      {props.children}
    </Tip>
  );
};
