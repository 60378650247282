import React from 'react'
import { Dialog} from "@mui/material"

export const CustomAlertHelpDialog = props => {
    return (
        <Dialog fullScreen onClose={props.closeAction}
            open={props.open} >
            <div style={{ width: '100% !important', height: '100vh' }}>
                {props.body}
            </div>
        </Dialog >
    )
}