import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion as Acc,
  AccordionSummary as AccSum,
  AccordionDetails,
  Button,
  Box,
  styled,
  Grid,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { useScrollToTop } from "../../hooks/useScrollToTop";
import { useFormContext } from "react-hook-form";
import { InputField } from "../../components/InputField";
import { TypoTooltip } from "../../components/TypoTooltip";
import { FileService } from "../../services/FileService";
import { ExpandMore } from "@mui/icons-material";
import { ToastNotification } from "../../components/toastNotification";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "../../components/Alert";
import {
  setlistSigners,
  setlistCheckers,
  setlistViewers,
  getFile,
  setlistCapturet,
} from "../../store/actions";
import { ParticipantDraggableTable } from "./ParticipantDraggableTable";
import videoTutorial from "../../assets/Aprobador_firmantes_masivo.mp4";
import { emailPattern } from "../../utils";
import { FileFlowService } from "../../services/FileFlowService";
import { ParticipantDraggableTableRevisor } from "./ParticipantDraggableTableRevisores";
import ParticipantListResponsive from "./ParticipantListResponsive";
import { ParticipantDraggableTableResponsive } from "./ParticipantDraggableTableResponsive";
import HelpPages from "../../components/HelpPages";
import { TemplatesServices } from "../../services/TemplatesServices";
import BackdropComponent from "../../components/BackDrop";

const RootStyle = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  width: "100%",
  maxWidth: "95%",
  transition: "height 500ms ease",
}));

const Accordion = styled(Acc)(({ theme }) => ({
  "&.MuiAccordion-root": {
    boxShadow: "none !important",
  },
  "&::before": {
    display: "none",
  },
}));

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: "transparent",
    color: "#fff",
  },
}));

const AccordionSummary = styled(AccSum)(({ theme }) => ({
  borderBottom: "1px solid red",
  borderBottomColor: theme.palette.secondary.main40,
}));

export const ConfigFileTab = (props) => {
  const { control, handleSubmit, watch, setValue, formState, resetField } =
    useFormContext();

  const folioIdSolicitud = sessionStorage.getItem("FileId");
  const environmentCtrlSigned = useSelector(
    (state) =>
      state.Authentication?.items?.UserLoginData?.EnviromentVars
        ?.IsActiveSignatureZones
  );
  const fileAuthentication = useSelector(
    (state) => state.Authentication?.items?.UserLoginData?.EnviromentVars
  );

  const [searchUser, setsearchUser] = useState();
  const [borrado, setBorrado] = useState("");
  const [loadingParticipant, setloadingParticipant] = useState(false);
  const [loading, setloading] = useState(false);
  const [results, setresults] = useState([]);
  const [open, setOpen] = useState(false);
  const [templateActiveData, setTemplateActiveData] = useState(false);
  const [reloadSearchRole, setReloadSearchRole] = useState(false);
  const [rolesUserData, setRolesUserData] = useState([]);
  const [setseletedUserRol, setSetseletedUserRol] = useState([]);
  const [disabledParticipantCapture, setDisabledParticipantCapture] =
    useState(false);

  const watchParticipantCheck = watch("participantCheck");
  const watchParticipantSign = watch("participantSign");
  const watchParticipantCaptureData = watch("participantCaptureData");
  const watchSign = watch("signatureType");
  const watchParticipantUser = watch("searchParticipantUsers");
  const watchlimitDateCheck = watch("limitDateCheckParticipant");
  const watchlimitDateSign = watch("limitDateSignParticipant");
  const watchlimitDateCapture = watch("limitDateCapturearticipant");
  const watchTypeUserSelect = watch("typeUserSelect");
  const watchRolSeletedUser = watch("rolSeletedUser");
  const watchOtp = watch("OTP");

  const newFileState = useSelector((state) => state.NewFile);
  const fileState = useSelector((state) => state.File);
  const dispatch = useDispatch();
  const listSigners = useSelector((state) => state.NewFile.participants.sign);
  const listCheckers = useSelector((state) => state.NewFile.participants.check);
  const listViewers = useSelector((state) => state.NewFile.participants.view);
  const listCaptures = useSelector(
    (state) => state.NewFile.participants.capture
  );

  console.log("watchParticipantCaptureData", watchRolSeletedUser);

  const checkDocuments = newFileState?.documentsStack?.filter(
    (doc) => doc?.DocumentConfiguration?.Check === true
  );

  const checkDocumentsEdit = fileState?.item?.Documents?.filter(
    (doc) => doc?.Check === true
  );

  const signToParticipant = useSelector(
    (state) =>
      state.NewFile?.file?.fileForm?.FileConfiguration?.SignatureOptions
        ?.SignatureType
  );
  const signToParticipantForEdit = useSelector(
    (state) => state.File?.item?.SignatureOptions?.SignatureType
  );

  const fechaLimPrerre =
    newFileState?.dataPrerrequesitos?.PrerequisitesConfiguration
      ?.UploadPrerequisitesDeadline;

  const fechaLimPrerreEdit =
    fileState?.item?.Prerequisites?.UploadPrerequisitesDeadline;

  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down("sm"));
  useScrollToTop();

  useEffect(() => {
    getRolesUserTemplate();
  }, [fileState.item]);

  const getRolesUserTemplate = async () => {
    try {
      const templateServices = TemplatesServices.getInstance();
      const response = await templateServices.GetRolesByFile(folioIdSolicitud);
      console.log("responseRoles", response);
      if (response.Body?.m_Item2 === true) {
        if (response.Body.m_Item1.length == 0) {
          setloading(false);
          setTemplateActiveData(false);
        } else {
          const rolesUnicosArray = Array.from(response.Body.m_Item1);
          console.log("rolesUnicosArray", rolesUnicosArray, rolesUserData);
          const newArrayDeRoles = rolesUnicosArray.map((role, index) => ({
            Name: role.RoleName,
            Id: role.RoleName,
            Capture: role.Captute,
          }));
          setRolesUserData(newArrayDeRoles);

          setTemplateActiveData(true);
          setloading(false);
          setReloadSearchRole(false);
        }
      } else {
        setReloadSearchRole(true);
        setRolesUserData([]);
      }
    } catch (error) {
      setloading(false);
      console.log("error", error);
    }
  };

  console.log("RolesUserData", rolesUserData);

  useEffect(() => {
    if (rolesUserData.length == 0) {
      console.log("responseRoles ENTRO", rolesUserData);
      getRolesUserTemplate();
    } else {
      setloading(false);
      setReloadSearchRole(false);
    }
  }, [templateActiveData, reloadSearchRole, rolesUserData]);

  const getParticipantFromApi = async (search = "") => {
    const fileService = FileService.getInstance();
    setloadingParticipant(true);
    try {
      const response = await fileService.getParticipants({
        SearchText: search,
      });
      setresults(
        response.Body.map(({ ...rest }) => ({
          name: rest.Email,
          ...rest,
        }))
      );
      setloadingParticipant(false);
    } catch (err) {
      console.log(err);
      setloadingParticipant(false);
    } finally {
      setloadingParticipant(false);
    }
  };

  useEffect(() => {
    if (newFileState?.editStack) {
      if (fileState.item) {
        const newStackSign = fileState?.item?.Participants.filter(
          (use) => use.Sign === true
        );
        dispatch(setlistSigners(newStackSign));

        const newStackCapture = fileState?.item?.Participants.filter(
          (use) => use.Capture === true
        );
        dispatch(setlistCapturet(newStackCapture));

        const newStackCheck = fileState?.item?.Participants.filter(
          (use) => use.Check === true || use.Reviewer === true
        );
        dispatch(setlistCheckers(newStackCheck));
        setValue(
          "limitDateCheckParticipant",
          newStackCheck.length > 0 ? newStackCheck[0].CheckDeadline : ""
        );

        const newStackView = fileState?.item?.Participants.filter(
          (use) => use.OnlyView === true
        );
        dispatch(setlistViewers(newStackView));
      }
    } else {
      setValue("limitDateCheckParticipant", "");
    }
  }, [fileState?.item]);

  const handleAddParticipant = async (data) => {
    const fileService = FileService.getInstance();
    let newUser;

    var isReviwer = watchTypeUserSelect === "userApproving" ? true : false;
    var existReviwerCheck = listCheckers?.filter(
      (item) => item.Reviewer === true
    );

    if (isReviwer && existReviwerCheck?.length > 0) {
      ToastNotification({
        icon: "warning",
        text: "Ya existe un usuario aprobador.",
      });
      return;
    }

    const fechaCapt = new Date(watchlimitDateCapture);
    const fechaSign = new Date(watchlimitDateSign);

    if (watchParticipantUser) {
      if (
        !watchParticipantSign &&
        !watchParticipantCheck &&
        !watchParticipantCaptureData
      ) {
        ToastNotification({
          icon: "warning",
          text: "Debes seleccionar al menos una acción para el participante.",
        });
        return;
      }
      setloading(true);
      if (!watchParticipantUser.Id) {
        try {
          const response = await fileService.createParticipant({
            Email: watchParticipantUser.name,
            UserAlias: data.participantName,
            Name: "",
            FileIdReference: newFileState.editStack
              ? fileState.item?.FileData?.Id
              : newFileState.file.Id,
          });
          newUser = response.Body;
          setValue("watchParticipantUser", newUser);
          setloading(false);
        } catch (error) {
          setloading(false);
          return ToastNotification({
            icon: "warning",
            text: error.message,
          });
        }
      }
      try {
        if (watchParticipantCheck) {
          const duplicated = listCheckers.find(
            (el) => el.UserId === watchParticipantUser.Id
          );
          if (duplicated) {
            setloading(false);
            return ToastNotification({
              icon: "warning",
              text: "El participante ya es editor.",
            });
          }
          console.log("listCheckers count: ", listCheckers?.length);
          var participant = null;
          if (isReviwer) {
            participant = {
              UserEmail: watchParticipantUser.name,
              FullName: data.participantName,
              CheckDeadline: watchlimitDateCheck,
              UserId: watchParticipantUser.Id ?? newUser.UserId,
              CheckOrder: null,
              Check: null,
              Reviewer: isReviwer,
            };
          } else {
            participant = {
              UserEmail: watchParticipantUser.name,
              FullName: data.participantName,
              CheckDeadline: watchlimitDateCheck,
              UserId: watchParticipantUser.Id ?? newUser.UserId,
              CheckOrder: listCheckers?.length + 1,
              Check: true,
              Reviewer: false,
            };
          }
          console.log("participant Checkers count: ", participant);
          try {
            const response = await fileService.registerParticipantInFile({
              FileId: newFileState.editStack
                ? fileState.item?.FileData?.Id
                : newFileState.file.Id,
              Participant: participant,
            });
            setBorrado("");
            !duplicated &&
              dispatch(setlistCheckers([...listCheckers, participant]));
            setloading(false);
          } catch (err) {
            console.log(err);
            Alert({
              icon: "error",
              title: "Hubo un error, inténtalo de nuevo" + err,
            });
          } finally {
            setValue("searchParticipantUsers", null);
            resetField("searchParticipantUsers");
            setresults([]);
            setsearchUser("");
            setBorrado("");
            setloading(false);
            resetForm();
          }
        }

        if (watchParticipantCaptureData) {
          if (
            watchlimitDateSign !== null &&
            watchlimitDateSign !== undefined &&
            watchlimitDateSign !== "" &&
            fechaCapt > fechaSign
          ) {
            return ToastNotification({
              icon: "warning",
              text: "La fecha de captura no puede ser mayor a la fecha de firma.",
            });
          }

          if (
            watchRolSeletedUser === null ||
            watchRolSeletedUser === undefined
          ) {
            return ToastNotification({
              icon: "warning",
              text: "Por favor selecciona un rol para el usuario.",
            });
          }
          const duplicated = listCaptures?.find(
            (el) => el.Role === watchRolSeletedUser
          );

          if (duplicated) {
            setloading(false);
            return ToastNotification({
              icon: "warning",
              text: "El rol ya lo tiene asignado un usuario.",
            });
          }

          const duplicatedUser = listCaptures?.find(
            (el) => el.UserId === watchParticipantUser.Id
          );

          if (duplicatedUser) {
            setloading(false);
            return ToastNotification({
              icon: "warning",
              text: "El participante ya es capturista.",
            });
          }

          const participant = {
            UserEmail: watchParticipantUser.name,
            FullName: data.participantName,
            CaptureDeadline: watchlimitDateCapture,
            UserId: watchParticipantUser.Id ?? newUser.UserId,
            Capture: true,
            Role: watchRolSeletedUser,
            CaptureOrder:
              listCaptures !== null && listCaptures !== undefined
                ? listCaptures.length + 1
                : 1,
          };

          console.log("participantCapture", participant);

          try {
            const response = await fileService.registerParticipantInFile({
              FileId: newFileState.editStack
                ? fileState.item?.FileData?.Id
                : newFileState.file.Id,
              Participant: participant,
            });

            console.log("response", response);
            setBorrado("");
            if (listCaptures === undefined || listCaptures === null) {
              dispatch(setlistCapturet([participant]));
            } else {
              !duplicated &&
                dispatch(setlistCapturet([...listCaptures, participant]));
            }
            setValue("limitDateCapturearticipant", "");
            setloading(false);
          } catch (err) {
            console.log(err);
            Alert({
              icon: "error",
              title: "Hubo un error, inténtalo de nuevo" + err,
            });
          } finally {
            setValue("rolSeletedUser", null);
            setValue("searchParticipantUsers", null);
            resetField("searchParticipantUsers");
            setresults([]);
            setsearchUser("");
            setBorrado("");
            setloading(false);
            resetForm();
          }
        }

        if (watchParticipantSign) {
          if (
            watchlimitDateCapture !== null &&
            watchlimitDateCapture !== undefined &&
            watchlimitDateCapture !== "" &&
            fechaSign < fechaCapt
          ) {
            return ToastNotification({
              icon: "warning",
              text: "La fecha de firma no puede ser menor a la fecha de captura.",
            });
          }

          const duplicated = listSigners.find(
            (el) => el.UserId === (watchParticipantUser.Id ?? newUser.UserId)
          );

          if (duplicated) {
            setloading(false);
            return ToastNotification({
              icon: "warning",
              text: "El participante ya es firmante.",
            });
          }

          const duplicatedRolSigner = listSigners?.find(
            (el) =>
              el.Role !== null &&
              el.Role !== undefined &&
              el.Role === watchRolSeletedUser
          );

          if (duplicatedRolSigner) {
            setloading(false);
            return ToastNotification({
              icon: "warning",
              text: "El rol ya pertece a un firmante.",
            });
          }

          const participant = {
            SignByUser: !signToParticipant ? true : false,
            SignDeadline: watchlimitDateSign
              ? watchlimitDateSign.toISOString()
              : null,
            UserId: watchParticipantUser.Id ?? newUser.UserId,
            UserEmail: watchParticipantUser.name,
            Role:
              watchRolSeletedUser !== null && watchRolSeletedUser !== undefined
                ? watchRolSeletedUser
                : null,
            FullName: data.participantName,
            SignOrder: listSigners?.length + 1,
            ApplyNOM151: null,
            Sign: true,
            SignatureOptions:
              signToParticipant ||
              (newFileState.editStack && signToParticipantForEdit)
                ? null
                : {
                    OTP: watchOtp,
                    VideoRecording: data.video,
                    SignatureType:
                      data.signatureType === "simpleSign"
                        ? "Simple"
                        : "Avanzada",
                    AudioValidate:
                      fileAuthentication?.ConfigurationVideoValidates
                        ?.ValidateLocked,
                  },
          };

          try {
            const response = await fileService.registerParticipantInFile({
              FileId: newFileState.editStack
                ? fileState.item?.FileData?.Id
                : newFileState.file.Id,
              Participant: participant,
            });
            console.log(response);
            !duplicated &&
              dispatch(setlistSigners([...listSigners, participant]));
            if (
              watchRolSeletedUser !== null &&
              watchRolSeletedUser !== undefined
            ) {
              setValue("rolSeletedUser", null);
            }
            setloading(false);
          } catch (err) {
            console.log(err);
            Alert({
              icon: "error",
              title: "Hubo un error, inténtalo de nuevo" + err,
            });
          } finally {
            setloading(false);
            resetForm();
          }
        }

        Alert({
          icon: "success",
          title: "El participante se ha añadido a la lista.",
        });
        resetField("searchParticipantUsers");
      } catch (error) {
        console.log(error);
      } finally {
        setloading(false);
      }
    }

    if (!watchParticipantUser) {
      ToastNotification({
        icon: "warning",
        text: "Primero debes buscar y añadir a un participante.",
      });
      setloading(false);
    }
    setloading(false);
  };

  useEffect(() => {
    if (watchSign === "simpleSign" || watchSign === "advancedSign") {
      setValue("OTP", true);
    }
  }, [watchSign]);

  const handleDelete = async (user, list) => {
    const fileService = FileService.getInstance();
    try {
      const response = await fileService.removeParticipantInFile({
        FileId: newFileState.editStack
          ? fileState.item?.FileData?.Id
          : newFileState.file.Id,
        UserId: user.UserId,
        Action:
          list === "signers"
            ? "sign"
            : list === "checkers"
            ? user.Reviewer === true
              ? "reviewer"
              : "check"
            : list === "capture"
            ? "capture"
            : null,
      });
      console.log("response ELIMINAR", response);
      ToastNotification({
        icon: "success",
        text: "Participante eliminado",
      });

      if (list === "signers") {
        const newList = listSigners.filter((el) => {
          return el.UserId !== user.UserId;
        });
        dispatch(setlistSigners(newList));
      }
      if (list === "checkers") {
        const newList = listCheckers.filter((el) => {
          return el.UserId !== user.UserId;
        });
        dispatch(setlistCheckers(newList));
      }

      if (list === "capture") {
        const newList = listCaptures.filter((el) => {
          return el.UserId !== user.UserId;
        });
        dispatch(setlistCapturet(newList));
      }

      setValue("searchParticipantUsers", null);
      resetField("searchParticipantUsers");
      setresults([]);

      setsearchUser("");
    } catch (err) {
      console.log(err);
      ToastNotification({
        icon: "error",
        text: "Inténtalo de nuevo",
      });
    }
  };

  const handleEdit = async (user, list) => {
    console.log("ConfigFileTab:. handleEdit: ", user, list);
  };

  const handleValidateDate = (typeDate) => {
    const listchecks = listCheckers?.filter((l) => l.CheckDeadline);
    const listsigns = listSigners?.filter((l) => l.SignDeadline);
    const listcapture = listCaptures?.filter((l) => l.CaptureDeadline);
    const lclength = listchecks?.length;
    const lslength = listsigns?.length;
    const lcaplength = listcapture?.length;
    console.log("listcapture", listcapture);

    if (typeDate === "check") {
      if (
        fechaLimPrerre ||
        (newFileState?.editStack === true && fechaLimPrerreEdit)
      ) {
        const limDate = new Date(fechaLimPrerre || fechaLimPrerreEdit);
        return limDate.setDate(limDate.getDate());
      } else {
        return new Date();
      }
    }

    if (typeDate === "capture") {
      if (lclength === 0) {
        if (lslength === 0) {
          var currentDateValue =
            watchlimitDateCheck === undefined ||
            watchlimitDateCheck === null ||
            watchlimitDateCheck === ""
              ? // aqui entra la condicion para la fecha de prerrequisitos
                fechaLimPrerre ||
                (newFileState?.editStack === true && fechaLimPrerreEdit)
                ? new Date(fechaLimPrerre || fechaLimPrerreEdit)
                : new Date()
              : watchlimitDateCheck;
          const limDate = new Date(currentDateValue);
          return limDate.setDate(limDate.getDate());
        } else if (lcaplength !== 0) {
          const lastdateCap = listcapture[lcaplength - 1]?.CaptureDeadline;
          const limDate = new Date(lastdateCap);
          return limDate.setDate(limDate.getDate());
        }
        return new Date();
      } else {
        const lastdateCheck = listchecks[lclength - 1].CheckDeadline;
        const limDate = new Date(lastdateCheck);
        return limDate.setDate(limDate.getDate());
      }
    }

    if (typeDate === "sign") {
      if (lclength === 0) {
        if (lslength === 0) {
          var currentDateValue =
            watchlimitDateCheck === undefined ||
            watchlimitDateCheck === null ||
            watchlimitDateCheck === ""
              ? // aqui entra la condicion para la fecha de prerrequisitos
                fechaLimPrerre ||
                (newFileState?.editStack === true && fechaLimPrerreEdit)
                ? new Date(fechaLimPrerre || fechaLimPrerreEdit)
                : new Date()
              : watchlimitDateCheck;
          const limDate = new Date(currentDateValue);
          return limDate.setDate(limDate.getDate());
        }
        const lastdatesign = listsigns[lslength - 1].SignDeadline;
        const limDate = new Date(lastdatesign);
        return limDate.setDate(limDate.getDate());
      }
      if (lslength === 0) {
        const lastdatecheck = listchecks[lclength - 1].CheckDeadline;
        const limDate = new Date(lastdatecheck);
        return limDate.setDate(limDate.getDate());
      }
      console.log(" listsigns: 3:", listsigns);
      const lastdatesign = listsigns[lslength - 1].SignDeadline;
      const limDate = new Date(lastdatesign);
      return limDate.setDate(limDate.getDate());
    }
  };

  const resetForm = useCallback(() => {
    setValue("limitDateSignParticipant", "");
    setValue("participantSign", false);
    setValue("participantCheck", false);
    setValue("participantCaptureData", false);

    setValue("video", false);
    setValue("simpleSign", false);
    setValue("advancedSign", false);

    setValue("searchParticipantUsers", null);
    setValue("limitDateSignParticipant", "");
    setValue("limitDateCapturearticipant", "");
    setValue("typeUserSelect", "");

    resetField("searchParticipantUsers");

    setsearchUser("");
  }, [setValue]);

  const handleValidateDateMax = (typeDate) => {
    const listsigns = listSigners?.filter((l) => l.SignDeadline);
    const listcapture = listCaptures?.filter((l) => l.CaptureDeadline);

    if (typeDate === "check" && listcapture?.length > 0) {
      const lastdateCapture = listcapture[0].CaptureDeadline;
      const limDate = new Date(lastdateCapture);
      return limDate.setDate(limDate.getDate());
    }
    if (typeDate === "check" && listsigns?.length > 0) {
      const lastdatesign = listsigns[0].SignDeadline;
      const limDate = new Date(lastdatesign);
      return limDate.setDate(limDate.getDate());
    }

    if (typeDate === "capture" && listsigns?.length > 0) {
      const lastdatesign = listsigns[0].SignDeadline;
      const limDate = new Date(lastdatesign);
      return limDate.setDate(limDate.getDate());
    }
    return null;
  };

  useEffect(() => {
    if (searchUser) {
      getParticipantFromApi(searchUser);
    }
  }, [searchUser]);

  useEffect(() => {
    if (!open) {
      setresults([]);
    }
  }, [open]);

  useEffect(() => {
    if (watchParticipantUser) {
      if (watchParticipantUser.Id) {
        setValue(
          "participantName",
          `${watchParticipantUser.Name ?? ""} ${
            watchParticipantUser.FatherLastName ?? ""
          } ${watchParticipantUser.MotherLastName ?? ""}`
        );
      } else {
        setValue("participantName", "");
      }
    }
  }, [watchParticipantUser, setValue]);

  useEffect(() => {
    if (!watchParticipantUser) {
      resetForm();
    }
  }, [watchParticipantUser, setValue, resetForm]);

  useEffect(() => {
    resetForm();
  }, [listCheckers, listSigners, listViewers, resetForm]);

  const agregarStageParticipante = async () => {
    if (environmentCtrlSigned === true) {
      const fileFlowService = FileFlowService.getInstance();
      await fileFlowService.registerStatusStage({
        File_id: newFileState.editStack
          ? fileState.item?.FileData?.Id
          : newFileState.file.Id,
        Status: "Representación de firma",
        Result: null,
      });
      dispatch(getFile({ FileId: folioIdSolicitud }));
      props.handleNextStep();
    } else {
      const fileFlowService = FileFlowService.getInstance();
      await fileFlowService.registerStatusStage({
        File_id: folioIdSolicitud,
        Status: "Vista previa",
        Result: null,
      });
      dispatch(getFile({ FileId: folioIdSolicitud }));
      props.handleNextStep();
    }
  };

  const validacionRevisiones = () => {
    let userReviewer = listCheckers?.some((item) => item.Reviewer === true);

    let userCheck = listCheckers?.some((item) => item.Check === true);

    if (
      checkDocuments?.length === 0 &&
      (checkDocumentsEdit?.length === 0 || checkDocumentsEdit === undefined)
    ) {
      if (listSigners?.length !== 0) {
        return false;
      } else {
        return true;
      }
    } else {
      if (listSigners?.length !== 0 && listCheckers?.length !== 0) {
        if (userReviewer === true && userCheck === true) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  const validacionButtonToolTip = () => {
    let userReviewer = listCheckers?.filter(
      (item) => item.Reviewer === true
    ).length;
    let userCheck = listCheckers?.filter((item) => item.Check === true).length;

    if (
      checkDocuments?.length === 0 &&
      (checkDocumentsEdit?.length === 0 || checkDocumentsEdit === undefined)
    ) {
      return false;
    } else {
      if (userReviewer === 0 && userCheck === 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    validacionRevisiones();
    validacionButtonToolTip();
  }, [listCheckers]);

  useEffect(() => {
    if (
      watchRolSeletedUser !== null &&
      watchRolSeletedUser !== undefined &&
      watchRolSeletedUser !== ""
    ) {
      let userSeleted = rolesUserData.filter(
        (user) => user.Id === watchRolSeletedUser
      );
      if (userSeleted.length !== 0 && userSeleted[0].Capture === false) {
        setValue("participantCaptureData", false);
        setDisabledParticipantCapture(true);
      } else {
        setDisabledParticipantCapture(false);
      }
    }
  }, [watchRolSeletedUser]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <BackdropComponent loading={fileState.loading || loading} />
      <RootStyle>
        <HelpPages
          Body={
            <Box sx={{ mt: 2 }}>
              <video
                width={"100%"}
                className="HelpVideo"
                src={videoTutorial}
                autoPlay
                loop
                muted
              />
            </Box>
          }
        />
        <Accordion disableGutters defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{
              p: 0,
              "& .MuiAccordionSummary-content": {
                m: 0,
              },
            }}
          >
            <Typography variant="titulo">
              Configura las acciones que ejecutará cada participante
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ paddingLeft: 0, paddingTop: 16 }}>
            <Box
              sx={{
                mb: 3,
                display: "flex",
                alignItems: "center",
                gap: 0,
                flexWrap: { xs: "wrap" /* , sm: "nowrap" */ },
              }}
            >
              {templateActiveData === true ? (
                <InputField
                  inputType="select"
                  control={control}
                  label="Rol del participante"
                  placeholder="Selecciona el rol del participante"
                  name="rolSeletedUser"
                  selectField="Id"
                  optionsList={rolesUserData}
                  onClose={() => setValue("rolSeletedUser", null)}
                />
              ) : null}
              <InputField
                inputType="autocomplete"
                control={control}
                name="searchParticipantUsers"
                label="Correo electrónico"
                placeholder="Escribe el correo electrónico"
                defaultValue=""
                error={!!formState.errors.searchParticipantUsers}
                loadingParticipant={loadingParticipant}
                optionsList={results}
                validations={{
                  validate: (v) => v && emailPattern.test(v?.name),
                }}
                onInputChange={(e, v) => {
                  setsearchUser(v);
                }}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={(e, r) => {
                  if (r === "removeOption") {
                    resetField("searchParticipantUsers");
                  }
                  setOpen(false);
                }}
              />
              {!watchParticipantUser?.Id &&
              watchParticipantUser &&
              !formState.errors?.searchParticipantUsers ? (
                <Typography
                  variant="caption"
                  component={"p"}
                  sx={{ display: "block", mt: 1, color: "#C20E30" }}
                >
                  No se encontró un registro para este participante, escriba la
                  información solicitada para enviarle una invitación.
                </Typography>
              ) : null}
              {formState.errors.searchParticipantUsers?.type === "validate" && (
                <Typography
                  variant="caption"
                  color="#C20E30"
                  component={"p"}
                  sx={{ display: "block", mt: 1 }}
                >
                  E-mail no válido
                </Typography>
              )}
              {watchParticipantUser &&
                !formState.errors.searchParticipantUsers && (
                  <InputField
                    disabled={
                      watchParticipantUser.Id ||
                      formState.errors.searchParticipantUsers
                    }
                    inputType="text"
                    control={control}
                    name="participantName"
                    label={
                      watchParticipantUser.Id
                        ? "Nombre Completo"
                        : " Nombre o Alias"
                    }
                    error={!!formState.errors["participantName"]}
                  />
                )}
            </Box>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 700, mt: 2, mb: 0.8 }}
            >
              Selecciona la acción que realizará el participante{" "}
              <spam style={{ color: "#C20E30" }}>*</spam>
            </Typography>
            <InputField
              inputType="check"
              control={control}
              disabled={
                checkDocuments?.length === 0 &&
                (checkDocumentsEdit?.length === 0 ||
                  checkDocumentsEdit === undefined)
                  ? true
                  : false
              }
              name="participantCheck"
              label="El participante formará parte de la revisión"
              tooltipText={
                checkDocuments?.length === 0 &&
                (checkDocumentsEdit?.length === 0 ||
                  checkDocumentsEdit === undefined)
                  ? "Esta acción no esta disponible debido a que no tienes documentos para revisar."
                  : "Asigna una fecha límite de revisión que te permitirá llevar un seguimiento y gestionar los tiempos de control de cambios."
              }
              defaultValue={false}
            />
            {watchParticipantCheck && (
              <Box sx={{ ml: 3 }}>
                <InputField
                  inputType="date"
                  control={control}
                  label="Fecha de límite para la revisión del documento"
                  name="limitDateCheckParticipant"
                  validations={{ required: true }}
                  error={!!formState.errors.limitDateCheckParticipant}
                  sxInput={{ maxWidth: 500 }}
                  minDate={handleValidateDate("check")}
                  maxDate={handleValidateDateMax("check")}
                  defaultValue={""}
                />
                <Typography variant="body2" sx={{ mt: 1, fontWeight: 600 }}>
                  Acción que va realizar el participante{" "}
                  <spam style={{ color: "#C20E30" }}>*</spam>
                </Typography>
                <InputField
                  inputType="radio"
                  control={control}
                  name="typeUserSelect"
                  value={"userEditor"}
                  radioValues={[
                    {
                      value: "userEditor",
                      label: "Editor",
                      tooltipText: "",
                    },
                    {
                      value: "userApproving",
                      label: "Autorizador",
                      tooltipText: "",
                    },
                  ]}
                  validations={{ required: true }}
                  error={!!formState.errors.typeUserSelect}
                />
              </Box>
            )}
            {templateActiveData === true ? (
              <InputField
                inputType="check"
                control={control}
                disabled={disabledParticipantCapture}
                name="participantCaptureData"
                label="El participante formará parte de la captura"
                tooltipText="Asigna una fecha límite de firma que te permitirá llevar un seguimiento y gestionar los tiempos."
                defaultValue={false}
                error={!!formState.errors.participantCaptureData}
              />
            ) : null}
            {watchParticipantCaptureData && (
              <Box sx={{ ml: 3 }}>
                <InputField
                  inputType="date"
                  control={control}
                  label="Fecha de límite para capturar los datos"
                  name="limitDateCapturearticipant"
                  validations={{ required: true }}
                  error={!!formState.errors.limitDateSignParticipant}
                  sxInput={{ maxWidth: 500 }}
                  minDate={handleValidateDate("capture")}
                  maxDate={handleValidateDateMax("capture")}
                  defaultValue={""}
                />
              </Box>
            )}
            <InputField
              inputType="check"
              control={control}
              name="participantSign"
              label="El participante firmará electrónicamente"
              tooltipText="Asigna una fecha límite de firma que te permitirá llevar un seguimiento y gestionar los tiempos."
              defaultValue={false}
              error={!!formState.errors.participantSign}
            />
            {watchParticipantSign && (
              <Box sx={{ ml: 3 }}>
                <InputField
                  inputType="date"
                  control={control}
                  label="Fecha de límite para firmar el documento"
                  name="limitDateSignParticipant"
                  validations={{ required: true }}
                  error={!!formState.errors.limitDateSignParticipant}
                  sxInput={{ maxWidth: 500 }}
                  minDate={handleValidateDate("sign")}
                  defaultValue={""}
                />
                {(newFileState.editStack && !signToParticipantForEdit) ||
                (!newFileState.editStack && !signToParticipant) ? (
                  <>
                    <Box sx={{ mt: 1 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 700, mt: 2, mb: 0.8 }}
                      >
                        Tipo de Firma{" "}
                        <spam style={{ color: "#C20E30" }}>*</spam>
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{ mb: 1, display: "flex", alignItems: "center" }}
                    >
                      Selecciona el tipo de firma que incluirás en los
                      documentos.
                    </Typography>

                    <InputField
                      inputType="radio"
                      control={control}
                      name="signatureType"
                      radioValues={[
                        {
                          value: "simpleSign",
                          label: "Firma Autógrafa en Mensaje de Datos",
                          tooltipText:
                            "Los participantes deberán usar una representación gráfica (grafo) que será plasmado a los documentos al firmar, se agregará atribución legal mediante el uso de una firma digital verificado por una Contraseña de un solo uso (OTP).",
                        },
                        {
                          value: "advancedSign",
                          label: "Firma Electrónica Avanzada",
                          tooltipText:
                            "Los participantes deberán usar su Firma Electrónica Avanzada emitida por el SAT, durante el proceso de firmado se validará la vigencia y validez del mismo. Se requiere contar con los archivos: Clave Privada (.key), Certificado (.cer) y Contraseña de clave privada de la firma.",
                        },
                      ]}
                      validations={{ required: true }}
                    />
                    <Typography variant="subtitle2" sx={{ mb: 1, mt: 3 }}>
                      Propiedades del tipo de firma
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mb: 1, display: "flex", alignItems: "center" }}
                    >
                      Selecciona la opción que deseas incluir para el proceso de
                      firma electrónica de la solicitud.
                    </Typography>
                    <InputField
                      inputType="check"
                      control={control}
                      label="Videograbación: Los participantes deberán grabar un video de consentimiento de la aplicación de su firma en los documentos."
                      tooltipText={
                        "Se realizará una grabación de video corta donde el participante demuestra estar de acuerdo con las condiciones específicas para el procedimiento de firma electrónica."
                      }
                      name="video"
                    />
                    <InputField
                      inputType="check"
                      control={control}
                      tooltipText={
                        "La contraseña de un solo uso será enviada vía correo electrónico y por mensaje de texto el cual tendrá una vigencia de 5 minutos."
                      }
                      label={
                        watchSign === "simpleSign"
                          ? "Contraseña de un solo uso (OTP): Se agregará un código numérico que complementará la atribución de un firmado electrónico"
                          : '<Box><Box>Contraseña de un solo uso (OTP): Se agregará un código numérico que complementará la atribución de un firmado electrónico</Box><Box> <span style="color:#C20E30">(Ampliamente recomendado como complemento)</span> </Box> </Box>'
                      }
                      name="OTP"
                      disabled={watchSign === "simpleSign"}
                      defaultValue={watchSign === "advancedSign"}
                    />
                  </>
                ) : null}
              </Box>
            )}
            {Object.keys(formState.errors).length > 0 && (
              <Typography
                variant="caption"
                color="primary"
                sx={{ display: "block", mt: 1 }}
              >
                Campos requeridos <spam style={{ color: "#C20E30" }}>*</spam>
              </Typography>
            )}
            <LoadingButton
              variant="contained"
              loading={loading}
              disabled={loading}
              fullWidth={xsScreen}
              sx={{
                mt: 4,
                mb: 10,
                height: 45,
                marginRight: "auto",
                display: "block",
                maxWidth: 380,
              }}
              onClick={handleSubmit(handleAddParticipant)}
            >
              Añadir participante
            </LoadingButton>
          </AccordionDetails>
        </Accordion>
        {listCheckers?.length > 0 && (
          <Accordion disableGutters defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{
                p: 0,
                "& .MuiAccordionSummary-content": {
                  m: 0,
                },
              }}
            >
              <TypoTooltip variant="titulo" text="Lista de revisores" />
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <ParticipantDraggableTableRevisor
                  data={listCheckers}
                  setData={(newData) => dispatch(setlistCheckers(newData))}
                  listType="checkers"
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  dataValidate={listSigners}
                />
              </Box>
              <Box sx={{ display: { xs: "block", sm: "none" } }}>
                {listCheckers?.map((user) => (
                  <ParticipantListResponsive
                    key={user.UserId}
                    data={listCheckers}
                    user={user.UserEmail || user.Email}
                    type={"checkers"}
                    functionUser={user.Check}
                    action={true}
                    handleDelete={handleDelete}
                    userComplete={user}
                    dataValidate={listSigners}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}

        {listCaptures?.length > 0 && (
          <Accordion disableGutters defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{
                p: 0,
                "& .MuiAccordionSummary-content": {
                  m: 0,
                },
              }}
            >
              <TypoTooltip
                variant="titulo"
                text="Lista de participantes que capturan"
              />
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <ParticipantDraggableTableRevisor
                  data={listCaptures}
                  setData={(newData) => dispatch(setlistCheckers(newData))}
                  listType="capture"
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  dataValidate={listCaptures}
                />
              </Box>
              <Box sx={{ display: { xs: "block", sm: "none" } }}>
                {listCaptures?.map((user) => (
                  <ParticipantListResponsive
                    key={user.UserId}
                    data={listCaptures}
                    user={user.UserEmail || user.Email}
                    type={"capture"}
                    functionUser={"capture"}
                    action={true}
                    handleDelete={handleDelete}
                    userComplete={user}
                    dataValidate={listCaptures}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}

        {listSigners?.length > 0 && (
          <Accordion disableGutters defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{
                p: 0,
                "& .MuiAccordionSummary-content": {
                  m: 0,
                },
              }}
            >
              <TypoTooltip variant="titulo" text="Lista de firmantes" />
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <ParticipantDraggableTable
                  data={listSigners}
                  setData={(newData) => {
                    console.log("DragTable: ", newData);
                    dispatch(setlistSigners(newData));
                  }}
                  listType="signers"
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  dataValidate={listCheckers}
                />
              </Box>
              <Box sx={{ display: { xs: "block", sm: "none" } }}>
                <ParticipantDraggableTableResponsive
                  data={listSigners}
                  setData={(newData) => {
                    console.log("DragTable: ", newData);
                    dispatch(setlistSigners(newData));
                  }}
                  listType="signers"
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  dataValidate={listCheckers}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        )}

        <Divider
          sx={{
            mt: 5,
            mb: 5,
            borderBottomColor: ({ palette }) => palette.secondary.main60,
          }}
        />
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={4}>
            <Button
              fullWidth
              color="secondary"
              variant="outlined"
              sx={{ height: 45, px: 10 }}
              onClick={() => {
                dispatch(getFile({ FileId: folioIdSolicitud }));
                props.handleBackStep();
              }}
            >
              Anterior
            </Button>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Tip
              placement="top"
              enterTouchDelay={0}
              title={
                validacionRevisiones() === true
                  ? "Para continuar es necesario que ingreses un usuario autorizador y un usuario revisor."
                  : null
              }
            >
              <Box>
                <LoadingButton
                  fullWidth
                  loading={loading}
                  disabled={validacionRevisiones()}
                  variant="contained"
                  sx={{ height: 45, px: 0 }}
                  onClick={() => {
                    agregarStageParticipante();
                  }}
                >
                  Continuar
                </LoadingButton>
              </Box>
            </Tip>
          </Grid>
        </Grid>
      </RootStyle>
    </LocalizationProvider>
  );
};

/* const onSubmit = async (action) => {
  console.log(newFileState);
  if (
    listSigners.length > 0 ||
    listCheckers.length > 0 ||
    listViewers.length > 0
  ) {
    const viewUsers = listViewers.map(({ UserId }) => ({
      UserId,
    }));
    const signUsers = listSigners.map(
      ({
        UserId,
        Deadline,
        OTP,
        VideoRecording,
        Psc,
        AutographSignature,
      }) => ({
        UserId,
        Deadline,
        OTP,
        VideoRecording,
        Psc,
        AutographSignature,
      })
    );
    const checkUsers = listCheckers.map(({ UserId, Deadline }) => ({
      UserId,
      Deadline,
    }));
    const bodyDocument = {
      FileId: newFileState.file.Id,
      DocumentConfiguration: {
        ...newFileState.document.DocumentConfiguration,
        ViewUsers: viewUsers,
        SignUsers: signUsers,
        CheckUsers: checkUsers,
      },
      UploadConfiguration: newFileState.document.UploadConfiguration,
    };
    console.log(bodyDocument);
    action === "save" ? setloading(true) : setsecondloading(true);
    try {
      const fileService = FileService.getInstance();
      const response = await fileService.registerDocument(bodyDocument);
      console.log(response);
      Alert({ icon: "success", title: "Documento añadido" }, () => {
        dispatch(
          addToDocsStack({
            ...bodyDocument,
            ViewUsers: listViewers,
            SignUsers: listSigners,
            CheckUsers: listCheckers,
            documentId: response.Body.Id,
            documentType: response.Body.Name,
            documentStatus: response.Body.Status,
            pdfUrl: newFileState.document.fileUrl,
          })
        );
        resetForm();
        resetCaptureForm();
        dispatch(resetNewDocument());
        dispatch(deleteAllParticipants());
        if (action === "save") {
          props.handleNextStep();
        } else {
          props.handleSetStep(1);
        }
      });
    } catch (err) {
      console.log(err);
      Alert({ icon: "error", title: "Hubo un error, inténtalo de nuevo" });
    } finally {
      action === "save" ? setloading(false) : setsecondloading(false);
    }
  } else {
    ToastNotification({
      icon: "warning",
      text: "Debe haber al menos un participante.",
    });
  }
}; */
