import { Box, Typography, styled, FormControl } from "@mui/material";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { UTCtoLocal } from "../../../utils";

const Control = styled(FormControl)(({ theme }) => ({
  width: "100%",
  marginBottom: "8px",
  display: "flex",
}));

export default function Metadatos({ fileState }) {
  const userData = useSelector(
    (state) => state.Authentication.items.UserLoginData
  );
  const stageFileFlow = useSelector(
    (state) => state.File?.item?.FileData?.Stage?.FlowState
  );
  const statusAdmin =
    userData?.Username === fileState?.item?.FileData?.CreationUser;

  const userListen = fileState?.item?.Participants?.filter((user) => user.UserId === userData.Id);

  const stagesDocumentList = () => {
    switch (stageFileFlow) {
      case "Nuevo expediente":
        return 'REVISION'
      case "Revisión de documentos":
        return 'REVISION'
      case "Notificacion De Asignacion de Revisión":
        return 'REVISION'
      case "Revisión de Administrador":
        return 'REVISION'
      case "Asignando solicitud de revisión":
        return 'REVISION'
      case "Firma de documentos":
        return 'FIRMA'
        case "Validación de evidencias":
        return 'FIRMA';
      case "Notificacion De Asignacion de Firma":
        return 'FIRMA'
      case "Asignando solicitud de firma":
        return 'FIRMA'
      case "Notificacion De Finalización de Firma":
        return 'FIRMA'
      default:
        break;
    }
  };

  return (
    <Fragment>
      <Control>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ minWidth: "30%" }}>
            <Typography variant="h6">
              Folio del archivo
            </Typography>
          </Box>
          <Typography variant="subtitle">{stagesDocumentList() === 'FIRMA' ? (fileState?.document?.Id) : (fileState.document?.DocumentData.Id ?? "---")}</Typography>
        </Box>
      </Control>
      <Control>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ minWidth: "30%" }}>
            <Typography variant="h6">
              Nombre del archivo
            </Typography>
          </Box>
          <Typography variant="subtitle">{stagesDocumentList() === 'FIRMA' ? (fileState?.document?.Name) : (fileState.document?.FileName ?? "---")}</Typography>
        </Box>
      </Control>
      <Control>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ minWidth: "30%" }}>
            <Typography variant="h6">
              Estatus del documento
            </Typography>
          </Box>
          <Typography variant="subtitle">{stagesDocumentList() === 'FIRMA' ? (fileState?.document?.Status) : (fileState.document?.DocumentData.Status ?? "---")}</Typography>
        </Box>
      </Control>
      {stagesDocumentList() === 'FIRMA' ? null : (
        <Control>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ minWidth: "30%" }}>
              <Typography variant="h6">
                Tipo de Documento
              </Typography>
            </Box>
            <Typography variant="subtitle">{fileState.document?.DocumentData.Name ?? "---"}</Typography>
          </Box>
        </Control>
      )}
      {statusAdmin ? null : (stagesDocumentList() === 'FIRMA' ?
        <Control>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ minWidth: "30%" }}>
              <Typography variant="h6">
                Fecha de vencimiento
              </Typography>
            </Box>
            <Typography variant="subtitle">{UTCtoLocal(userListen[0]?.SignDeadline, false)}</Typography>
          </Box>
        </Control>
        : null)}
      {statusAdmin ? null : (stagesDocumentList() === 'REVISION' ?
        <Control>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ minWidth: "30%" }}>
              <Typography variant="h6">
                Fecha de limite de revisión
              </Typography>
            </Box>
            <Typography variant="subtitle">
              {UTCtoLocal(userListen[0]?.CheckDeadline, false)}
            </Typography>
          </Box>
        </Control>
        : null)}
      {statusAdmin ? null : (stagesDocumentList() === 'FIRMA' ?
        <Control>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ minWidth: "30%" }}>
              <Typography variant="h6">
                Fecha de limite de firma
              </Typography>
            </Box>
            <Typography variant="subtitle">
              {UTCtoLocal(userListen[0]?.SignDeadline, false)}
            </Typography>
          </Box>
        </Control>
        : null)}

    </Fragment>
  );
}
