(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("axios"), require("buffer/"), require("crypto"), require("node-forge"), require("object-hash"));
	else if(typeof define === 'function' && define.amd)
		define(["axios", "buffer/", "crypto", "node-forge", "object-hash"], factory);
	else if(typeof exports === 'object')
		exports["@gobmx-sfp/firmafiel"] = factory(require("axios"), require("buffer/"), require("crypto"), require("node-forge"), require("object-hash"));
	else
		root["@gobmx-sfp/firmafiel"] = factory(root["axios"], root["buffer/"], root["crypto"], root["node-forge"], root["object-hash"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__376__, __WEBPACK_EXTERNAL_MODULE__53__, __WEBPACK_EXTERNAL_MODULE__417__, __WEBPACK_EXTERNAL_MODULE__771__, __WEBPACK_EXTERNAL_MODULE__780__) {
return 