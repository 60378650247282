import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Divider,
  Grid,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { isEdge } from "react-device-detect";
import videoUbicacion from "../assets/VideoUbicacion.mp4";
import videoUbicacionCorto from "../assets/VideoUbicacionCorto.mp4";
import { LoadingButton } from "@mui/lab";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  "&.MuiDialogTitle-root": {
    fontSize: 20,
    color: theme.palette.primary.text,
    fontWeight: 300,
    lineHeight: "130%",
  },
  display: "inline-flex",
  alignItems: "center",
}));

export const CustomModalDialogGeolocation = (props) => {
  return (
    <CustomDialog
      aria-labelledby="customized-dialog-title"
      open={props.Open}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <StyledDialogTitle sx={{ m: 0, p: 2, color: "" }}>
        <Typography variant="titulo" textAlign={"center"}>
          Verifica tu ubicación
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => props.setOpenAlerte(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent>
        <Box>
          <Typography
            textAlign={"center"}
            variant="body1"
            sx={{ mt: 1, textAlign: "center", maxWidth: 700, fontWeight: 600, mx: "auto" }}
          >
            La información de tu ubicación a través de tu dispositivo no está
            disponible.
          </Typography>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyItems: "center",
                justifyContent: "center",
              }}
            >
              <video
                className="videoUbi"
                src={isEdge === true ? videoUbicacion : videoUbicacionCorto}
                autoPlay
                loop
                muted
              />
            </Box>
          </Box>
          <Divider sx={{ maxWidth: 300, mt: 1, mx: "auto" }} />
          <Typography
            textAlign={"center"}
            variant="body1"
            sx={{ mt: 1, textAlign: "center", maxWidth: 700, fontWeight: 600, mx: "auto" }}
          >
            Por consiguiente, la ubicación será obtenida a partir de tu IP
            (conexión a Internet).
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ textAling: "center !important" }}>
        <Grid container spacing={2} justifyContent="center">
          {/* <Grid item xs={12} sm={4}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              sx={{ height: 38, px: 10 }}
              onClick={() => props.setOpenAlerte(false)}
            >
              Cancelar
            </Button>
          </Grid> */}
          <Grid item xs={12} sm={5}>
            <LoadingButton
              fullWidth
              variant="contained"
              sx={{ height: 38, px: 10 }}
              onClick={() => {
                props.setGeolocationIP(true);
                props.setOpenAlerte(false);
              }}
            >
              Continuar
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </CustomDialog>
  );
};
