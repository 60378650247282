import { newFileConstants } from "../constants";

const initialState = {
  loading: false,
  error: null,
  file: null,
  document: null,
  form: null,
  participants: {
    check: [],
    sign: [],
    view: [],
    capture: [],
  },
  documentsStack: [],
  dataPrerrequesitos: null
};

export const NewFile = (state = initialState, action) => {
  switch (action.type) {
    case newFileConstants.NEW_FILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        file: null,
      };

    case newFileConstants.SET_PRERREQUISITES:
      return {
        ...state,
        dataPrerrequesitos: action.payload,
      };

    case newFileConstants.RESET_PRERREQUISITES:
      return {
        ...state,
        dataPrerrequesitos: null,
      };

    case newFileConstants.NEW_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        file: action.payload,
      };

    case newFileConstants.NEW_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        file: null,
      };
    case newFileConstants.RESET_NEW_FILE:
      return {
        ...state,
        file: null,
      };
    case newFileConstants.SET_NEW_DOCUMENT:
      return {
        ...state,
        document: {
          ...(state.document ?? {}),
          ...action.payload,
        },
      };
    case newFileConstants.RESET_NEW_DOCUMENT:
      return {
        ...state,
        document: null,
      };
    case newFileConstants.RESET_PDF:
      return {
        ...state,
        document: {
          ...(state.document ?? {}),
          file: null,
          fileUrl: null,
          UploadConfiguration: null,
        },
      };
    case newFileConstants.SET_FORM:
      return {
        ...state,
        form: action.payload,
      };
    case newFileConstants.UNSET_FORM:
      return {
        ...state,
        form: null,
      };
    case newFileConstants.SET_SIGN_PARTICIPANTS:
      return {
        ...state,
        participants: {
          ...state.participants,
          sign: action.payload,
        },
      };
    case newFileConstants.SET_CHECK_PARTICIPANTS:
      return {
        ...state,
        participants: {
          ...state.participants,
          check: action.payload,
        },
      };
    case newFileConstants.SET_VIEW_PARTICIPANTS:
      return {
        ...state,
        participants: {
          ...state.participants,
          view: action.payload,
        },
      };
    case newFileConstants.SET_CAPTURE_PARTICIPANTS:
      return {
        ...state,
        participants: {
          ...state.participants,
          capture: action.payload,
        },
      };
    case newFileConstants.UNSET_ALL_PARTICIPANTS:
      return {
        ...state,
        participants: {
          sign: [],
          check: [],
          view: [],
        },
      };
    case newFileConstants.ADD_TO_DOCS_STACK:
      return {
        ...state,
        documentsStack: [...state.documentsStack, action.payload],
      };
    case newFileConstants.SET_DOCS_STACK:
      return {
        ...state,
        documentsStack: [...action.payload],
      };
    case newFileConstants.RESET_DOCS_STACK:
      return {
        ...state,
        documentsStack: [],
      };

    case newFileConstants.EDIT_STACK:
      return {
        ...state,
        editStack: action.payload,
      };

    default:
      return state;
  }
};
