import { employeesConstants } from "../constants";

export const Employees = (state = {}, action) => {
  switch (action.type) {
    case employeesConstants.EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: true,
        items: undefined,
        error: undefined,
      };

    case employeesConstants.EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: undefined,
      };

    case employeesConstants.AUTHENTICATION_FAILURE:
      return {
        ...state,
        loading: false,
        items: undefined,
        error: "ERROR",
      };
    case employeesConstants.EMPLOYEES_RESET:
      return {
        ...state,
        loading: false,
        items: undefined,
        error: undefined,
      };
    case employeesConstants.EMPLOYEES_UPDATE_ONE:
      return {
        ...state,
        updateUser: action.payload,
      };
    case employeesConstants.EMPLOYEES_RESET_UPDATE:
      return {
        ...state,
        updateUser: null,
      };
    default:
      return state;
  }
};
